import css from "./DialogHeader.module.css";
import Typography from "@/Typography";
import Button from "@/Button";
import { CloseIcon } from "@/Icon";

export type DialogHeaderProps = {
    title: string;
    closeDialog?: () => void;
};

export const DialogHeader = ({ title, closeDialog }: DialogHeaderProps) => {
    return (
        <div className={css.DialogHeader}>
            <div className={css.DialogHeaderTitle}>
                <Typography
                    className={css.DialogHeaderTitleContent}
                    variant="h6"
                >
                    {title}
                </Typography>
            </div>
            <div className={css.DialogHeaderClose}>
                <Button variant="text" size="tiny" onClick={closeDialog}>
                    <CloseIcon />
                </Button>
            </div>
        </div>
    );
};

export default DialogHeader;
