import { useState, useLayoutEffect } from "react";

export default function useRelativeMousePosition(ref: React.RefObject<any>) {
    const [point, setPoint] = useState<{ x: number; y: number }>({
        x: NaN,
        y: NaN,
    });

    useLayoutEffect(() => {
        const element = ref.current;

        function handleOnMouseMove(e: MouseEvent) {
            const rect = element.getBoundingClientRect(),
                x = (e.clientX - rect.x) / rect.width,
                y = (e.clientY - rect.y) / rect.height;

            setPoint({ x, y });
        }

        element?.addEventListener("mousemove", handleOnMouseMove);

        return () => {
            element?.removeEventListener("mousemove", handleOnMouseMove);
        };
    }, [ref]);

    return [point] as const;
}
