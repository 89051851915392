import type { ReactNode } from "react";
import { CssBaseline } from "@bespeak/ui";
import { ThemeContext } from "@bespeak/ui/src/Theme";
import type { WhiteLabelConfig } from "@/types/WhiteLabelConfig";

namespace ThemeProvider {
    export interface Props {
        whiteLabelConfig: WhiteLabelConfig;
        children: ReactNode;
    }
}

export function ThemeProvider(props: ThemeProvider.Props) {
    return (
        <ThemeContext.Provider value={props.whiteLabelConfig}>
            {props.children}
            <CssBaseline />
        </ThemeContext.Provider>
    );
}
