import { useMemo } from "react";
import type { ErrorLink } from "@apollo/client/link/error";
import { onError } from "@apollo/client/link/error";

namespace useErrorLink {
    export interface Props {
        setNetworkError: (networkError: Error | null) => void;
        setGraphQlErrors: (graphQlErrors: readonly Error[] | null) => void;
    }
}

export function useErrorLink(props: useErrorLink.Props) {
    return useMemo(
        () =>
            onError(({ graphQLErrors, response, networkError }) => {
                if (graphQLErrors) {
                    props.setGraphQlErrors(graphQLErrors);
                }
                if (graphQLErrors || response?.data) {
                    props.setNetworkError(null);
                } else if (networkError) {
                    props.setNetworkError(networkError);
                }
            }),
        [props],
    );
}

export { type ErrorLink };

export default useErrorLink;
