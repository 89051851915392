import React, { type ReactNode } from "react";
import clsx from "clsx";
import css from "./Section.module.scss";
import SpacingType from "@/types/enums/spacing.enum";

type Props = {
    children: ReactNode;
    spacing?: SpacingType | string | null;
    verticalSpacing?: SpacingType | string | null;
    className?: string;
    maxWidth?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
};

const Section = (props: Props) => {
    const {
        children,
        spacing = SpacingType.S1,
        verticalSpacing = SpacingType.S0,
        className: classNameProps,
        maxWidth = "var(--default-section-width)",
        style: styleProp,
        onClick,
    } = props;

    const style = {
        "--_Section-padding-hor": spacing === null ? "0" : `var(--${spacing})`,
        "--_Section-padding-ver":
            verticalSpacing === null ? "0" : `var(--${verticalSpacing})`,
        maxWidth: maxWidth,
        ...styleProp,
    } as React.CSSProperties;

    const classname = clsx("Section", css.Section, classNameProps);

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <div className={classname} style={style} onClick={handleClick}>
            {children}
        </div>
    );
};

export default Section;
