import type { HTMLAttributes, ReactNode } from "react";
import clsx from "clsx";
import css from "./ReferenceCardTitle.module.css";

export interface ReferenceCardTitleProps
    extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
    className?: string;
}

export default function ReferenceCardTitle(props: ReferenceCardTitleProps) {
    return (
        <div
            data-testid="reference-card-title"
            className={clsx(css.ReferenceCardTitle, props.className)}
        >
            {props.children}
        </div>
    );
}
