import { useQuery } from "@apollo/client";
import { GetEducationalPathForAppDocument } from "@bespeak/apollo";

export function useGetEducationalPathForApp(id: string) {
    const { data, ...results } = useQuery(GetEducationalPathForAppDocument, {
        variables: {
            id: id,
        },
        skip: !id,
    });

    return {
        educationalPath: data?.getEducationalPathForApp,
        ...results,
    };
}

export default useGetEducationalPathForApp;
