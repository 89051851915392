import css from "./HelpBubble.module.css";
import { HelpBubbleCard } from "./HelpBubbleCard.component";
import { HelpBubbleCircle } from "./HelpBubbleCircle.component";
import { useState, useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { useTranslation } from "react-i18next";

export type HelpBubbleProps = {
    isVisible?: boolean; //Storybook
};

export const HelpBubble = ({ isVisible = false }: HelpBubbleProps) => {
    const { t } = useTranslation("common", {
        keyPrefix: "help-bubble",
    });
    const [isActive, setIsActive] = useState(isVisible);
    const ref = useRef<HTMLDivElement>(null);
    const handleClick = () => {
        setIsActive(!isActive);
    };

    const handleClickOutside = () => {
        if (isActive) {
            setIsActive(false);
        }
    };

    useOnClickOutside(ref, handleClickOutside);

    return (
        <div ref={ref} className={css.HelpBubbleBase}>
            <HelpBubbleCard
                isVisible={isActive}
                email={t("email", "info@visavi.nl")}
                phone={t("phone", "0800-3334555")}
                title={t("title", "Kunnen we je helpen?")}
            />
            <HelpBubbleCircle onClick={handleClick} />
        </div>
    );
};

export default HelpBubble;
