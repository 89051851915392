const WhiteLabelConfigError = ({ error }: { error: any }) => (
    <div
        style={{
            position: "fixed",
            inset: 0,
            padding: 40,
            display: "grid",
            placeItems: "center",
            fontFamily: "sans-serif",
        }}
    >
        <div style={{ fontSize: "1.6rem" }}>
            <h1>Error</h1>
            <p>Something went wrong while loading white label configuration:</p>
            {error instanceof Error ? (
                <pre style={{ fontSize: "1.2rem", opacity: 0.7 }}>
                    <code>{error.stack}</code>
                </pre>
            ) : null}
        </div>
    </div>
);

export default WhiteLabelConfigError;
