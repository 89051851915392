import { cva } from "cva";
import { alignmentVariants } from "./common.variants";
import css from "./Table.module.scss";

export const thVariants = cva({
    base: css.Th,
    variants: {
        ...alignmentVariants,
    },
});
