import { type ReactNode, useEffect } from "react";
import { AuthProvider as ReactOidcAuthProvider } from "react-oidc-context";
import { authProviderProps } from "@/lib/Auth";
import type { UserManager } from "oidc-client-ts";

namespace AuthProvider {
    export interface Props {
        children: ReactNode;
    }
}

export function AuthProvider(props: AuthProvider.Props) {
    const { userManager, ...otherProps } = authProviderProps;

    const createRemoveUserCallback = (userManager: UserManager) => () =>
        void userManager.removeUser();

    // as per https://github.com/authts/oidc-client-ts/issues/1435#issuecomment-1991647966, attach a listener to various
    // events to remove the user from the state store when the user is no longer valid.
    useEffect(() => {
        const removeUser = createRemoveUserCallback(userManager);

        userManager.events.addSilentRenewError(removeUser);
        userManager.events.addAccessTokenExpired(removeUser);

        return () => {
            userManager.events.removeSilentRenewError(removeUser);
            userManager.events.removeAccessTokenExpired(removeUser);
        };
    }, [userManager]);

    return (
        <ReactOidcAuthProvider userManager={userManager} {...otherProps}>
            {props.children}
        </ReactOidcAuthProvider>
    );
}
