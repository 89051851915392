import css from "./LayoutImage.module.scss";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import { BuildingBlockType } from "@/components/organisms/BuildingBlockMapper";
import type { ReactNode } from "react";
import { LayoutTypes } from "@/components/molecules/LayoutSelector/LayoutTypes";

export interface LayoutProps {
    layout: LayoutTypes;
    type: BuildingBlockType;
}

const ImageBlock = () => {
    return (
        <div className={css.Image}>
            <InsertPhotoOutlinedIcon />
        </div>
    );
};

const VerticalContainer = ({ children }: { children: ReactNode }) => {
    return <div className={css.VerticalContainer}>{children}</div>;
};

const QuestionBlock = () => {
    return <div className={css.Question} />;
};

const AnswerBlock = () => {
    return <div className={css.Answers} />;
};

export default function LayoutImage({ layout, type }: LayoutProps) {
    if (
        type === BuildingBlockType.MPC ||
        type === BuildingBlockType.SORTQUESTION
    ) {
        switch (layout) {
            case LayoutTypes.ImageLeft:
                return (
                    <div className={css.Layout}>
                        <ImageBlock />
                        <VerticalContainer>
                            <QuestionBlock />
                            <AnswerBlock />
                        </VerticalContainer>
                    </div>
                );
            case LayoutTypes.ImageRight:
                return (
                    <div className={css.Layout}>
                        <VerticalContainer>
                            <QuestionBlock />
                            <AnswerBlock />
                        </VerticalContainer>
                        <ImageBlock />
                    </div>
                );
            case LayoutTypes.TextCentered:
                return (
                    <div className={css.Layout}>
                        <VerticalContainer>
                            <QuestionBlock />
                            <AnswerBlock />
                        </VerticalContainer>
                    </div>
                );
            case LayoutTypes.TwoColumns:
                return (
                    <div className={css.Layout}>
                        <QuestionBlock />
                        <AnswerBlock />
                    </div>
                );
            default:
                return <></>;
        }
    }

    if (
        type === BuildingBlockType.CONTENT ||
        type === BuildingBlockType.CONTENT_LIST ||
        type === BuildingBlockType.STAND
    ) {
        switch (layout) {
            case LayoutTypes.ImageLeft:
                return (
                    <div className={css.Layout}>
                        <ImageBlock />
                        <VerticalContainer>
                            <AnswerBlock />
                        </VerticalContainer>
                    </div>
                );
            case LayoutTypes.ImageRight:
                return (
                    <div className={css.Layout}>
                        <VerticalContainer>
                            <AnswerBlock />
                        </VerticalContainer>
                        <ImageBlock />
                    </div>
                );
            case LayoutTypes.TextCentered:
            case LayoutTypes.TextLeft:
                return (
                    <div className={css.Layout}>
                        <VerticalContainer>
                            <AnswerBlock />
                        </VerticalContainer>
                    </div>
                );
            case LayoutTypes.TwoColumns:
                return (
                    <div className={css.Layout}>
                        <AnswerBlock />
                        <AnswerBlock />
                    </div>
                );
            default:
                return <></>;
        }
    }

    if (type === BuildingBlockType.HOTSPOT) {
        switch (layout) {
            case LayoutTypes.ImageLeft:
                return (
                    <div className={css.Layout}>
                        <ImageBlock />
                        <VerticalContainer>
                            <AnswerBlock />
                        </VerticalContainer>
                    </div>
                );
            case LayoutTypes.ImageRight:
                return (
                    <div className={css.Layout}>
                        <VerticalContainer>
                            <AnswerBlock />
                        </VerticalContainer>
                        <ImageBlock />
                    </div>
                );
            case LayoutTypes.Centered:
                return (
                    <div className={css.Layout}>
                        <VerticalContainer>
                            <AnswerBlock />
                        </VerticalContainer>
                    </div>
                );
            default:
                return <></>;
        }
    }

    return <></>;
}
