import type { ComponentProps } from "react";
import type { VariantProps } from "cva";
import clsx from "clsx";
import type { AdornmentProps } from "./AdornmentProps";
import { thVariants } from "./th.variants";
import { tdVariants } from "./td.variants";
import styles from "./Table.module.scss";

export interface TableProps extends ComponentProps<"table"> {}

export function TableRoot({ className, ...props }: TableProps) {
    const defaultProps = {
        cellPadding: 0,
        cellSpacing: 0,
    } satisfies TableProps;
    const classes = clsx(className, styles.Table);
    return <table {...{ ...defaultProps, ...props }} className={classes} />;
}

export interface THeadProps extends ComponentProps<"thead"> {}

export function THead({ className, ...props }: THeadProps) {
    const classes = clsx(className, styles.THead);
    return <thead {...props} className={classes} />;
}

export interface TBodyProps extends ComponentProps<"tbody"> {}

export function TBody({ className, ...props }: TBodyProps) {
    const classes = clsx(className, styles.TBody);
    return <tbody {...props} className={classes} />;
}

export interface TFootProps extends ComponentProps<"tfoot"> {}

export function TFoot({ className, ...props }: TFootProps) {
    const classes = clsx(className, styles.TFoot);
    return <tfoot {...props} className={classes} />;
}

export interface TrProps extends ComponentProps<"tr"> {}

export function Tr({ className, ...props }: TrProps) {
    const classes = clsx(className, styles.Tr);
    return <tr {...props} className={classes} />;
}

//
// Th
// ---------------------------------------------------------------------------------------------------------------------

export interface ThProps
    extends Omit<ComponentProps<"th">, "align">,
        VariantProps<typeof thVariants>,
        AdornmentProps {
    width?: string;
}

export function Th({
    children,
    alignLeft,
    alignCenter,
    alignRight,
    startAdornment,
    endAdornment,
    width,
    ...otherThProps
}: ThProps) {
    const thVariantsProps = { alignLeft, alignCenter, alignRight },
        classNames = thVariants(thVariantsProps),
        style = { width };

    return (
        <th {...style} {...otherThProps} className={classNames}>
            <div className={styles.content}>
                {startAdornment}
                {children}
                {endAdornment}
            </div>
        </th>
    );
}

//
// Td
// ---------------------------------------------------------------------------------------------------------------------

export interface TdProps
    extends Omit<ComponentProps<"td">, "align">,
        VariantProps<typeof tdVariants> {}

export function Td({
    children,
    alignLeft,
    alignCenter,
    alignRight,
    muted,
    ...otherTdProps
}: TdProps) {
    const tdVariantsProps = { alignLeft, alignCenter, alignRight, muted },
        classNames = tdVariants(tdVariantsProps);

    return (
        <td {...otherTdProps} className={classNames}>
            <div className={styles.content}>{children}</div>
        </td>
    );
}

//
// Public API
// ---------------------------------------------------------------------------------------------------------------------

export const Table = Object.assign(TableRoot, {
    THead,
    TBody,
    TFoot,
    Tr,
    Th,
    Td,
});

export default Table;
