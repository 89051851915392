import { Delete, UploadFile } from "@mui/icons-material";
import css from "./MediaToolbar.module.scss";
import { useTranslation } from "react-i18next";
import mediaToolbarCallbackHandler from "@/components/atoms/MediaToolbar/MediaToolbarCallbackHandler";
import {
    type MediaToolbarAction,
    type MediaToolbarActionCallback,
    MediaToolbarActions,
} from "@/components/atoms/MediaToolbar/MediaToolbar.types";

export interface MediaToolbarProps {
    /**
     * Provide a list of actions here. If omitted, all actions are added by
     * default. If no actions are preferred, provide an empty array.
     */
    actions?: MediaToolbarAction[];

    /**
     * Provide callbacks for the various actions.
     */
    callbacks?: MediaToolbarActionCallback[];
}

const ALL_ACTIONS = Object.values(MediaToolbarActions);

export function MediaToolbar({
    actions = ALL_ACTIONS,
    callbacks,
}: MediaToolbarProps) {
    const { t } = useTranslation("common", { keyPrefix: "media-toolbar" });

    const getLabelForButton = (action: MediaToolbarAction) => {
        switch (action) {
            case MediaToolbarActions.DELETE:
                return t("delete");
            case MediaToolbarActions.CHANGE_IMAGE:
                return t("change-image");
        }
    };

    const getIconForButton = (action: MediaToolbarAction) => {
        switch (action) {
            case MediaToolbarActions.DELETE:
                return <Delete />;
            case MediaToolbarActions.CHANGE_IMAGE:
                return <UploadFile />;
        }
    };

    const createButton = (action: MediaToolbarAction, index: number) => {
        return (
            <button
                key={`${action}-${index}`}
                className={css.Button}
                title={getLabelForButton(action)}
                onClick={(evt) => {
                    mediaToolbarCallbackHandler(action, evt, callbacks);
                }}
            >
                {getIconForButton(action)}
            </button>
        );
    };

    return (actions?.length ?? 0) > 0 ? (
        <div className={css.MediaToolbar}>
            {actions?.map((action, index) => createButton(action, index))}
        </div>
    ) : null;
}

export default MediaToolbar;
