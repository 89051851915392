import type { ReactNode } from "react";
import { DialogStack } from "@bespeak/ui";

export namespace DialogProvider {
    export interface Props {
        children: ReactNode;
    }
}

export function DialogProvider({ children }: DialogProvider.Props) {
    return <DialogStack.Provider backdrop>{children}</DialogStack.Provider>;
}

export default DialogProvider;
