import type { ReactNode, HTMLAttributes } from "react";
import clsx from "clsx";
import css from "./ReferenceCardActions.module.css";

export interface ReferenceCardActionsProps
    extends Pick<HTMLAttributes<HTMLDivElement>, "className"> {
    children?: ReactNode;
}

export default function ReferenceCardActions(props: ReferenceCardActionsProps) {
    return (
        <div
            data-testid="reference-card-actions"
            className={clsx(css.ReferenceCardActions, props.className)}
        >
            {props.children ? props.children : null}
        </div>
    );
}
