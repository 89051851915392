import type { HTMLAttributes, ReactNode } from "react";
import clsx from "clsx";
import css from "./ReferenceCardSubtitle.module.css";

export interface ReferenceCardSubtitleProps
    extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
    className?: string;
}

export default function ReferenceCardSubtitle(
    props: ReferenceCardSubtitleProps,
) {
    return (
        <div
            data-testid="reference-card-subtitle"
            className={clsx(css.ReferenceCardSubtitle, props.className)}
        >
            {props.children}
        </div>
    );
}
