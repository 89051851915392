import Stack from "@mui/material/Stack";
import {
    type ContentBuildingBlock,
    type ListItemType,
} from "@/components/organisms/BuildingBlockMapper";
import ContentListItem from "@/components/molecules/ContentListItem/ContentListItem";
import { DndContext, type DragEndEvent } from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "@/components/molecules/SortableItem/SortableItem";
import { useMemo } from "react";
import css from "./ContentListItems.module.scss";
import {
    ContentBlockLayout,
    ContentBlockListLayout,
} from "../../../../../packages/apollo";
import ListLayoutSelector from "@/components/molecules/ListLayoutSelector";
import { Block } from "@/components/atoms/Block";
import type { ValidationError } from "yup";

export interface ContentListItemsProps extends ContentBuildingBlock {
    onChange?: (buildingBlock: ContentBuildingBlock) => void;
    list: {
        layout: ContentBlockListLayout;
        items: ListItemType[];
    };
    errors?: ValidationError[];
}

export function ContentListItems(props: ContentListItemsProps) {
    const { layout, list, errors } = props;

    type SortableContentListItemType = {
        [id in keyof ListItemType]-?: ListItemType[id];
    };

    const sortableItems = useMemo(
        () =>
            list.items?.filter(
                (item): item is SortableContentListItemType =>
                    item.id != undefined,
            ) ?? [],
        [list.items],
    );

    const handleOnAddItem = () => {
        props.onChange?.({
            ...props,
            list: {
                ...list,
                items: [...(list.items ?? []), { id: Date.now() }],
            },
        });
    };

    const handleOnRemoveItem = (index: number) => () => {
        props.onChange?.({
            ...props,
            list: {
                ...list,
                items: list.items?.filter((_, i) => i !== index),
            },
        });
    };

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const oldIndex = sortableItems.findIndex((item) => {
                return item.id === active.id;
            });

            const newIndex = sortableItems.findIndex(
                (item) => item.id === over?.id,
            );

            props.onChange?.({
                ...props,
                list: {
                    ...list,
                    items: arrayMove(sortableItems, oldIndex, newIndex),
                },
            });
        }
    }

    function handleListLayoutChange(layout: ContentBlockListLayout) {
        props.onChange?.({
            ...props,
            list: {
                ...list,
                layout,
            },
        });
    }

    /**
     * Construct the props for a single item. These include handlers for all
     * updateable fields which in turn call back to the original
     * `props.onChange`-handler.
     *
     * @param item Item to be mapped to props
     * @param index Index of the item (used to determine whether the last item
     *  is being mapped, as that has a specific add-button)
     */
    function mapItemToProps(item: ListItemType, index: number) {
        return {
            item,
            listLayout: list.layout,
            onMediaChange: (image: string | null) => {
                props.onChange?.({
                    ...props,
                    list: {
                        ...props.list,
                        items: sortableItems.map((a) =>
                            a.id === item.id
                                ? {
                                      ...a,
                                      assetId: image ?? undefined,
                                  }
                                : a,
                        ),
                    },
                });
            },
            onTitleChange: (value: string) => {
                props.onChange?.({
                    ...props,
                    list: {
                        ...props.list,
                        items: sortableItems.map((a) =>
                            a.id === item.id ? { ...a, title: value } : a,
                        ),
                    },
                });
            },
            onSubtitleChange: (value: string) => {
                props.onChange?.({
                    ...props,
                    list: {
                        ...props.list,
                        items: sortableItems.map((a) =>
                            a.id === item.id ? { ...a, subtitle: value } : a,
                        ),
                    },
                });
            },
            onContentChange: (value: string) => {
                props.onChange?.({
                    ...props,
                    list: {
                        ...props.list,
                        items: sortableItems.map((a) =>
                            a.id === item.id ? { ...a, content: value } : a,
                        ),
                    },
                });
            },
            onRemoveItem:
                sortableItems.length > 1
                    ? handleOnRemoveItem(index)
                    : undefined,
            onAddItem: handleOnAddItem,
            showAddButton: sortableItems.length - 1 === index,
            errors: errors?.filter((error) =>
                error.path?.startsWith(`list.items[${index}].`),
            ),
        };
    }

    function wrapItemsInSortableContainer() {
        return (
            <DndContext onDragEnd={handleDragEnd}>
                <SortableContext
                    items={sortableItems}
                    strategy={verticalListSortingStrategy}
                >
                    {sortableItems?.map((item, index) => (
                        <SortableItem id={item.id} key={item.id}>
                            <div>
                                <ContentListItem
                                    {...mapItemToProps(item, index)}
                                    showAddButton={
                                        index === list.items.length - 1
                                    }
                                />
                            </div>
                        </SortableItem>
                    ))}
                </SortableContext>
            </DndContext>
        );
    }

    const layoutOptions = {
        // The layout options for when the list is displayed beside the block content
        beside: [{ type: ContentBlockListLayout.OneColumnNumbered }],

        // Layout options for when the list is displayed below the block content
        below: [
            { type: ContentBlockListLayout.TwoColumns },
            { type: ContentBlockListLayout.TwoColumnsWithImage },
            { type: ContentBlockListLayout.TwoColumnsWithNumbers },
            { type: ContentBlockListLayout.ThreeColumns },
            { type: ContentBlockListLayout.ThreeColumnsWithNumbers },
        ],
    };

    // Block layouts in which the list is displayed beside the content.
    const besideLayouts = [
        ContentBlockLayout.ImageLeft,
        ContentBlockLayout.ImageRight,
    ];

    return (
        <>
            <Stack gap={1} flex={1} className={css.ContentListItems}>
                <Stack gap={"1rem"} useFlexGap flexWrap="wrap">
                    <Block.Header>
                        <Stack gap={1} direction="row" alignItems={"center"}>
                            <ListLayoutSelector
                                value={list.layout}
                                options={
                                    besideLayouts.includes(layout)
                                        ? layoutOptions.beside
                                        : layoutOptions.below
                                }
                                onChange={(newLayout) =>
                                    handleListLayoutChange(
                                        newLayout as ContentBlockListLayout,
                                    )
                                }
                                labelKey={"select-list-layout"}
                                keyPrefix={"content-list-block"}
                            />
                        </Stack>
                    </Block.Header>
                    <Block.Main>
                        <Stack gap={3}>{wrapItemsInSortableContainer()}</Stack>
                    </Block.Main>
                </Stack>
            </Stack>
        </>
    );
}

export default ContentListItems;
