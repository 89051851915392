import type {
    ExternalVideoAssetDto,
    FileAssetDto,
} from "../../../../../packages/apollo";
import { useEffect, useState } from "react";
import type { AssetUploadMode } from "@/components/organisms/AssetUpload/AssetUpload.component";
import { isExternalVideoAsset, isFileAsset } from "@bespeak/ui";

export function useModeWithDefault(
    availableModes: AssetUploadMode[],
    detailedAsset: FileAssetDto | ExternalVideoAssetDto | undefined,
) {
    const modeWithSetMode = useState<AssetUploadMode | undefined>(
        availableModes.length === 1 ? availableModes[0] : undefined,
    );
    const [, setMode] = modeWithSetMode;

    // Set the initial mode for existing assets based on the asset type.
    useEffect(() => {
        if (isExternalVideoAsset(detailedAsset)) {
            setMode("external_video");
        } else if (isFileAsset(detailedAsset)) {
            setMode("file");
        }
    }, [detailedAsset, setMode]);

    return modeWithSetMode;
}
