import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import type { MpcAnswerType } from "@/components/organisms/BuildingBlockMapper";
import Input from "@/components/atoms/Input";
import Media from "@/components/atoms//Media";
import FauxRadio from "@/components/atoms/FauxRadio";
import css from "./MpcImageAnswer.module.scss";
import { useTranslation } from "react-i18next";
import WysiwygEditor from "@/components/atoms/WysiwygEditor";
import clsx from "clsx";
import type { ValidationError } from "yup";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";

export interface MpcImageAnswerProps {
    onRadioChange: (checked: boolean) => void;
    onMediaChange: (image: string | null) => void;
    onInputChange: (value: string) => void;
    onContentChange: (value: string) => void;
    onAddQuestion: () => void;
    onRemoveQuestion?: () => void;
    canHaveContent?: boolean;
    answer: MpcAnswerType;
    className?: string;
    errors?: ValidationError[];
    showAddButton: boolean;
}

export function MpcImageAnswer({
    answer,
    onMediaChange,
    onInputChange,
    onRadioChange,
    className,
    onRemoveQuestion,
    onContentChange,
    onAddQuestion,
    canHaveContent,
    errors,
    showAddButton,
}: MpcImageAnswerProps) {
    const { t } = useTranslation("common", { keyPrefix: "mpc-answers" });
    const modules = {
        toolbar: [["bold", "italic", "underline", "strike"]],
    };

    const labelError = resolveError({
        errors,
        resolver: (error) => error?.path?.endsWith("label") ?? true,
    });

    const imageError = resolveError({
        errors,
        resolver: (error) => error?.path?.endsWith("image.id") ?? false,
    });

    return (
        <div className={clsx(css.imageBlock, className)}>
            <div className={css.radio}>
                <FauxRadio
                    checked={answer.isCorrect}
                    onChange={onRadioChange}
                />
            </div>
            <div className={clsx(css.thumb, { [css.Error]: imageError })}>
                <Media image={answer.image} onChange={onMediaChange} />
            </div>
            <Stack gap={1}>
                {labelError && (
                    <ValidationErrorLabel
                        keyPrefix={"mpc-answers.label"}
                        error={labelError}
                    />
                )}
                <Input
                    value={answer.label || ""}
                    onChange={onInputChange}
                    placeholder={t("answer")}
                    className={css.input}
                />
            </Stack>
            {canHaveContent && (
                <WysiwygEditor
                    modules={modules}
                    value={answer.content}
                    placeholder={t("start-typing", "Begin hier te typen...")}
                    onChange={(jsonReply) => {
                        onContentChange(jsonReply);
                    }}
                />
            )}
            <Stack direction="row" className={css.addAndDelete}>
                {onRemoveQuestion && (
                    <IconButton
                        onClick={onRemoveQuestion}
                        title={t("delete-answer")}
                    >
                        <Delete />
                    </IconButton>
                )}
                {showAddButton && (
                    <IconButton onClick={onAddQuestion} title={t("add-answer")}>
                        <Add />
                    </IconButton>
                )}
            </Stack>
        </div>
    );
}

export default MpcImageAnswer;
