import { memo, useRef } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { useBoundingclientrect, useOutsideClick } from "rooks";
import { useMediaQuery } from "usehooks-ts";
import Dialog from "@/Dialog/Dialog.component";
import type { PopupProps } from "@/Popup";
import Popup from "@/Popup";
import Spot from "@/Spot";
import clsx from "clsx";
import css from "./Hotspot.module.css";
import { Button } from "@/Button";

export interface HotspotProps {
    /**
     * Relative x position of the hotspot.
     */
    x?: number | undefined | null;
    /**
     * Relative y position of the hotspot.
     */
    y?: number | undefined | null;
    /**
     * Title of the hotspot popup.
     */
    title?: string | undefined | null;
    /**
     * Content of the hotspot popup.
     */
    content?: string | undefined | null;
    /**
     * Whether the hotspot is active or not.
     */
    active?: boolean | undefined | null;
    /**
     * Callback for when the hotspot is clicked.
     */
    onClick?: () => void;
    /**
     * Callback for when the hotspot popup is clicked outside.
     */
    onOutsideClick?: () => void;
    /**
     * Configuratble slots for the hotspot.
     */
    slots?: {
        popup?: {
            Title?: (props?: PopupProps) => JSX.Element;
            Content?: (props?: PopupProps) => JSX.Element;
        };
    };

    parentRef?: React.RefObject<HTMLElement>;
}

/**
 * Note that the parent element of the hotspot should have a position of
 * relative or absolute.
 */
export const Hotspot = memo(function RenderHotspot(props: HotspotProps) {
    const { t } = useTranslation("common", {
        keyPrefix: "dialog",
    });

    const containerRef = useRef<HTMLDivElement>(null),
        portalRef = useRef<HTMLDivElement>(null),
        parentBoundingClientRect = useBoundingclientrect(
            props.parentRef || {
                current: containerRef.current?.parentElement
                    ? containerRef.current?.parentElement
                    : null,
            },
        );

    const isMobile = useMediaQuery("(max-width: 768px)");

    const x = Number(props.x),
        y = Number(props.y);

    const parentWidth = Number(parentBoundingClientRect?.width),
        parentHeight = Number(parentBoundingClientRect?.height),
        parentLeft = Number(parentBoundingClientRect?.left) + window.scrollX,
        parentTop = Number(parentBoundingClientRect?.top) + window.scrollY;

    const relativeLeft = x * parentWidth,
        relativeTop = y * parentHeight;

    const absoluteLeft = parentLeft + x * parentWidth,
        absoluteTop = parentTop + y * parentHeight;

    const isOnLeftHalfOfParentElement =
        absoluteLeft >= parentLeft &&
        absoluteLeft <= parentLeft + parentWidth / 2;

    const isOnRightHalfOfParentElement =
        absoluteLeft > parentLeft + parentWidth / 2 &&
        absoluteLeft <= parentLeft + parentWidth;

    const popupPosition = isOnLeftHalfOfParentElement
        ? "left"
        : isOnRightHalfOfParentElement
          ? "right"
          : undefined;

    useOutsideClick(portalRef, () => {
        props.onOutsideClick?.();
    });

    if (parentBoundingClientRect?.width == null) return;

    return (
        <div
            data-testid="hotspot"
            className={clsx(css.Hotspot)}
            ref={containerRef}
            style={{
                top: `${relativeTop}px`,
                left: `${relativeLeft}px`,
            }}
        >
            {props.active === false ? (
                <Spot onClick={props.onClick} />
            ) : (
                createPortal(
                    !isMobile ? (
                        <div
                            data-testid="hotspot-popup-container"
                            ref={portalRef}
                            style={{
                                position: "absolute",
                                top: `${absoluteTop}px`,
                                left: `${absoluteLeft}px`,
                                width: 0,
                                height: 0,
                            }}
                        >
                            <Popup
                                active={props.active}
                                title={props.title}
                                content={props.content}
                                position={popupPosition}
                                slots={props.slots?.popup}
                            >
                                {({ active }) => (
                                    <Spot
                                        active={active}
                                        onClick={props.onClick}
                                    />
                                )}
                            </Popup>
                        </div>
                    ) : (
                        <Dialog active={props.active} backdrop>
                            <Dialog.Body onClickOutside={props.onClick}>
                                <Dialog.Header
                                    closeDialog={props.onClick}
                                    title={props.title || ""}
                                />
                                <Dialog.Content content={props.content} />
                                <Dialog.Footer>
                                    <Button
                                        variant="text"
                                        size="tiny"
                                        onClick={props.onOutsideClick}
                                    >
                                        {t("close", "Sluiten")}
                                    </Button>
                                </Dialog.Footer>
                            </Dialog.Body>
                        </Dialog>
                    ),
                    document.body,
                )
            )}
        </div>
    );
});

export default Hotspot;
