import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import type { SelectChangeEvent } from "@mui/material/Select";
import MuiSelect from "@mui/material/Select";

interface SelectProps {
    value: string;
    label: string;
    onChange: (value: string) => void;
    options: { label: string; value: string | boolean }[];
    disabled?: boolean;
}

function Select({ label, onChange, value, options, disabled }: SelectProps) {
    const handleChange = (e: SelectChangeEvent) => {
        onChange(e.target.value);
    };

    return (
        <FormControl fullWidth disabled={disabled}>
            <InputLabel id="overview-table-select-filter-label">
                {label}
            </InputLabel>
            <MuiSelect
                labelId="overview-table-select-filter-label"
                id="overview-table-select-filter"
                value={value}
                label={label}
                onChange={handleChange}
                disabled={disabled}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </MuiSelect>
        </FormControl>
    );
}

export default Select;
