import css from "./CarouselIndicators.module.css";
import CarouselIndicator from "./CarouselIndicator.component";

export interface CarouselIndicatorsProps {
    slides: unknown[];
    index: number;
}

export default function CarouselIndicators(props: CarouselIndicatorsProps) {
    const { slides, index } = props;

    return (
        <div
            data-testid="carousel-indicators"
            className={css.CarouselIndicators}
        >
            {slides.map((_, slideIndex) => (
                <CarouselIndicator
                    key={slideIndex}
                    active={index === slideIndex}
                />
            ))}
        </div>
    );
}
