import type { ComponentProps, CSSProperties } from "react";

interface StackProps extends ComponentProps<"div"> {
    display?: CSSProperties["display"];
    direction?: CSSProperties["flexDirection"];
    alignItems?: CSSProperties["alignItems"];
    justifyContent?: CSSProperties["justifyContent"];
    gap?:
        | "none"
        | "xs"
        | "s"
        | "m"
        | "l"
        | "xl"
        | "xxl"
        | "xxxl"
        | CSSProperties["gap"];
}

export function Stack({
    display = "flex",
    direction = "row",
    alignItems,
    justifyContent,
    gap,
    ...props
}: StackProps) {
    return (
        <div
            data-testid="stack"
            style={{
                display,
                flexDirection: direction,
                alignItems,
                justifyContent,
                gap: `var(--spacing-${gap})`,
            }}
            {...props}
        />
    );
}
