import { useDropzone } from "react-dropzone";
import { UploadFile } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useAuthenticatedFetchLazy } from "@/lib/useFetch";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { FigureWithId } from "@bespeak/ui";
import { useTranslation } from "react-i18next";
import css from "./Media.module.scss";
import type { ValidationError } from "yup";
import clsx from "clsx";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import MediaToolbar, {
    type MediaToolbarAction,
    MediaToolbarActions,
} from "@/components/atoms/MediaToolbar";
import noop from "@/lib/noop";

export interface MediaProps {
    image?: { id?: number | string; src?: string };
    onChange?: (id: string | null) => void;
    label?: string;
    error?: ValidationError;
    imageActions?: MediaToolbarAction[];
}

/**
 * @deprecated Use AssetUpload instead.
 */
export function Media(props: MediaProps) {
    const { image, onChange = noop, imageActions } = props;
    const { t } = useTranslation("common", { keyPrefix: "media" });

    const [progress, setProgress] = useState(false);

    const { uploadAsset } = useAuthenticatedFetchLazy();

    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        maxFiles: 1,
        onDrop: onDrop,
        accept: {
            "image/*": [".png", ".jpg", ".jpeg"],
        },
    });

    async function onDrop(files: File[]) {
        if (!files[0]) return;

        setProgress(true);

        await uploadAsset(files[0])
            .then((data) => {
                onChange(data.id);
            })
            .finally(() => {
                setProgress(false);
            });
    }

    function deleteImage() {
        onChange(null);
    }

    return (
        <>
            <ValidationErrorLabel keyPrefix={"media"} error={props.error} />
            <Box
                sx={{
                    flexBasis: "50%",
                    position: "relative",
                    ["&:hover .Image"]: {
                        opacity: 0.2,
                    },
                    ["&:hover .Border"]: {
                        opacity: 1.0,
                    },
                    maxHeight: "40vh",
                }}
                className={clsx(css.Image, { [css.Error]: props.error })}
                {...getRootProps()}
            >
                <input {...getInputProps()} />

                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "20% 50%",
                        transition: "opacity 0.2s ease-in-out",
                        backgroundColor: "rgb(0 0 0 / 0.1)",
                    }}
                >
                    {image?.id && image.id !== "new" ? (
                        <FigureWithId id={image.id.toString()} cover="cover" />
                    ) : (
                        <div></div>
                    )}
                </Box>

                {!image?.id || image.id === "new" ? (
                    <Box
                        sx={{
                            inset: 2,
                            position: "absolute",
                            border: "3px dashed rgb(255 255 255 / 1)",
                            transition: "opacity 0.2s ease-in-out",
                            opacity: image?.src ? 0.0 : 1.0,
                            display: "grid",
                            placeContent: "center",
                        }}
                    >
                        <Stack
                            justifyContent="center"
                            alignItems="center"
                            gap={1}
                        >
                            <>
                                {progress ? (
                                    <CircularProgress />
                                ) : (
                                    <>
                                        <UploadFile />
                                        <Typography
                                            align="center"
                                            variant="body1"
                                        >
                                            {props.label ||
                                                t(
                                                    "upload-image",
                                                    "Upload afbeelding",
                                                )}
                                        </Typography>
                                    </>
                                )}
                            </>
                        </Stack>
                    </Box>
                ) : null}

                {image?.id && image.id !== "new" ? (
                    <Box
                        sx={{
                            inset: 2,
                            position: "absolute",
                            display: "grid",
                            placeContent: "center",
                        }}
                    >
                        <MediaToolbar
                            actions={imageActions}
                            callbacks={[
                                {
                                    action: MediaToolbarActions.DELETE,
                                    callback: () => deleteImage(),
                                },
                            ]}
                        />
                    </Box>
                ) : null}
            </Box>
        </>
    );
}

export default Media;
