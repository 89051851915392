import { useTranslation } from "react-i18next";
export function useGetLabel(type: string) {
    const { t } = useTranslation("common");
    let label = "";

    switch (type) {
        case "EXAM":
            label = t("didactic-tools.types.exam", "Examen");
            break;
        case "TEST_YOURSELF":
            label = t("didactic-tools.types.test-yourself", "Test jezelf");
            break;
        case "ENTRY_TEST":
            label = t("didactic-tools.types.entry-test", "Instaptoets");
            break;
        case "BOOK_CHECK":
            label = t("didactic-tools.types.bookcheck", "Boekcheck");
            break;
        case "CASE":
            label = t("didactic-tools.types.case", "Case");
            break;
        case "WIKI":
            label = t("didactic-tools.types.wiki", "Wiki");
            break;
        case "ASSIGNMENT":
            label = t("didactic-tools.types.assignment", "Opdracht");
            break;
        case "QUIZ":
            label = t("didactic-tools.types.quiz", "Quiz");
            break;
        case "LESSON":
            label = t("didactic-tools.types.lesson", "Les");
            break;
        case "BOOK":
            label = t("didactic-tools.types.book", "Boek");
            break;
        case "LINEAR_VIDEO":
            label = t("didactic-tools.types.linear-video", "Lineaire Video");
            break;
        case "VIDEO_INTERACTIVE":
            label = t("didactic-tools.types.video-interactive", "Masterclass");
            break;
        case "reference-card":
            label = t("search.types.reference-card", "Fact card");
            break;
        case "module":
            label = t("search.types.module", "Module");
            break;
        case "learning-path":
            label = t("search.types.learning-path", "Leerpad");
            break;
        default:
            label = "";
            break;
    }

    return {
        label,
    };
}
