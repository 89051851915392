import Stack from "@mui/material/Stack";
import type { ConversationPersonType } from "@/components/organisms/BuildingBlockMapper";
import { DndContext, type DragEndEvent } from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "@/components/molecules/SortableItem/SortableItem";
import ConversationPerson from "../ConversationPerson/ConversationPerson";
import css from "./ConversationPersons.module.scss";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import type { ValidationError } from "yup";

export interface ConversationPersonsProps {
    persons: ConversationPersonType[];
    errors?: ValidationError[];
    onChange: (persons: ConversationPersonType[]) => void;
}

export function ConversationPersons(props: ConversationPersonsProps) {
    const { persons, errors } = props;

    const minError = resolveError({ errors, path: "persons", type: "min" });
    const errorLabels = minError ? (
        <div className={css.error}>
            {minError && (
                <ValidationErrorLabel
                    keyPrefix={"conversation-persons"}
                    error={minError}
                />
            )}
        </div>
    ) : null;

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const oldIndex = persons.findIndex(
                (person) => person.id === active.id,
            );
            const newIndex = persons.findIndex(
                (person) => person.id === over?.id,
            );

            props.onChange(arrayMove(persons, oldIndex, newIndex));
        }
    }

    return (
        <Stack gap={1} flex={1}>
            <Stack
                gap={"1rem"}
                direction={"column"}
                useFlexGap
                flexWrap="wrap"
                flexBasis="100%"
            >
                {errorLabels}
                <DndContext onDragEnd={handleDragEnd}>
                    <SortableContext
                        items={persons}
                        strategy={verticalListSortingStrategy}
                    >
                        {persons.map((person, index) => {
                            const personProps = {
                                person,
                                errors: errors?.filter((err) =>
                                    err.path?.startsWith(`persons[${index}]`),
                                ),
                                onMediaChange: (image: string | null) => {
                                    props.onChange(
                                        persons.map((p) =>
                                            p.id === person.id
                                                ? {
                                                      ...p,
                                                      assetId:
                                                          image || undefined,
                                                  }
                                                : p,
                                        ),
                                    );
                                },
                                onInputChange: (value: string) => {
                                    props.onChange(
                                        persons.map((p) =>
                                            p.id === person.id
                                                ? { ...p, name: value }
                                                : p,
                                        ),
                                    );
                                },
                                onRemovePerson:
                                    persons.length > 1
                                        ? () => {
                                              props.onChange(
                                                  persons.filter(
                                                      (p) => p.id !== person.id,
                                                  ),
                                              );
                                          }
                                        : undefined,
                                onAddPerson: () => {
                                    props.onChange([
                                        ...persons,
                                        {
                                            id: Date.now(),
                                            name: "",
                                            order: persons.length,
                                        },
                                    ]);
                                },
                            };

                            return (
                                <SortableItem key={person.id} id={person.id}>
                                    <div className={css.person}>
                                        <ConversationPerson {...personProps} />
                                    </div>
                                </SortableItem>
                            );
                        })}
                    </SortableContext>
                </DndContext>
            </Stack>
        </Stack>
    );
}

export default ConversationPersons;
