import type { HTMLAttributes } from "react";
import clsx from "clsx";
import css from "./ReferenceCardList.module.css";
import Asset from "@/Asset";
import type { ReferenceCardListItemDto } from "@bespeak/apollo";
import ConvertedHTML from "@/ConvertedHTML";

export interface ReferenceCardListProps extends HTMLAttributes<HTMLDivElement> {
    list: [];
    className?: string;
}

export default function ReferenceCardList(props: ReferenceCardListProps) {
    return (
        <div
            data-testid="reference-card-list"
            className={clsx(css.ReferenceCardList, props.className)}
        >
            {props.list.map((item: ReferenceCardListItemDto) => (
                <div key={item.id} className={clsx(css.ReferenceCardListItem)}>
                    <div className={clsx(css.ReferenceCardListItemWrap)}>
                        {item.assetId && (
                            <div
                                className={clsx(
                                    css.ReferenceCardListItemImgWrap,
                                )}
                            >
                                <div
                                    className={clsx(
                                        css.ReferenceCardListItemImg,
                                    )}
                                >
                                    <Asset.Lazy
                                        assetId={item.assetId}
                                        aspectRatio="1"
                                    />
                                </div>
                            </div>
                        )}
                        <div className={clsx(css.ReferenceCardListItemBody)}>
                            <div
                                className={clsx(css.ReferenceCardListItemTitle)}
                            >
                                <h5>{item.title}</h5>
                                <h6>{item.subtitle}</h6>
                            </div>
                            <div
                                className={clsx(
                                    css.ReferenceCardListItemContent,
                                )}
                            >
                                <span>
                                    <ConvertedHTML value={item.content} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
