import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import type {
    ConversationMessageType,
    ConversationPersonType,
} from "@/components/organisms/BuildingBlockMapper";
import { useTranslation } from "react-i18next";
import PersonSelector from "../PersonSelector/PersonSelector";
import WysiwygEditor from "@/components/atoms/WysiwygEditor";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";

import type { ValidationError } from "yup";

export interface ConversationMessageProps {
    onInputChange: (value: string) => void;
    onAddMessage: () => void;
    onRemoveMessage?: () => void;
    onPersonChange: (value: string) => void;
    message: ConversationMessageType;
    persons?: ConversationPersonType[];
    errors?: ValidationError[];
}

export function ConversationMessage({
    message,
    errors,
    onInputChange,
    onRemoveMessage,
    onAddMessage,
    onPersonChange,
    persons,
}: ConversationMessageProps) {
    const { t } = useTranslation("common");

    const modules = {
        toolbar: [["bold", "italic"]],
    };

    const messageError = resolveError({
        errors,
        resolver: (error) => error?.path?.endsWith("message") ?? false,
    });

    return (
        <Stack
            key={message.id}
            direction="row"
            gap={1}
            component={Paper}
            sx={{ p: 2 }}
            flexBasis="100%"
            alignItems={"center"}
            justifyContent={"space-between"}
        >
            <Stack direction="row" flexBasis="100%" alignItems="center" gap={1}>
                <PersonSelector
                    value={message.personIndex}
                    onChange={onPersonChange}
                    label={t("select-person", "Persoon")}
                    options={
                        persons?.map((person, index) => {
                            return {
                                value: index.toString(),
                                name: person.name,
                            };
                        }) || []
                    }
                />
                <Stack flexBasis="100%">
                    <ValidationErrorLabel
                        keyPrefix={"conversation-messages.message"}
                        error={messageError}
                    />
                    <WysiwygEditor
                        placeholder={t("message", "Bericht")}
                        value={message.message}
                        onChange={onInputChange}
                        modules={modules}
                    />
                </Stack>
            </Stack>
            <Stack direction="row">
                {onRemoveMessage && (
                    <IconButton onClick={onRemoveMessage}>
                        <Delete />
                    </IconButton>
                )}
                <IconButton onClick={onAddMessage}>
                    <Add />
                </IconButton>
            </Stack>
        </Stack>
    );
}

export default ConversationMessage;
