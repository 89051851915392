import { useContext } from "react";
import { DidacticToolContext } from "./DidacticTool.context";

export namespace useDidacticTool {}

export function useDidacticTool() {
    const didacticToolContext = useContext(DidacticToolContext);

    if (!didacticToolContext) {
        throw new Error(
            "useDidacticTool must be used within a DidacticToolProvider",
        );
    }

    return {
        ...didacticToolContext,
    };
}
