import type { ComponentProps } from "react";
import clsx from "clsx";
import css from "./Block.module.scss";

//
// Root
// =============================================================================

function BlockRoot(props: ComponentProps<"div"> & { elevate?: boolean }) {
    const { children, className, elevate = true, ...rest } = props;

    return (
        <div
            data-testid="block"
            className={clsx(css.Block, { [css.Elevate]: elevate }, className)}
            {...rest}
        >
            {children}
        </div>
    );
}

//
// Header
// =============================================================================

function BlockHeader(props: ComponentProps<"div">) {
    const { children, className, ...rest } = props;

    return (
        <div
            data-testid="block-header"
            className={clsx(css.Header, className)}
            {...rest}
        >
            <div className={clsx(css.HeaderWrap)}>{children}</div>
        </div>
    );
}

//
// Main
// =============================================================================

function BlockMainRoot(props: ComponentProps<"div">) {
    const { children, className, ...rest } = props;

    return (
        <div
            data-testid="block-main"
            className={clsx(css.Main, className)}
            {...rest}
        >
            {children}
        </div>
    );
}

interface StackLayoutProps extends ComponentProps<"div"> {
    children: JSX.Element[];
    direction?: "row" | "column";
    reverse?: boolean;
}

function StackLayout(props: StackLayoutProps) {
    const {
        children,
        className,
        direction = "row",
        reverse = false,
        ...rest
    } = props;

    return (
        <div
            data-testid="block-main"
            className={clsx(css.StackLayout, className)}
            data-direction={direction}
            data-reverse={reverse}
            {...rest}
        >
            {children.map((child, index) => (
                <div key={index}>{child}</div>
            ))}
        </div>
    );
}

const BlockMain = Object.assign(BlockMainRoot, {
    StackLayout,
});

//
// Footer
// =============================================================================

function BlockFooter(props: ComponentProps<"div">) {
    const { children, className, ...rest } = props;

    return (
        <div data-testid="block-footer" className={clsx(className)} {...rest}>
            {children}
        </div>
    );
}

//
// Block Public
// =============================================================================

export const Block = Object.assign(BlockRoot, {
    Header: BlockHeader,
    Main: BlockMain,
    Footer: BlockFooter,
});

export default Block;
