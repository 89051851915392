import type { ReactNode } from "react";
import { Autocomplete as MuiAutocomplete } from "@mui/material";
import { TextField as MuiTextfield } from "@mui/material";
import { useFormContext, useFormState } from "react-hook-form";
import { Controller } from "react-hook-form";

export namespace Autocomplete {
    interface Option {
        value: string;
        label: string;
        indent?: number;
    }

    export interface Props {
        label: string;
        error?: boolean;
        helperText?: string;
        options: Option[];
        startAdornment?: ReactNode;
        endAdornment?: ReactNode;
        multiple?: boolean;
        name: string;
    }
}

export function Autocomplete(props: Autocomplete.Props) {
    const { control } = useFormContext();

    const { errors, isSubmitted } = useFormState({ control }),
        error = errors[props.name],
        errorMessage =
            typeof error?.message === "string" ? error.message : undefined,
        hasError = Boolean(errorMessage);

    return (
        <Controller
            control={control}
            name={props.name}
            render={({ field: { onChange, onBlur, value, ref } }) => (
                <MuiAutocomplete
                    disablePortal
                    multiple={props.multiple}
                    options={props.options}
                    getOptionLabel={(option) => option.label}
                    filterSelectedOptions
                    onChange={(_, data) => onChange(data)}
                    value={value}
                    getOptionKey={(option) => option.value}
                    isOptionEqualToValue={(option, value) => {
                        return option.value === value.value;
                    }}
                    renderInput={(params) => (
                        <MuiTextfield
                            {...params}
                            label={props.label}
                            error={(isSubmitted && hasError) || props.error}
                            helperText={
                                (isSubmitted && errorMessage) ||
                                props.helperText
                            }
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        {props.startAdornment}
                                        {params.InputProps.startAdornment}
                                    </>
                                ),
                                endAdornment: (
                                    <>
                                        {params.InputProps.endAdornment}
                                        {props.endAdornment}
                                    </>
                                ),
                            }}
                            onBlur={onBlur}
                            ref={ref}
                        />
                    )}
                />
            )}
        />
    );
}
