import type { State } from "./ProgressBar.State";
import { Icon } from "./Icon.component";
import { dot } from "./dot";

export namespace Dot {
    export interface Props {
        /**
         * The state of the dot.
         */
        state: State;

        /**
         * The function to call when the dot is clicked.
         */
        onClick?: () => void;
    }
}

export function Dot(props: Dot.Props) {
    function handleClick() {
        props.onClick?.();
    }

    return (
        <div data-testid="dot" onClick={handleClick} className={dot(props)}>
            <Icon state={props.state} />
        </div>
    );
}
