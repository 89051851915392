import { useState, type MouseEvent } from "react";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import type { GridRowSelectionModel } from "@mui/x-data-grid";
import type { OverviewProps } from "./OverviewTable.types";

export const BulkMenu = ({
    bulkActions,
    selection,
}: {
    bulkActions: OverviewProps["bulkActions"];
    selection?: GridRowSelectionModel;
}) => {
    const [bulkMenuIsOpen, setBulkMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const bulkMenuOpen = () => {
        setBulkMenuOpen(false);
    };

    const bulkMenuClose = (event: MouseEvent<HTMLElement>) => {
        setBulkMenuOpen(true);
        setAnchorEl(event.currentTarget);
    };

    return (
        <Box sx={{ ml: 1 }}>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={bulkMenuIsOpen ? "long-menu" : undefined}
                aria-expanded={bulkMenuIsOpen ? "true" : undefined}
                aria-haspopup="true"
                onClick={bulkMenuClose}
                color="inherit"
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    "aria-labelledby": "long-button",
                }}
                open={bulkMenuIsOpen}
                onClose={bulkMenuOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {bulkActions?.map((group, groupIndex) =>
                    group.map((action, actionIndex) => (
                        <MenuItem
                            key={`${groupIndex}-${actionIndex}`}
                            divider={
                                actionIndex === group.length - 1 &&
                                bulkActions.length - 1 !== groupIndex
                            }
                            {...action}
                            onClick={() => {
                                typeof action.onClick === "function" &&
                                    action.onClick(selection || []);
                            }}
                        />
                    )),
                )}
            </Menu>
        </Box>
    );
};

export default BulkMenu;
