import type { ReactNode } from "react";
import ConvertedHTML from "@/ConvertedHTML";

export type DialogContentProps = {
    content?: string | null | undefined;
    children?: ReactNode;
};

export const DialogContent = ({ content, children }: DialogContentProps) => {
    const _content = content ? <ConvertedHTML value={content} /> : null,
        _children = children ? children : null;

    return (
        <div>
            {_content}
            {_children}
        </div>
    );
};

export default DialogContent;
