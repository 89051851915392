import type { AssetDto } from "@bespeak/apollo";
import useRefreshingFetch from "@/contexts/RefreshingFetch/useRefreshingFetch";

type FileAssetUploadMetadata = {
    type: "FILE";
};

type ExternalVideoAssetUploadMetadata = {
    type: "EXTERNAL_VIDEO";
    id?: string | null;
    videoUrl: string;
    loop: boolean;
    autoplay: boolean;
};

type AssetUploadMetadata =
    | FileAssetUploadMetadata
    | ExternalVideoAssetUploadMetadata;

export function useAuthenticatedFetchLazy() {
    const { refreshingFetch } = useRefreshingFetch();

    const request = async (url: string, init?: RequestInit) => {
        const headers = new Headers();

        if (init?.headers) {
            for (const [key, value] of Object.entries(init.headers)) {
                headers.append(key, value as string);
            }
        }

        return await refreshingFetch(url, {
            cache: "no-cache",
            credentials: "same-origin",
            redirect: "follow",
            referrerPolicy: "no-referrer",
            ...init,
            headers,
        });
    };

    /**
     * Upload an asset to the server. This can either be a file-based asset
     * or an external video asset.
     *
     * @param file File part, can be omitted if `meta.type ==== "EXTERNAL_VIDEO"`
     * @param meta Metadata for the asset. Can be omitted if `file` is provided,
     *  must be included for the `EXTERNAL_VIDEO` type.
     */
    const uploadAsset = async (file?: File, meta?: AssetUploadMetadata) => {
        if (!file && !meta) {
            throw Error("No file or metadata provided for asset upload");
        }
        if (meta?.type === "FILE" && !file) {
            throw Error("Meta type is FILE but no file provided");
        }

        const formData = new FormData();
        if (file) {
            formData.append("file", file, file.name);
        }
        if (meta) {
            formData.append(
                "meta",
                new Blob([JSON.stringify(meta)], {
                    type: "application/json",
                }),
            );
        }

        const url = `${import.meta.env.VITE_REST_API}/assets`;

        return await request(url, {
            method: "POST",
            body: formData,
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
                throw new Error("Network response was not ok.");
            })
            .then((data: AssetDto) => {
                // Expose the data as it's proper type for downstreams to be able
                // to depend on it.
                return data;
            });
    };

    return { request, uploadAsset };
}

export async function useAuthenticatedFetch(url: string, init?: RequestInit) {
    const { request } = useAuthenticatedFetchLazy();
    return await request(url, init);
}

export default useAuthenticatedFetch;
