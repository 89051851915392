import ReferenceCardBase from "./ReferenceCardBase.component";
import ReferenceCardMedia from "./ReferenceCardMedia.component";
import ReferenceCardHeader from "./ReferenceCardHeader.component";
import ReferenceCardLead from "./ReferenceCardLead.component";
import ReferenceCardTitle from "./ReferenceCardTitle.component";
import ReferenceCardSubtitle from "./ReferenceCardSubtitle.component";
import ReferenceCardMain from "./ReferenceCardMain.component";
import ReferenceCardFooter from "./ReferenceCardFooter.component";
import ReferenceCardScroll from "./ReferenceCardScroll.component";
import ReferenceCardList from "./ReferenceCardList.component";
import ReferenceCardActions from "./ReferenceCardActions.component";

const ReferenceCard = Object.assign(ReferenceCardBase, {
    Media: ReferenceCardMedia,
    Header: ReferenceCardHeader,
    Lead: ReferenceCardLead,
    Title: ReferenceCardTitle,
    Subtitle: ReferenceCardSubtitle,
    Main: ReferenceCardMain,
    Footer: ReferenceCardFooter,
    Scroll: ReferenceCardScroll,
    List: ReferenceCardList,
    Actions: ReferenceCardActions,
});

export default ReferenceCard;
