import Stack from "@mui/material/Stack";
import Input from "@/components/atoms/Input";
import Block from "@/components/atoms/Block";
import Media from "@/components/atoms/Media";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import FauxRadio from "@/components/atoms/FauxRadio";
import css from "./StandQuestionBuildingBlock.module.scss";
import { useState } from "react";
import SelectBgColor from "./common/SelectBgColor/SelectBgColor.component";
import clsx from "clsx";
import { StandBlockLayout } from "@bespeak/apollo";
import LayoutSelector from "@/components/molecules/LayoutSelector";
import WysiwygEditor from "@/components/atoms/WysiwygEditor";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import {
    type BuildingBlocksLabel,
    BuildingBlockType,
    type StandQuestionBuildingBlock,
} from "@/components/organisms/BuildingBlockMapper";
import SelectLabel from "@/components/organisms/BuildingBlockMapper/blocks/common/SelectLabel";

export interface StandQuestionBuildingBlockComponentProps
    extends StandQuestionBuildingBlock {
    availableLabels: BuildingBlocksLabel[];
    onChange?: (buildingBlock: StandQuestionBuildingBlock) => void;
}

export function StandQuestionBuildingBlockComponent(
    props: StandQuestionBuildingBlockComponentProps,
) {
    const { t } = useTranslation("common");
    const [bgColor, setBgColor] = useState(props.background || "DEFAULT");

    const [label, setLabel] = useState(props.label || null);

    const modulesForFeedback = {
        toolbar: [["bold", "italic", "underline", "strike"], [{ header: [6] }]],
    };
    const layout = props.layout || StandBlockLayout.TextLeft;

    type Fields = Pick<
        StandQuestionBuildingBlock,
        | "standText"
        | "stand"
        | "instruction"
        | "agreeText"
        | "disagreeText"
        | "background"
        | "correctFeedbackText"
        | "incorrectFeedbackText"
    >;

    const handleChange =
        (key: keyof Fields) => (value: Fields[keyof Fields]) => {
            props.onChange?.({
                ...props,
                [key]: value,
            });
        };

    const registerControl = (key: keyof Fields) => ({
        value: props[key],
        onChange: handleChange(key),
    });

    const imageBlock = (
        <Stack flexBasis="50%">
            <Media
                image={props.image}
                onChange={(image) => {
                    props.onChange?.({
                        ...props,
                        ...(image != null && {
                            image: { id: image },
                        }),
                        ...(image === null && {
                            image: undefined,
                        }),
                    });
                }}
                error={resolveError({ errors: props.errors, path: "image.id" })}
            />
        </Stack>
    );

    const introductionText = (
        <Input
            {...registerControl("standText")}
            placeholder={t("standText", "Introductie")}
            variant="body2"
        />
    );

    const standText = (
        <Input
            {...registerControl("stand")}
            placeholder={t("stand", "Stelling")}
            variant="body1"
            error={resolveError({ errors: props.errors, path: "stand" })}
        />
    );

    const instructionText = (
        <Input
            {...registerControl("instruction")}
            placeholder={t("instruction", "Instructie")}
            variant="body2"
        />
    );

    const correctText = (
        <WysiwygEditor
            {...registerControl("correctFeedbackText")}
            label={t(
                "correct-feedback-text-label",
                "Feedback bij correct antwoord",
            )}
            placeholder={t(
                "correct-feedback-text-placeholder",
                "Feedback bij correct antwoord",
            )}
            modules={modulesForFeedback}
        />
    );

    const incorrectText = (
        <WysiwygEditor
            {...registerControl("incorrectFeedbackText")}
            label={t(
                "incorrect-feedback-text-label",
                "Feedback bij incorrect antwoord",
            )}
            placeholder={t(
                "incorrect-feedback-text-placeholder",
                "Feedback bij incorrect antwoord",
            )}
            modules={modulesForFeedback}
        />
    );

    const agreeText = (
        <Button size="medium" disableRipple>
            <FauxRadio
                checked={props.correctOption === "AGREE"}
                onChange={(checked) => {
                    props.onChange?.({
                        ...props,
                        correctOption: checked ? "AGREE" : undefined,
                    });
                }}
            />
            <Input
                {...registerControl("agreeText")}
                placeholder={t("agreeText")}
                textAlign="center"
                className={css.buttonInput}
            />
        </Button>
    );

    const disagreeText = (
        <Button size="medium" disableRipple>
            <FauxRadio
                checked={props.correctOption === "DISAGREE"}
                onChange={(checked) => {
                    props.onChange?.({
                        ...props,
                        correctOption: checked ? "DISAGREE" : undefined,
                    });
                }}
            />
            <Input
                {...registerControl("disagreeText")}
                placeholder={t("disagreeText")}
                textAlign="center"
                className={css.buttonInput}
            />
        </Button>
    );

    const buttons = (
        <div>
            <ValidationErrorLabel
                keyPrefix={"stand-question-building-block"}
                error={resolveError({
                    errors: props.errors,
                    path: "correctOption",
                })}
                additionalTypeToKeyMapping={(type) => {
                    switch (type) {
                        case "required":
                        case "nullable":
                        case "optionality":
                            return "correct-option-required";
                        default:
                            return undefined;
                    }
                }}
            />
            <Stack direction="row" spacing={1}>
                {agreeText}
                {disagreeText}
            </Stack>
        </div>
    );

    return (
        <Block>
            <Block.Header>
                <Stack gap={1} direction="row" alignItems={"center"}>
                    <LayoutSelector
                        type={BuildingBlockType.STAND}
                        value={layout}
                        layoutOptions={[
                            { type: StandBlockLayout.ImageLeft },
                            { type: StandBlockLayout.ImageRight },
                            { type: StandBlockLayout.TextCentered },
                        ]}
                        onChange={(e) => {
                            props.onChange?.({
                                ...props,
                                layout: e as StandBlockLayout,
                            });
                        }}
                    />
                    <SelectBgColor
                        onChange={(event) => {
                            setBgColor(event);
                            props.onChange?.({
                                ...props,
                                background: event,
                            });
                        }}
                        value={bgColor}
                    />
                    <SelectLabel
                        options={props.availableLabels}
                        onChange={(newValue) => {
                            setLabel(newValue);
                            props.onChange?.({
                                ...props,
                                label: newValue,
                            });
                        }}
                        value={label}
                    />
                </Stack>
            </Block.Header>
            <Block.Main
                className={clsx({ [css.primary]: bgColor === "PRIMARY" })}
            >
                {layout === StandBlockLayout.TextLeft ? (
                    <Stack gap={1} flexBasis="50%">
                        {introductionText}
                        {standText}
                        {instructionText}
                        <Stack direction="column" spacing={2}>
                            {buttons}
                            <Stack direction="column" spacing={2}>
                                {correctText}
                                {incorrectText}
                            </Stack>
                        </Stack>
                    </Stack>
                ) : null}
                {layout === StandBlockLayout.TextCentered ? (
                    <Stack gap={1} flexBasis="100%" alignItems={"center"}>
                        {introductionText}
                        {standText}
                        {instructionText}
                        <Stack direction="column" spacing={2}>
                            {buttons}
                            <Stack direction="column" spacing={2}>
                                {correctText}
                                {incorrectText}
                            </Stack>
                        </Stack>
                    </Stack>
                ) : null}
                {layout === StandBlockLayout.ImageRight ? (
                    <Stack gap={4} direction="row" spacing={2}>
                        <Stack gap={1} flexBasis="50%">
                            {introductionText}
                            {standText}
                            {instructionText}
                            <Stack direction="column" spacing={2}>
                                {buttons}
                                <Stack direction="column" spacing={2}>
                                    {correctText}
                                    {incorrectText}
                                </Stack>
                            </Stack>
                        </Stack>
                        {imageBlock}
                    </Stack>
                ) : null}
                {layout === StandBlockLayout.ImageLeft ? (
                    <Stack gap={4} direction="row" spacing={2}>
                        {imageBlock}
                        <Stack gap={1} flexBasis="50%">
                            {introductionText}
                            {standText}
                            {instructionText}
                            <Stack direction="column" spacing={2}>
                                {buttons}
                                <Stack direction="column" spacing={2}>
                                    {correctText}
                                    {incorrectText}
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                ) : null}
            </Block.Main>
        </Block>
    );
}

export default StandQuestionBuildingBlockComponent;
