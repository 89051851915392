import { type ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useGetGroupsForAuthenticatedUserQuery } from "@bespeak/apollo";

interface GroupRequiredGuardProps {
    children: ReactNode;
}

export function GroupRequiredGuard(props: GroupRequiredGuardProps) {
    const { data, loading } = useGetGroupsForAuthenticatedUserQuery(),
        hasGroups = data?.getGroupsForAuthenticatedUser?.length;

    const { pathname } = useLocation(),
        isOnGroupPage = pathname === "/group";

    if (loading) return null;

    if (!isOnGroupPage && !hasGroups) {
        return <Navigate to="/group" replace={true} />;
    }

    return props.children;
}

export default GroupRequiredGuard;
