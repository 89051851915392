import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import type { QuestionConnectAnswerType } from "@/components/organisms/BuildingBlockMapper";
import Input from "@/components/atoms/Input";
import css from "./QuestionConnectAnswer.module.scss";
import { useTranslation } from "react-i18next";
import type { ValidationError } from "yup";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import Media from "@/components/atoms//Media";

export interface QuestionConnectAnswerProps {
    onMediaChange: (image: string | null) => void;
    onInputChange: (value: string) => void;
    onCorrectAnswerChange: (value: string) => void;
    onAddQuestion?: () => void;
    onRemoveQuestion?: () => void;
    errors?: ValidationError[];
    answer: QuestionConnectAnswerType;
}

export function QuestionConnectAnswer({
    answer,
    onMediaChange,
    onInputChange,
    onCorrectAnswerChange,
    onRemoveQuestion,
    onAddQuestion,
    errors,
}: QuestionConnectAnswerProps) {
    const { t } = useTranslation("common");

    const labelError = resolveError({
        errors,
        resolver: (error) => error?.path?.endsWith("label") ?? false,
    });

    const correctAnswerError = resolveError({
        errors,
        resolver: (error) => error?.path?.endsWith("correctAnswer") ?? false,
    });

    return (
        <Stack
            key={answer.id}
            direction="column"
            alignItems={"center"}
            component={Paper}
            className={css.QuestionConnectAnswer}
        >
            <div className={css.question}>
                {answer.image?.src && (
                    <img src={answer.image.src} className={css.image} />
                )}
                <Stack
                    key={answer.id}
                    direction="row"
                    alignItems={"center"}
                    className={css.QuestionConnectAnswer}
                    gap={1}
                >
                    <ValidationErrorLabel
                        keyPrefix={"question-connect.label-or-image"}
                        error={labelError}
                    />

                    <div className={css.thumb}>
                        <Media
                            image={answer.image}
                            label=" "
                            onChange={onMediaChange}
                        />
                    </div>
                    <div className={css.input}>
                        <Stack gap={1}>
                            <Input
                                value={answer.label}
                                onChange={onInputChange}
                                placeholder={t("question", "Vraag")}
                            />
                        </Stack>
                    </div>
                </Stack>
            </div>
            <div className={css.correctAnswer}>
                <Input
                    value={answer.correctAnswer?.content}
                    onChange={onCorrectAnswerChange}
                    placeholder={t("answer", "Antwoord")}
                    error={correctAnswerError}
                />
            </div>
            <div>
                <Stack direction="row">
                    {onRemoveQuestion && (
                        <IconButton onClick={onRemoveQuestion}>
                            <Delete />
                        </IconButton>
                    )}
                    {onAddQuestion && (
                        <IconButton onClick={onAddQuestion}>
                            <Add />
                        </IconButton>
                    )}
                </Stack>
            </div>
        </Stack>
    );
}

export default QuestionConnectAnswer;
