import type { ChangeEvent, ComponentProps, Key } from "react";
import { memo } from "react";
import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import type { BoxProps } from "@mui/material/Box";
import type {
    BuildingBlockBackground,
    HotspotBuildingBlockDto,
    SpotDto,
} from "@bespeak/apollo";
import Box from "@mui/material/Box";
import { HotspotBlockLayout } from "@bespeak/apollo";
import Block from "@/components/atoms/Block";
import LayoutSelector from "@/components/molecules/LayoutSelector";
import WysiwygEditor from "@/components/atoms/WysiwygEditor";
import Media from "@/components/atoms/Media";
import { BuildingBlockType } from "..";
import { Button, IconButton, Stack, TextField } from "@mui/material";
import { ForwardedFigureWithId, Hotspot } from "@bespeak/ui";
import useRelativeMousePosition from "@/hooks/useRelativeMousePosition";
import { Delete } from "@mui/icons-material";
import SelectBgColor from "@/components/organisms/BuildingBlockMapper/blocks/common/SelectBgColor";

export interface HotspotBuildingBlockProps extends HotspotBuildingBlockDto {
    onChange?: (buildingBlock: HotspotBuildingBlockProps) => void;
}

const layoutOptions = [
    { type: HotspotBlockLayout.ImageLeft },
    { type: HotspotBlockLayout.ImageRight },
    { type: HotspotBlockLayout.Centered },
];

export default function HotspotBuildingBlock(props: HotspotBuildingBlockProps) {
    function handleOnLayoutChange(layout: HotspotBlockLayout) {
        props.onChange?.({
            ...props,
            layout,
        });
    }

    function handleContentChange(content: string) {
        props.onChange?.({
            ...props,
            content,
        });
    }

    function handleImageChange(image: string | null | undefined) {
        props.onChange?.({
            ...props,
            assetId: image,
        });
    }

    function handleSpotsChange(spots: SpotDto[]) {
        props.onChange?.({
            ...props,
            spots,
        });
    }

    const bgColor = {
        DEFAULT: "",
        PRIMARY: `var(--color-background-primary)`,
    };

    return (
        <Block>
            <Block.Header>
                <LayoutSelector
                    type={BuildingBlockType.HOTSPOT}
                    defaultValue={HotspotBlockLayout.ImageLeft}
                    value={props.layout}
                    layoutOptions={layoutOptions}
                    // @ts-expect-error type of onchange should be more generic
                    // or based on the type of the layoutOptions
                    onChange={handleOnLayoutChange}
                />
                <SelectBgColor
                    onChange={(value) => {
                        props.onChange?.({
                            ...props,
                            background: value as BuildingBlockBackground,
                        });
                    }}
                    value={props.background}
                />
            </Block.Header>
            <Block.Main
                style={{
                    backgroundColor: bgColor[props.background],
                }}
            >
                <Block.Main.StackLayout
                    direction={
                        props.layout === HotspotBlockLayout.ImageLeft ||
                        props.layout === HotspotBlockLayout.ImageRight
                            ? "row"
                            : props.layout === HotspotBlockLayout.Centered
                              ? "column"
                              : undefined
                    }
                    reverse={
                        props.layout === HotspotBlockLayout.ImageRight ||
                        props.layout === HotspotBlockLayout.Centered
                    }
                >
                    <HotspotImage
                        assetId={props.assetId}
                        spots={props.spots}
                        onImageChange={handleImageChange}
                        onSpotsChange={handleSpotsChange}
                    />
                    <HotspotContent
                        content={props.content}
                        onChange={handleContentChange}
                    />
                </Block.Main.StackLayout>
            </Block.Main>
        </Block>
    );
}

//
// Image
// =============================================================================

interface HotspotImageProps
    extends Pick<HotspotBuildingBlockProps, "assetId" | "spots"> {
    onImageChange?: (image: string | undefined | null) => void;
    onSpotsChange?: (spots: SpotDto[]) => void;
    boxProps?: BoxProps;
}

const HotspotImage = memo(function HotspotImageRender(
    props: HotspotImageProps,
) {
    const ref = useRef(null);
    const [mousePosition] = useRelativeMousePosition(ref);
    const [activeHotspot, setActiveHotspot] = useState<Key | null>(null);

    const { t } = useTranslation();

    const translatedTitle = t("title"),
        translatedContent = t("content"),
        translatedUnlinkImage = t("unlink-image");

    function handleMediaChange(image: string | null | undefined) {
        props.onImageChange?.(image);
    }

    function handleNewSpotClick() {
        props.onSpotsChange?.([
            ...(props.spots || []),
            {
                x: mousePosition!.x,
                y: mousePosition!.y,
                title: "",
                content: "",
            },
        ]);
        setActiveHotspot(props.spots?.length);
    }

    function titleChangeHandler(index: Key) {
        return (event: ChangeEvent<HTMLInputElement>) => {
            props.onSpotsChange?.(
                props.spots?.map((spot, i) => {
                    if (i === index) {
                        return {
                            ...spot,
                            title: event.target.value,
                        };
                    }
                    return spot;
                }),
            );
        };
    }

    function contentChangeHandler(index: Key) {
        return (content: string) => {
            props.onSpotsChange?.(
                props.spots?.map((spot, i) => {
                    if (i === index) {
                        return {
                            ...spot,
                            content,
                        };
                    }
                    return spot;
                }),
            );
        };
    }

    function unlinkImage() {
        props.onImageChange?.(null);
    }

    function deleteSpot(index: Key) {
        props.onSpotsChange?.(props.spots?.filter((_, i) => i !== index));
    }

    return (
        <Box height="100%" position="relative" data-testid="hotspot-image">
            {props.assetId == null && (
                <Media
                    image={{ id: props.assetId }}
                    onChange={handleMediaChange}
                />
            )}

            <div
                style={{
                    opacity: props.assetId ? 1 : 0,
                    pointerEvents: props.assetId ? "all" : "none",
                }}
            >
                <Box>
                    <ForwardedFigureWithId
                        slotContainerRef={ref}
                        id={props.assetId}
                        cover="contain"
                        hideCursor={
                            mousePosition.y > 0.0 ||
                            mousePosition.x > 0.0 ||
                            mousePosition.y < 1.0 ||
                            mousePosition.x < 1.0
                        }
                        slot={
                            <>
                                <TargetCursor
                                    y={mousePosition.y}
                                    x={mousePosition.x}
                                    onClick={handleNewSpotClick}
                                />
                                {props.spots?.map((spot, index) => (
                                    <Hotspot
                                        {...spot}
                                        key={index}
                                        onClick={() =>
                                            activeHotspot === index
                                                ? setActiveHotspot(null)
                                                : setActiveHotspot(index)
                                        }
                                        onOutsideClick={() =>
                                            setActiveHotspot(null)
                                        }
                                        active={activeHotspot === index}
                                        slots={{
                                            popup: {
                                                Title: () => (
                                                    <TextField
                                                        label={translatedTitle}
                                                        variant="outlined"
                                                        value={spot.title || ""}
                                                        onChange={titleChangeHandler(
                                                            index,
                                                        )}
                                                        sx={{
                                                            marginTop: 1,
                                                            marginBottom: 1,
                                                        }}
                                                    />
                                                ),
                                                Content: () => (
                                                    <>
                                                        <WysiwygEditor
                                                            label={
                                                                translatedContent
                                                            }
                                                            value={
                                                                spot.content ||
                                                                ""
                                                            }
                                                            onChange={contentChangeHandler(
                                                                index,
                                                            )}
                                                        />
                                                        <Stack alignItems="end">
                                                            <IconButton
                                                                color="warning"
                                                                onClick={() =>
                                                                    deleteSpot(
                                                                        index,
                                                                    )
                                                                }
                                                            >
                                                                <Delete />
                                                            </IconButton>
                                                        </Stack>
                                                    </>
                                                ),
                                            },
                                        }}
                                        parentRef={ref}
                                    />
                                ))}
                            </>
                        }
                    />
                </Box>
                <Button
                    sx={{ my: "8px" }}
                    onClick={unlinkImage}
                    variant="outlined"
                >
                    {translatedUnlinkImage}
                </Button>
            </div>
        </Box>
    );
});

interface TargetCursorProps extends ComponentProps<"div"> {
    x: number;
    y: number;
}

function TargetCursor(props: TargetCursorProps) {
    const topPercent = Math.max(0, Math.min(100, props.y * 100)),
        leftPercent = Math.max(0, Math.min(100, props.x * 100));

    let opacity = 1;

    if (topPercent > 90) opacity = 1.2 - (props.y - 0.9) / 0.1;
    else if (leftPercent > 90) opacity = 1.2 - (props.x - 0.9) / 0.1;
    else if (props.x < 0.1) opacity = props.x * 10;
    else if (props.y < 0.1) opacity = props.y * 10;

    function handleClick(event: any) {
        if (props.x < 0 || props.y < 0 || props.x > 1 || props.y > 1) return;
        props.onClick?.(event);
    }

    return (
        <div
            style={{
                position: "absolute",
                backgroundColor: "rgb(255 255 255 / 0.3)",
                width: 16,
                height: 16,
                outline: "1px solid white",
                borderRadius: "50%",
                transform: "translate(-50%, -50%)",
                border: "1px solid rgb(0 0 0 / 0.3)",
                top: `${topPercent}%`,
                left: `${leftPercent}%`,
                opacity,
            }}
            onClick={handleClick}
        />
    );
}

//
//
// Hotspot Content Editor
// =============================================================================

const modules = {
    toolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ header: [3, 4, false] }],
    ],
};

interface HotspotContentProps
    extends Pick<HotspotBuildingBlockProps, "content"> {
    onChange?: (content: string) => void;
    boxProps?: BoxProps;
}

function HotspotContent(props: HotspotContentProps) {
    const { t } = useTranslation();

    function handleContentChange(content: string) {
        props.onChange?.(content);
    }

    return (
        <Box data-testid="hotspot-content">
            <WysiwygEditor
                modules={modules}
                value={props.content ?? ""}
                placeholder={t("start-typing", "Begin hier te typen...")}
                onChange={handleContentChange}
            />
        </Box>
    );
}
