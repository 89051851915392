/**
 * @fileoverview LayoutTypes
 *
 * Exposes all the layout types available in the CMS. Because codegen creates
 * a type for each layout, we need to merge them into a single type.
 *
 * To use this type as a type we can export it with the same name as the const
 * that holds a union of all the values.
 *
 * So note that this is no accident that we expose the type as LayoutTypes and
 * the const as LayoutTypes. This is so that we can use the type as a type and
 * the const as a value.
 */

import {
    MpcBlockLayout,
    ContentBlockLayout,
    StandBlockLayout,
    MoodboardBlockLayout,
    SortQuestionBlockLayout,
    QuestionConnectBlockLayout,
    QuoteBlockLayout,
    HotspotBlockLayout,
} from "@bespeak/apollo";

export const LayoutTypes = {
    ...MpcBlockLayout,
    ...ContentBlockLayout,
    ...StandBlockLayout,
    ...MoodboardBlockLayout,
    ...SortQuestionBlockLayout,
    ...QuestionConnectBlockLayout,
    ...QuoteBlockLayout,
    ...HotspotBlockLayout,
};

export type LayoutTypes =
    | MpcBlockLayout
    | ContentBlockLayout
    | StandBlockLayout
    | MoodboardBlockLayout
    | SortQuestionBlockLayout
    | QuestionConnectBlockLayout
    | QuoteBlockLayout
    | HotspotBlockLayout;
