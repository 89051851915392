import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const buildDate = import.meta.env.VITE_BUILD_TIMESTAMP;

void i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        debug: false,
        backend: {
            loadPath: `/locales/{{lng}}/{{ns}}.json?${buildDate}`,
        },
        lng: "nl",
        defaultNS: "common",
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
