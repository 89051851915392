import { Close, Menu } from "@mui/icons-material";
import type { MenuProps } from "@/components/organisms/MainMenu";
import css from "./LeftMobileMenu.module.scss";
import styles from "../../Breadcrumbs/Breadcrumbs.module.css";
import { useState } from "react";
import clsx from "clsx";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs.component";
import { Button } from "@bespeak/ui";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useHistoryPath from "@/lib/useHistoryPath/useHistoryPath";

const LeftMobileMenu = ({
    links,
    program,
    learningPath,
    module,
    didacticTool,
}: MenuProps) => {
    const [isOpen, setOpen] = useState(false);
    const [isTransitioning, setTransitioning] = useState(false);
    const navigate = useNavigate();

    const { pathname } = useLocation();

    const historyPath = useHistoryPath(pathname);

    const handleHistoryBack = () => {
        window.scrollTo(0, 0);
        navigate(historyPath);
    };

    const toggle = () => {
        if (isTransitioning) return;

        setOpen((open) => !open);

        setTransitioning(true);
        setTimeout(() => {
            setTransitioning(false);
        }, 200);
    };

    return (
        <div
            className={clsx(
                css.LeftMobileMenu,
                { [css.Open]: isOpen },
                { [css.Transitioning]: isTransitioning },
            )}
        >
            <div className={css.Links}>
                {links.map((link, index) => (
                    <a key={index} href={link.href}>
                        {link.label}
                    </a>
                ))}
            </div>
            {!module && (
                <button onClick={toggle} type="button">
                    {isOpen || isTransitioning ? <Close /> : <Menu />}
                </button>
            )}
            {module && (
                <div className={styles.Breadcrumbs}>
                    <Button
                        onClick={handleHistoryBack}
                        variant="text"
                        color="surfaceOnBackground"
                        size="small"
                        className={css.Back}
                        style={{
                            fontSize: "var(--base-font-size)",
                            padding: 0,
                        }}
                    >
                        <ArrowBackIcon
                            style={{ fontSize: "var(--base-font-size)" }}
                        />
                    </Button>
                    <Breadcrumbs
                        program={program!}
                        learningPath={learningPath}
                        module={module}
                        didacticTool={didacticTool}
                    />
                </div>
            )}
        </div>
    );
};

export default LeftMobileMenu;
