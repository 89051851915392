import type { HTMLAttributes } from "react";
import clsx from "clsx";
import css from "./CarouselNavigationButtonGroup.module.css";

export interface CarouselNavigationButtonGroupProps
    extends HTMLAttributes<HTMLDivElement> {}

export default function CarouselNavigationButtonGroup(
    props: CarouselNavigationButtonGroupProps,
) {
    const { className, children, ...rest } = props;

    const classNames = clsx(css.CarouselNavigationButtonGroup, className);

    return (
        <div
            data-testid="carousel-navigation-button-group"
            className={classNames}
            {...rest}
        >
            {children}
        </div>
    );
}
