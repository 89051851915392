import MediaCardRoot from "./MediaCardRoot.component";
import MediaCardHeader from "./MediaCardHeader.component";
import MediaCardActions from "./MediaCardActions.component";
import MediaCardMedia from "./MediaCardMedia.component";

/**
 * @example
 *   <MediaCard>
 *       <MediaCard.Media
 *          fit="contain"
 *          src={dog}
 *          alt="Dog" />
 *       <MediaCard.Header
 *           fileType="Type of link or file"
 *           title="Title"
 *           subtitle="Subtitle"
 *       />
 *       <MediaCard.Actions>
 *           <Button
 *               type="button"
 *               variant="text"
 *               endAdornment={<FollowLinkIcon />}
 *           >
 *               Open link
 *           </Button>
 *       </MediaCard.Actions>
 *   </MediaCard>
 */
export const MediaCard = Object.assign(MediaCardRoot, {
    Media: MediaCardMedia,
    Header: MediaCardHeader,
    Actions: MediaCardActions,
});

export default MediaCard;

import DownloadIcon from "./download.svg?react";
import FollowLinkIcon from "./follow-link.svg?react";

export { DownloadIcon, FollowLinkIcon };
