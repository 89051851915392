import type { ContentBuildingBlock } from "@/components/organisms/BuildingBlockMapper";
import Block from "../../../atoms/Block";
import Stack from "@mui/material/Stack";
import SelectBgColor from "./common/SelectBgColor/SelectBgColor.component";
import { useState } from "react";
import clsx from "clsx";
import WysiwygEditor from "@/components/atoms/WysiwygEditor/WysiwygEditor";
import css from "./ContentBuildingBlockEditor.module.scss";
import { ContentBlockLayout } from "@bespeak/apollo";
import LayoutSelector from "@/components/molecules/LayoutSelector";
import { useTranslation } from "react-i18next";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import { BuildingBlockType } from "@/components/organisms/BuildingBlockMapper";
import ContentListItems from "@/components/molecules/ContentListItems/ContentListItems";
import { AssetUpload } from "src/components/organisms/AssetUpload";

interface ContentBuildingBlockEditorComponentProps
    extends ContentBuildingBlock {
    elevate?: boolean;
    onChange?: (buildingBlock: ContentBuildingBlock) => void;
}

export function ContentBuildingBlockEditorComponent(
    props: ContentBuildingBlockEditorComponentProps,
) {
    const { type, list } = props;

    const { t } = useTranslation("common");
    const [bgColor, setBgColor] = useState(props.background || "DEFAULT");
    const modules = {
        toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [3, 4, false] }],
        ],
    };

    const listElement =
        type === BuildingBlockType.CONTENT_LIST && list ? (
            <ContentListItems {...props} list={list} />
        ) : null;

    const imageBlock = (
        <AssetUpload
            availableModes={["file", "external_video"]}
            showExternalVideoSettings={true}
            asset={props.asset}
            onChange={(asset) => {
                props.onChange?.({
                    ...props,

                    ...(asset != null && {
                        asset: asset,
                    }),

                    ...(asset === null && {
                        // Allow clearing the asset
                        asset: undefined,
                    }),
                });
            }}
            error={resolveError({ errors: props.errors, path: "asset.id" })}
        />
    );

    const contentBlock = (value: string, prop: string) => {
        const error = resolveError({ errors: props.errors, path: prop });

        return (
            <WysiwygEditor
                modules={modules}
                value={value}
                placeholder={t("start-typing", "Begin hier te typen...")}
                onChange={(jsonReply) => {
                    props.onChange?.({
                        ...props,
                        [prop]: jsonReply,
                    });
                }}
                error={error}
            />
        );
    };

    return (
        <Block elevate={props.elevate}>
            <Block.Header>
                <LayoutSelector
                    type={BuildingBlockType.CONTENT}
                    value={props.layout || ContentBlockLayout.TwoColumns}
                    layoutOptions={[
                        { type: ContentBlockLayout.TwoColumns },
                        { type: ContentBlockLayout.ImageLeft },
                        { type: ContentBlockLayout.ImageRight },
                        { type: ContentBlockLayout.TextCentered },
                    ]}
                    onChange={(e) => {
                        props.onChange?.({
                            ...props,
                            layout: e as ContentBlockLayout,
                        });
                    }}
                />
                <SelectBgColor
                    onChange={(event) => {
                        setBgColor(event);
                        props.onChange?.({
                            ...props,
                            background: event,
                        });
                    }}
                    value={bgColor}
                />
            </Block.Header>
            <Block.Main
                className={clsx({ [css.primary]: bgColor === "PRIMARY" })}
            >
                <Stack gap={4} direction="row">
                    {props.layout === ContentBlockLayout.TextCentered ? (
                        <>
                            <Stack
                                gap={1}
                                direction="column"
                                flexBasis={"100%"}
                            >
                                <div className={css.centerImage}>
                                    {imageBlock}
                                </div>
                                <Stack gap={1}>
                                    {contentBlock(
                                        props.contentColumnOne,
                                        "contentColumnOne",
                                    )}
                                </Stack>
                                {listElement}
                            </Stack>
                        </>
                    ) : null}
                    {props.layout === ContentBlockLayout.ImageRight ? (
                        <>
                            <Stack gap={1} flexBasis={"50%"}>
                                {contentBlock(
                                    props.contentColumnOne,
                                    "contentColumnOne",
                                )}
                                {listElement}
                            </Stack>
                            <Stack gap={1} flexBasis={"50%"}>
                                {imageBlock}
                            </Stack>
                        </>
                    ) : null}
                    {props.layout === ContentBlockLayout.ImageLeft ? (
                        <>
                            <Stack gap={1} flexBasis={"50%"}>
                                {imageBlock}
                            </Stack>
                            <Stack gap={1} flexBasis={"50%"}>
                                {contentBlock(
                                    props.contentColumnOne,
                                    "contentColumnOne",
                                )}
                                {listElement}
                            </Stack>
                        </>
                    ) : null}
                    {props.layout === ContentBlockLayout.TwoColumns ? (
                        <>
                            <Stack flexBasis={"100%"} gap={2}>
                                <Stack direction={"row"} gap={1}>
                                    <Stack gap={1} flexBasis={"50%"}>
                                        {contentBlock(
                                            props.contentColumnOne,
                                            "contentColumnOne",
                                        )}
                                    </Stack>
                                    <Stack gap={1} flexBasis={"50%"}>
                                        {contentBlock(
                                            props.contentColumnTwo ?? "",
                                            "contentColumnTwo",
                                        )}
                                    </Stack>
                                </Stack>
                                {listElement}
                            </Stack>
                        </>
                    ) : null}
                </Stack>
            </Block.Main>
        </Block>
    );
}

export default ContentBuildingBlockEditorComponent;
