import DialogStack from "./index";

export function DialogStackConsumer({ children }: DialogStack.Consumer.Props) {
    return (
        <DialogStack.Context.Consumer>
            {(value) => {
                if (value === null) {
                    throw new Error(
                        "DialogStackConsumer must be used within a DialogStackProvider",
                    );
                }
                return children(value);
            }}
        </DialogStack.Context.Consumer>
    );
}

export default DialogStackConsumer;
