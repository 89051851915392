import { Fragment, useState } from "react";
import { createPortal } from "react-dom";
import { useKey } from "rooks";
import DialogStack from "./index";

const DIALOG_ELEMENT_ID = "dialog-stack";
const BACKDROP_ELEMENT_ID = "dialog-stack-backdrop";

export function DialogStackProvider({
    children,
    backdrop,
}: DialogStack.Provider.Props) {
    const [stack, setStack] = useState<DialogStack.DialogDefinition[]>([]);

    const backdropActive = stack.length > 0 ? true : undefined;

    const backdropElement = findOrCreateDomElement(BACKDROP_ELEMENT_ID),
        portalElement = findOrCreateDomElement(DIALOG_ELEMENT_ID);

    const pop = () => {
        setStack((prev) => {
            const next = [...prev];
            next.pop();
            return next;
        });
    };

    const push = (dialog: DialogStack.DialogDefinition) => {
        setStack((prev) => {
            const next = [...prev];
            next.push(dialog);
            return next;
        });
    };

    const value: DialogStack.Context.Value = {
        stack,
        pop,
        push,
    };

    const handleEsc = () => {
        pop();
    };

    useKey("Escape", handleEsc);

    return (
        <DialogStack.Context.Provider value={value}>
            {children}
            {backdrop
                ? createPortal(
                      <DialogStack.Backdrop active={backdropActive} />,
                      backdropElement,
                  )
                : null}
            {createPortal(
                stack.map((dialog, index) => (
                    <Fragment key={index}>{dialog.component}</Fragment>
                )),
                portalElement,
            )}
        </DialogStack.Context.Provider>
    );
}

function findDomElement(id: string) {
    const domElement = document.getElementById(id);
    if (domElement && !(domElement instanceof HTMLDivElement)) {
        throw new Error(`Element with id "${id}" is not an HTMLDivElement`);
    }
    return domElement;
}

function createDomElement(id: string) {
    const domElement = document.createElement("div");
    domElement.id = id;
    document.body.appendChild(domElement);
    return domElement;
}

function findOrCreateDomElement(id: string) {
    return findDomElement(id) || createDomElement(id);
}

export default DialogStackProvider;
