import type DialogStack from ".";
import { useDialogStack } from ".";
import css from "./DialogStackBackdrop.module.css";

export function DialogStackBackdrop({ active }: DialogStack.Backdrop.Props) {
    const dialogStack = useDialogStack();

    function handleClick() {
        dialogStack.pop();
    }

    return (
        <div
            data-testid="backdrop"
            className={css.DialogStackBackdrop}
            data-active={active}
            onClick={handleClick}
        />
    );
}

export default DialogStackBackdrop;
