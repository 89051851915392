import type { ReactNode } from "react";
import { bar } from "./bar";

export namespace Bar {
    export interface Props {
        children: ReactNode;
    }
}

export function Bar(props: Bar.Props) {
    return (
        <div data-testid="bar" className={bar()}>
            {props.children}
        </div>
    );
}
