import { useMemo } from "react";
import { HttpLink } from "@apollo/client";
import { fetchWithCredentials } from "./fetchWithCredentials";

namespace useHttpLink {}

export function useHttpLink() {
    return useMemo(() => {
        return new HttpLink({
            uri: import.meta.env.VITE_API,
            fetch: fetchWithCredentials,
        });
    }, []);
}

export { type HttpLink };

export default useHttpLink;
