import { type ComponentProps, type ReactNode, useEffect } from "react";
import type { FieldValues, UseFormReturn } from "react-hook-form";
import type { SubmitErrorHandler } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import { useForm } from "react-hook-form";
import type { ObjectSchema } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@/Button";
import { Stack } from "@/Stack";
import { isFunction } from "lodash";
import { type Field } from "@bespeak/cms/src/components/organisms/DynamicForm";

export namespace FormRoot {
    interface FieldWatcher {
        fieldName: Field["name"];
        callback: (value: any, form: UseFormReturn) => void;
    }

    export interface Props<FormType extends FieldValues>
        extends Omit<ComponentProps<"form">, "onSubmit"> {
        onSubmit: SubmitHandler<FormType>;
        onErrors: SubmitErrorHandler<FormType>;
        onCancel?: () => void;
        submitLabel?: ReactNode;
        cancelLabel?: ReactNode;
        data?: Record<string, unknown>;
        schema?: ObjectSchema<any>;
        watchers?: FieldWatcher[];
    }
}

export function FormRoot<FormType extends FieldValues>({
    children,
    submitLabel = "Submit",
    cancelLabel = "Cancel",
    onSubmit,
    onCancel,
    onErrors,
    schema,
    watchers = [],
    ...props
}: FormRoot.Props<FormType>) {
    const methods = useForm({
        defaultValues: props.data,
        resolver: schema ? yupResolver(schema) : undefined,
    });

    const showSubmit = Boolean(submitLabel) && isFunction(onSubmit),
        showCancel = Boolean(cancelLabel) && isFunction(onCancel);

    function handleOnCancel() {
        onCancel?.();
    }

    // Attach external watchers' callbacks to the form
    useEffect(() => {
        if (watchers?.length <= 0) return;

        const subscription = methods.watch((value, { name }) => {
            watchers
                ?.find((watcher) => watcher.fieldName === name)
                ?.callback(value, methods);
        });

        return () => subscription.unsubscribe();
    }, [watchers, methods]);

    return (
        <FormProvider {...methods}>
            <form
                {...props}
                onSubmit={methods.handleSubmit(onSubmit, onErrors)}
            >
                <Stack direction="column" gap="l">
                    {children}
                    <Stack direction="row" gap="s">
                        {showSubmit && (
                            <Button variant="filled" size="small" type="submit">
                                {submitLabel}
                            </Button>
                        )}
                        {showCancel && (
                            <Button
                                variant="text"
                                size="small"
                                type="button"
                                onClick={handleOnCancel}
                            >
                                {cancelLabel}
                            </Button>
                        )}
                    </Stack>
                </Stack>
            </form>
        </FormProvider>
    );
}
