import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import Input from "@/components/atoms/Input";
import css from "./ContentListItem.module.scss";
import { useTranslation } from "react-i18next";
import WysiwygEditor from "@/components/atoms/WysiwygEditor";
import clsx from "clsx";
import type { ValidationError } from "yup";
import type { ListItemType } from "@/components/organisms/BuildingBlockMapper";
import { ContentBlockListLayout } from "../../../../../packages/apollo";
import { useMemo } from "react";
import { AssetUpload } from "@/components/organisms/AssetUpload";
import { useDetailedAsset } from "@bespeak/ui/src/hooks";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";

export interface ContentListItemProps {
    item: ListItemType;
    listLayout: ContentBlockListLayout;
    onMediaChange: (image: string | null) => void;
    onTitleChange: (value: string) => void;
    onSubtitleChange: (value: string) => void;
    onContentChange: (value: string) => void;
    onAddItem: () => void;
    onRemoveItem?: () => void;
    errors?: ValidationError[];
    showAddButton: boolean;
}

const layoutsWithImages = [ContentBlockListLayout.TwoColumnsWithImage];

export function ContentListItem(props: ContentListItemProps) {
    const {
        item,
        listLayout,
        onTitleChange,
        onSubtitleChange,
        onRemoveItem,
        onContentChange,
        onAddItem,
        showAddButton,
        errors,
    } = props;

    const { t } = useTranslation("common", { keyPrefix: "content-list-item" });
    const modules = {
        toolbar: [["bold", "italic", "underline", "strike"]],
    };
    const detailedAsset = useDetailedAsset(item.assetId);

    const canHaveImages = useMemo(
        () => layoutsWithImages.includes(listLayout),
        [listLayout],
    );

    const inputs = (
        <>
            <Input
                value={item.title}
                onChange={onTitleChange}
                placeholder={t("title")}
                className={css.input}
                error={resolveError({
                    errors,
                    resolver: (error) =>
                        error.path?.endsWith(".title") ?? false,
                })}
            />
            <Input
                value={item.subtitle}
                onChange={onSubtitleChange}
                placeholder={t("subtitle")}
                className={css.input}
            />
            <WysiwygEditor
                modules={modules}
                value={item.content}
                placeholder={t("start-typing", "Begin hier te typen...")}
                onChange={(jsonReply) => {
                    onContentChange(jsonReply);
                }}
            />
        </>
    );

    return (
        <div className={css.ContentListItem}>
            <Stack gap={1}>
                {canHaveImages ? (
                    <Stack direction="row" gap={1}>
                        <div className={clsx(css.thumb)}>
                            <AssetUpload
                                availableModes={["file"]}
                                onChange={(newAsset) => {
                                    props.onMediaChange(newAsset?.id ?? null);
                                }}
                                asset={detailedAsset}
                                error={resolveError({
                                    errors,
                                    resolver: (error) =>
                                        error.path?.endsWith(".assetId") ??
                                        false,
                                })}
                            />
                        </div>
                        <div style={{ flexGrow: 1 }}>{inputs}</div>
                    </Stack>
                ) : (
                    <>{inputs}</>
                )}
            </Stack>
            <Stack direction="row" className={css.addAndDelete}>
                {onRemoveItem && (
                    <IconButton onClick={onRemoveItem} title={t("delete-item")}>
                        <Delete />
                    </IconButton>
                )}
                {showAddButton && (
                    <IconButton onClick={onAddItem} title={t("add-item")}>
                        <Add />
                    </IconButton>
                )}
            </Stack>
        </div>
    );
}

export default ContentListItem;
