import css from "./CarouselEditor.module.scss";
import { useState } from "react";
import { AddCircleOutlined, Delete } from "@mui/icons-material";
import clsx from "clsx";
import {
    type BuildingBlock,
    createNewBlock,
} from "@/components/organisms/BuildingBlockMapper";
import { BuildingBlockMapperComponent } from "@/components/organisms/BuildingBlockMapper/BuildingBlockMapper.component";
import { Button } from "@mui/material";
import type { ValidationError } from "yup";
import { useTranslation } from "react-i18next";
import { Carousel } from "@bespeak/ui";

export interface CarouselEditorProps {
    blocks: BuildingBlock[];
    onChange: (blocks: BuildingBlock[]) => void;
    errors?: ValidationError[];
}

export const CarouselEditor = ({
    blocks,
    onChange,
    errors,
}: CarouselEditorProps) => {
    const { t } = useTranslation("common", { keyPrefix: "carousel-editor" });
    const [index, setIndex] = useState(0);

    const handleAddNewSlide = () => {
        onChange([...blocks, createNewBlock()]);
    };

    const handleSlideDataChanged = (block: BuildingBlock) => {
        onChange(blocks.map((item) => (item.id === block?.id ? block : item)));
    };

    const handleDeleteCurrentSlide = () => {
        onChange(blocks.filter((_, i) => i !== index));
    };

    const resolveErrorsForBlock = (block: BuildingBlock) => {
        return (
            errors?.reduce((acc, error) => {
                if (error.path?.startsWith(`blocks.${block.id}.`)) {
                    acc.push({
                        ...error,
                        // Remove the block id from the path, as nested blocks
                        // expect the path to be relative to themselves
                        path: error.path?.replace(`blocks.${block.id}.`, ""),
                    });
                }
                return acc;
            }, [] as ValidationError[]) ?? []
        );
    };

    // Wrap the slides in a div to have them work within the flexbox carousel
    const slides = blocks.map((block) => {
        return (
            <BuildingBlockMapperComponent
                key={block.id}
                {...block}
                errors={resolveErrorsForBlock(block)}
                elevate={false}
                onChange={handleSlideDataChanged}
            />
        );
    });

    // Slide which acts as a large button to add a new slide
    const addNewSlide = (
        <div
            // key={slides.length}
            className={clsx(css.AddNew)}
            onClick={handleAddNewSlide}
            title={t("add-step")}
        >
            <AddCircleOutlined fontSize="large" />
        </div>
    );

    // Combine the fetched slides with the "add new"-slide
    const slidesWithAddNew = [...slides, addNewSlide];

    return (
        <div className={css.CarouselEditor}>
            <Carousel
                slides={slidesWithAddNew}
                overrideIndicators={slidesWithAddNew.map((_, i) => (
                    // Provide custom indicators with support for error highlighting
                    <div
                        key={i}
                        className={clsx(
                            css.Indicator,
                            {
                                [css.Active]: i === index,
                            },
                            {
                                [css.Error]:
                                    i < blocks.length
                                        ? resolveErrorsForBlock(blocks[i])
                                              .length > 0
                                        : false,
                            },
                        )}
                    />
                ))}
                onIndexChanged={setIndex}
                additionalControllerButtons={
                    <Button
                        type="button"
                        variant={"outlined"}
                        size={"small"}
                        color={"error"}
                        title={t("remove-step")}
                        style={{
                            visibility:
                                index < slidesWithAddNew.length - 1
                                    ? "visible"
                                    : "hidden", // Hide the delete button on the "add new" slide
                        }}
                        onClick={handleDeleteCurrentSlide}
                    >
                        <Delete />
                    </Button>
                }
                carouselClassName={css.Carousel}
            />
        </div>
    );
};

export default CarouselEditor;
