import { useTranslation } from "react-i18next";

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    type SelectChangeEvent,
} from "@mui/material";

interface SelectBgColorProps {
    onChange?: (value: string) => void;
    value?: string;
}

const SelectBgColor = (props: SelectBgColorProps) => {
    const { t } = useTranslation("common");

    const handleChange = (event: SelectChangeEvent<string>): void => {
        const value = event.target.value;
        props.onChange?.(value);
    };

    return (
        <FormControl sx={{ minWidth: 150 }}>
            <InputLabel id="select-bg-color-label">
                {t("background-color", "Achtergrondkleur")}
            </InputLabel>
            <Select
                labelId="select-bg-color-label"
                id="select-bg-color"
                label={t("background-color", "Achtergrond kleur")}
                value={props.value}
                onChange={handleChange}
            >
                <MenuItem value="DEFAULT" selected={true}>
                    {t("none", "Geen")}
                </MenuItem>
                <MenuItem value="PRIMARY">{t("primary", "Primary")}</MenuItem>
            </Select>
        </FormControl>
    );
};

export default SelectBgColor;
