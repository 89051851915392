import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import type { SelectChangeEvent } from "@mui/material/Select";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

interface SelectProps {
    value: string[];
    label: string;
    onChange: (value: string[]) => void;
    options: { label: string; value: string }[];
    maxWidth?: number;
}

function MultiSelect({
    label,
    onChange,
    value,
    options,
    maxWidth,
}: SelectProps) {
    const handleChange = (e: SelectChangeEvent<string[]>) => {
        const eventValue = e.target.value;
        const val =
            typeof eventValue === "string" ? eventValue.split(",") : eventValue;

        onChange(val);
    };

    return (
        <FormControl
            fullWidth
            sx={{ minWidth: 200, maxWidth: maxWidth || 300, flex: 1 }}
        >
            <InputLabel id="overview-table-multi-select-filter-label">
                {label}
            </InputLabel>
            <Select
                labelId="overview-table-multi-select-filter-label"
                id="overview-table-multi-select-filter"
                multiple
                value={value || []}
                label={label}
                renderValue={(selected) => {
                    return selected
                        .map((select: string) => {
                            const fieldWithLabel = options?.find(
                                (e) => e.value === select,
                            );
                            return fieldWithLabel?.label;
                        }, [])
                        .join(", ");
                }}
                onChange={handleChange}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        <Checkbox
                            checked={value?.includes(option.value) || false}
                        />
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default MultiSelect;
