import type { ReactNode, HTMLAttributes } from "react";
import clsx from "clsx";
import css from "./ReferenceCardFooter.module.css";

export interface ReferenceCardFooterProps
    extends Pick<HTMLAttributes<HTMLDivElement>, "className"> {
    children?: ReactNode;
}

export default function ReferenceCardFooter(props: ReferenceCardFooterProps) {
    return (
        <div
            data-testid="reference-card-footer"
            className={clsx(css.ReferenceCardFooter, props.className)}
        >
            {props.children ? props.children : null}
        </div>
    );
}
