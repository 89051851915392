import type { HTMLAttributes, ReactNode } from "react";
import clsx from "clsx";
import css from "./ReferenceCardBase.module.css";
import { noop } from "@vitest/utils";

interface ReferenceCardBaseProps
    extends Pick<
        HTMLAttributes<HTMLDivElement>,
        "className" | "tabIndex" | "style"
    > {
    children: ReactNode;
    raise?: boolean;
    focus?: boolean;
    expand?: boolean;
    onClick?: () => void;
}

export default function ReferenceCardBase(props: ReferenceCardBaseProps) {
    return (
        <div
            data-testid="reference-card"
            className={clsx(css.ReferenceCardBase, props.className)}
            data-raise={props.raise ? "" : undefined}
            data-focus={props.focus ? "" : undefined}
            tabIndex={props.tabIndex}
            style={props.style}
            onClick={props.onClick ?? noop}
        >
            {props.children}
        </div>
    );
}
