import Stack from "@mui/material/Stack";
import {
    type SortQuestionAnswers,
    type SortQuestionBuildingBlock,
} from "@/components/organisms/BuildingBlockMapper";
import { SortQuestionBlockLayout } from "@bespeak/apollo";
import SortQuestionTextAnswer from "../SortQuestionTextAnswer/SortQuestionTextAnswer";
import css from "./SortQuestionAnswers.module.scss";
import clsx from "clsx";
import { DndContext, type DragEndEvent } from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "@/components/molecules/SortableItem/SortableItem";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";

export interface sortQuestionAnswersProps extends SortQuestionBuildingBlock {
    onChange?: (buildingBlock: SortQuestionBuildingBlock) => void;
}

export function SortQuestionAnswers(props: sortQuestionAnswersProps) {
    const {
        answers,
        layout = SortQuestionBlockLayout.TwoColumns,
        errors,
    } = props;

    const minError = resolveError({ errors, path: "answers", type: "min" });
    const minOneCorrectError = resolveError({
        errors,
        path: "answers",
        type: "min-three-correct-answer",
    });

    const errorLabels =
        minError || minOneCorrectError ? (
            <div className={css.error}>
                {minError && (
                    <ValidationErrorLabel
                        keyPrefix={"sort-answers"}
                        error={minError}
                    />
                )}
                {minOneCorrectError && (
                    <ValidationErrorLabel
                        keyPrefix={"sort-answers"}
                        error={minOneCorrectError}
                        additionalTypeToKeyMapping={(type?: string) =>
                            type === "min-three-correct-answer"
                                ? type
                                : undefined
                        }
                    />
                )}
            </div>
        ) : null;

    type SortableAnswerType = {
        [id in keyof SortQuestionAnswers]-?: SortQuestionAnswers[id];
    };

    const sortableAnswers =
        answers?.filter(
            (answer): answer is SortableAnswerType => answer.id != undefined,
        ) ?? [];

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const oldIndex = sortableAnswers.findIndex((item) => {
                return item.id === active.id;
            });

            const newIndex = sortableAnswers.findIndex(
                (item) => item.id === over?.id,
            );

            props.onChange?.({
                ...props,
                answers: arrayMove(sortableAnswers, oldIndex, newIndex),
            });
        }
    }

    const handleOnAddItem = () => {
        props.onChange?.({
            ...props,
            answers: [
                ...(props.answers ?? []),
                { id: Date.now(), label: "", order: 0 },
            ],
        });
    };

    const handleOnRemoveItem = (index: number) => () => {
        props.onChange?.({
            ...props,
            answers: props.answers?.filter((_, i) => i !== index),
        });
    };

    function findErrorsForAnswer(index: number) {
        return (
            errors?.filter((error) =>
                error?.path?.startsWith(`answers[${index}].`),
            ) ?? []
        );
    }

    function mapAnswerToProps(answer: SortQuestionAnswers, index: number) {
        return {
            answer,
            onInputChange: (value: string) => {
                props.onChange?.({
                    ...props,
                    answers: sortableAnswers.map((a) =>
                        a.id === answer.id
                            ? { ...a, label: value, order: index }
                            : a,
                    ),
                });
            },
            errors: findErrorsForAnswer(index),
            onRemoveItem:
                sortableAnswers.length > 1
                    ? handleOnRemoveItem(index)
                    : undefined,
            onAddItem: handleOnAddItem,
        };
    }

    function wrapAnswersInSortableContainer() {
        return (
            <DndContext onDragEnd={handleDragEnd}>
                <SortableContext
                    items={sortableAnswers}
                    strategy={verticalListSortingStrategy}
                >
                    {sortableAnswers?.map((answer, index) => (
                        <SortableItem id={answer.id} key={answer.id}>
                            <div
                                className={clsx({
                                    [css.centered]:
                                        layout ===
                                        SortQuestionBlockLayout.TextCentered,
                                })}
                            >
                                <SortQuestionTextAnswer
                                    {...mapAnswerToProps(answer, index)}
                                    showAddButton={index === answers.length - 1}
                                    showRemoveButton={index >= 1}
                                />
                            </div>
                        </SortableItem>
                    ))}
                </SortableContext>
            </DndContext>
        );
    }

    return (
        <Stack gap={1} flex={1}>
            {errorLabels}
            {wrapAnswersInSortableContainer()}
        </Stack>
    );
}

export default SortQuestionAnswers;
