import {
    type MediaToolbarAction,
    type MediaToolbarActionCallback,
    MediaToolbarActions,
} from "@/components/atoms/MediaToolbar/MediaToolbar.types";

/**
 * Some buttons have default behavior. These callbacks are listed here. Keep in
 * mind that these callbacks are not called if a custom callback is provided.
 * Also, event propagation is not stopped for these callbacks, so if you want
 * a default callback that does not propagate, you must stop propagation
 * within that callback specifically.
 */
const DEFAULT_CALLBACKS: MediaToolbarActionCallback[] = [
    {
        action: MediaToolbarActions.CHANGE_IMAGE,
        callback: () => {},
    },
];

export function mediaToolbarCallbackHandler(
    action: MediaToolbarAction,
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    callbacks?: MediaToolbarActionCallback[],
) {
    // Find the correct callback to use
    const overrideCallback = callbacks?.find((c) => c.action === action);
    const defaultCallback = overrideCallback
        ? undefined
        : DEFAULT_CALLBACKS.find((c) => c.action === action);

    // Prevent default behavior, which would trigger a submit
    evt.preventDefault();

    if (defaultCallback) {
        // Do not stop propagation. Default callbacks can handle that
        // themselves if desired, but propagation may be desired in some
        // of those cases (such as "CHANGE_IMAGE" propagating to the
        // dropzone behind the button).
        defaultCallback.callback(evt);
    } else {
        evt.stopPropagation(); // Do stop propagation for custom callbacks
        overrideCallback?.callback(evt);
    }
}

export default mediaToolbarCallbackHandler;
