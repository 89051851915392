import type { CSSProperties, ComponentProps } from "react";
import clsx from "clsx";
import css from "./Spot.module.css";

export const DEFAULT_SPOT_SIZE = 40;

export interface SpotProps extends Pick<ComponentProps<"div">, "onClick"> {
    active?: boolean;
    styles?: {
        container?: CSSProperties;
    };
    classNames?: {
        container?: string;
        icon?: string;
    };
    size?: number;
}

export function Spot(props: SpotProps) {
    const containerClassNames = clsx(
        css.Spot,
        { [css.active]: props.active },
        props.classNames?.container,
    );

    const iconClassNames = clsx(css.Icon);

    const containerStyles: CSSProperties = {
        ...props.styles?.container,
        "--_Spot-size": `${props.size || DEFAULT_SPOT_SIZE}px`,
    };

    return (
        <div
            className={containerClassNames}
            style={containerStyles}
            onClick={props.onClick}
            role="spot"
            aria-pressed={props.active || false}
            aria-label="spot"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="none"
                className={iconClassNames}
            >
                <path
                    fill="#F7F9FF"
                    d="M6.422 7.775.73 2.153 2.628.279 8.319 5.9 14.01.279l1.897 1.874-5.69 5.622 5.69 5.622-1.897 1.874L8.32 9.65l-5.691 5.622L.73 13.397l5.69-5.622Z"
                />
            </svg>
        </div>
    );
}

export default Spot;
