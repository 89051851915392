import type { ComponentProps, CSSProperties } from "react";
import { memo } from "react";
import clsx from "clsx";
import css from "./Figure.module.scss";

export interface FigureProps extends ComponentProps<"figure"> {}

const FigureRoot = ({ children, className, ...props }: FigureProps) => (
    <figure className={clsx(className, css.Figure)} {...props}>
        {children}
    </figure>
);

export interface ImgProps extends ComponentProps<"img"> {
    aspectRatio?: string;
    focusPoint?: { x: number; y: number };
    style?: CSSProperties;
}

const Img = ({ className, focusPoint, aspectRatio, ...props }: ImgProps) => (
    <img
        className={clsx(className, css.Image)}
        style={{
            ["--focus-point-x"]: (focusPoint?.x || 0.5) * 100 + "%",
            ["--focus-point-y"]: (focusPoint?.y || 0.5) * 100 + "%",
            ["--aspect-ratio"]: aspectRatio || "auto",
            ...props.style,
        }}
        {...props}
    />
);

export const Figure = Object.assign(FigureRoot, { Img: memo(Img) });

Figure.Img.displayName = "Figure.Img";

export default Figure;
