import type { ComponentProps } from "react";
import clsx from "clsx";
import css from "./Card.module.scss";

export interface CardProps extends ComponentProps<"div"> {}

function CardRoot({ className, ...props }: CardProps) {
    return (
        <div
            data-testid="card"
            className={clsx(className, css.Card)}
            {...props}
        />
    );
}

export interface CardHeaderProps extends ComponentProps<"div"> {}

function CardHeader({ className, ...props }: CardHeaderProps) {
    return (
        <div
            data-testid="card-header"
            className={clsx(className, css.Header)}
            {...props}
        />
    );
}

export interface CardMediaProps extends ComponentProps<"div"> {}

function CardMedia({ className, ...props }: CardMediaProps) {
    return (
        <div
            data-testid="card-media"
            className={clsx(className, css.Media)}
            {...props}
        />
    );
}

export interface CardMainProps extends ComponentProps<"div"> {}

function CardMain({ className, ...props }: CardMainProps) {
    return (
        <div
            data-testid="card-main"
            className={clsx(className, css.Main)}
            {...props}
        />
    );
}

export interface CardActionsProps extends ComponentProps<"div"> {}

function CardActions({ className, ...props }: CardActionsProps) {
    return (
        <div
            data-testid="card-actions"
            className={clsx(className, css.Actions)}
            {...props}
        />
    );
}

export const Card = Object.assign(CardRoot, {
    Header: CardHeader,
    Media: CardMedia,
    Main: CardMain,
    Actions: CardActions,
});
