import css from "./Spacer.module.css";

export interface SpacerProps {
    size: "s" | "m" | "l" | "xl" | "2xl" | "3xl" | "default";
}

export function Spacer(props: SpacerProps) {
    return (
        <div
            className={css.Spacer}
            data-testid="spacer"
            data-size={props.size || "default"}
        />
    );
}

export default Spacer;
