import {
    type AssetDto,
    type ExternalVideoAssetDto,
    type FileAssetDto,
    useGetAssetByIdLazyQuery,
} from "@bespeak/apollo";
import { useEffect, useMemo, useState } from "react";

/**
 * Hook to fetch the details for a given [asset].
 *
 * @param asset Asset to fetch details for. Should have an `id` property, as that
 *  ID will be used to fetch the details. You can also just pass in the ID directly.
 * @return the details for the given asset, or `undefined` if the asset is undefined.
 */
export function useDetailedAsset(asset?: AssetDto | AssetDto["id"]) {
    const id = useMemo(
        () =>
            asset && Object.prototype.hasOwnProperty.call(asset, "id")
                ? asset?.id
                : asset,
        [asset],
    );
    const [detailedAsset, setDetailedAsset] = useState<
        FileAssetDto | ExternalVideoAssetDto | undefined
    >(undefined);

    const [getAssetById, { loading }] = useGetAssetByIdLazyQuery();

    useEffect(() => {
        if (id && id !== detailedAsset?.id && !loading) {
            // Fetch asset
            getAssetById({ variables: { id: id } }).then((result) => {
                if (result.data?.getAssetById) {
                    setDetailedAsset(() => result.data?.getAssetById);
                }
            });
        } else if ((!id || id === "new") && detailedAsset) {
            setDetailedAsset(() => undefined);
        }
    }, [loading, id, detailedAsset, getAssetById]);

    return detailedAsset;
}
