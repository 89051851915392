import { useQuery } from "@apollo/client";
import { GetEducationalProgramsDocument } from "@bespeak/apollo";

export function useGetEducationalPrograms() {
    const { data, loading } = useQuery(GetEducationalProgramsDocument, {
        variables: { pagination: { page: 0, pageSize: 100 } },
        errorPolicy: "all",
    });

    return {
        loading,
        educationalPrograms: data?.getEducationalPrograms.rows || [],
    };
}
