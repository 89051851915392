import { cva } from "cva";
import { alignmentVariants } from "./common.variants";
import css from "./Table.module.scss";

export const tdVariants = cva({
    base: css.Td,
    variants: {
        ...alignmentVariants,
        muted: {
            true: css.muted,
        },
    },
});
