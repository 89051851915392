import css from "./HelpBubble.module.css";

export type HelpBubbleCircleProps = {
    onClick: () => void;
};

export const HelpBubbleCircle = ({ onClick }: HelpBubbleCircleProps) => {
    return (
        <button onClick={onClick} className={css.HelpBubbleCircle}>
            ?
        </button>
    );
};
