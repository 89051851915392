import { useTranslation } from "react-i18next";
import WysiwygEditor from "@/components/atoms/WysiwygEditor";

export interface FeedbackFieldsProps {
    onChange: (
        value: string,
        key: "helpText" | "correctFeedbackText" | "incorrectFeedbackText",
    ) => void;
    hintValue?: string;
    hasHintText?: boolean;
    correctFeedbackValue?: string;
    incorrectFeedbackValue?: string;
}

export function FeedbackFields(props: FeedbackFieldsProps) {
    const { hasHintText = true } = props;
    const { t } = useTranslation("common");
    const modules = {
        toolbar: [["bold", "italic", "underline", "strike"], [{ header: [6] }]],
    };

    const helpText = hasHintText ? (
        <div>
            <WysiwygEditor
                onChange={(value) => props.onChange(value, "helpText")}
                value={props.hintValue}
                modules={modules}
                label={t("help-text-label", "Hint-tekst")}
                placeholder={t("help-text-placeholder", "Hint-tekst")}
            />
        </div>
    ) : null;

    const correctFeedbackText = (
        <div>
            <WysiwygEditor
                value={props.correctFeedbackValue}
                onChange={(value) =>
                    props.onChange(value, "correctFeedbackText")
                }
                modules={modules}
                label={t(
                    "correct-feedback-text-label",
                    "Feedback bij correct antwoord",
                )}
                placeholder={t(
                    "correct-feedback-text-placeholder",
                    "Feedback bij correct antwoord",
                )}
            />
        </div>
    );

    const incorrectFeedbackText = (
        <div>
            <WysiwygEditor
                value={props.incorrectFeedbackValue}
                onChange={(value) =>
                    props.onChange(value, "incorrectFeedbackText")
                }
                modules={modules}
                label={t(
                    "incorrect-feedback-text-label",
                    "Feedback bij incorrect antwoord",
                )}
                placeholder={t(
                    "incorrect-feedback-text-placeholder",
                    "Feedback bij incorrect antwoord",
                )}
            />
        </div>
    );
    return (
        <>
            {helpText}
            {correctFeedbackText}
            {incorrectFeedbackText}
        </>
    );
}

export default FeedbackFields;
