export const MediaToolbarActions = {
    DELETE: "DELETE",
    CHANGE_IMAGE: "CHANGE_IMAGE",
};
export type MediaToolbarAction =
    (typeof MediaToolbarActions)[keyof typeof MediaToolbarActions];

export interface MediaToolbarActionCallback {
    action: MediaToolbarAction;
    callback: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
