import { AlgoliaSearchProvider } from "@/provider/AlgoliaSearch";
import { SearchLayout } from "./SearchLayout.component";
import MainMenu from "@/components/organisms/MainMenu";
import { useContext } from "react";
import { ThemeContext } from "@bespeak/ui/src/Theme";

export const Search = () => {
    const theme = useContext(ThemeContext);

    return (
        <AlgoliaSearchProvider applicationID={theme?.algoliaApplicationID}>
            <MainMenu />
            <SearchLayout />
        </AlgoliaSearchProvider>
    );
};

export default Search;
