import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import type { SelectChangeEvent } from "@mui/material/Select";
import Select from "@mui/material/Select";
import css from "./PersonSelector.module.scss";

export type PersonSelectorProps = {
    value?: string;
    onChange?: (value: string) => void;
    options: {
        value: string;
        name: string;
    }[];
    label: string;
};

export default function PersonSelector({
    options,
    value,
    onChange,
    label,
}: PersonSelectorProps) {
    const handleChange = (event: SelectChangeEvent<string>) => {
        if (onChange) onChange(event.target.value);
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel>{label}</InputLabel>
            <Select value={value || "0"} label={label} onChange={handleChange}>
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        <div className={css.SelectItem}>
                            <span>{option.name}</span>
                        </div>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
