import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAuthenticatedFetchLazy } from "@/lib/useFetch";
import { useDropzone } from "react-dropzone";
import type { AssetDto } from "../../../../../packages/apollo";
import Box from "@mui/material/Box";
import clsx from "clsx";
import css from "@/components/organisms/AssetUpload/ImageAssetUpload.module.css";
import { FigureWithId, isFileAsset } from "@bespeak/ui";
import Stack from "@mui/material/Stack";
import { CircularProgress } from "@mui/material";
import { UploadFile } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import MediaToolbar, {
    type MediaToolbarAction,
    MediaToolbarActions,
} from "@/components/atoms/MediaToolbar";
import type { ValidationError } from "yup";

export type ImageAssetUploadProps = {
    asset?: AssetDto;
    onChange: (asset: AssetDto | null) => void;
    assetActions?: MediaToolbarAction[];
    error?: ValidationError;
    label?: string;
};

export function ImageAssetUpload(props: ImageAssetUploadProps) {
    const { asset, onChange, assetActions, error, label } = props;
    const { t } = useTranslation("common", { keyPrefix: "media" });

    const [progress, setProgress] = useState(false);

    const { uploadAsset } = useAuthenticatedFetchLazy();

    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        maxFiles: 1,
        onDrop: onDrop,
        accept: {
            "image/*": [".png", ".jpg", ".jpeg"],
        },
    });

    async function onDrop(files: File[]) {
        if (!files[0]) return;

        setProgress(true);

        await uploadAsset(files[0])
            .then((data) => {
                onChange(data);
            })
            .finally(() => {
                setProgress(false);
            });
    }

    function deleteImage() {
        onChange(null);
    }

    return (
        <>
            <Box
                sx={{
                    flexBasis: "50%",
                    position: "relative",
                }}
                className={clsx(css.Image, { [css.Error]: error })}
                {...getRootProps()}
            >
                <input {...getInputProps()} />

                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "20% 50%",
                        transition: "opacity 0.2s ease-in-out",
                        backgroundColor: "rgb(0 0 0 / 0.1)",
                        position: "absolute",
                    }}
                >
                    {isFileAsset(asset) && asset?.id && asset.id !== "new" ? (
                        <FigureWithId id={asset.id.toString()} cover="cover" />
                    ) : (
                        <div></div>
                    )}
                </Box>

                {!asset?.id || asset.id === "new" ? (
                    <Box
                        sx={{
                            inset: 2,
                            position: "absolute",
                            border: "3px dashed rgb(255 255 255 / 1)",
                            transition: "opacity 0.2s ease-in-out",
                            opacity: asset?.id ? 0.0 : 1.0,
                            display: "grid",
                            placeContent: "center",
                        }}
                    >
                        <Stack
                            justifyContent="center"
                            alignItems="center"
                            gap={1}
                        >
                            <>
                                {progress ? (
                                    <CircularProgress />
                                ) : (
                                    <>
                                        <UploadFile />
                                        <Typography variant="body1">
                                            {label ||
                                                t(
                                                    "upload-image",
                                                    "Upload afbeelding",
                                                )}
                                        </Typography>
                                    </>
                                )}
                            </>
                        </Stack>
                    </Box>
                ) : null}

                {isFileAsset(asset) && asset?.id && asset.id !== "new" ? (
                    <Box
                        sx={{
                            inset: 2,
                            position: "absolute",
                            display: "grid",
                            placeContent: "center",
                        }}
                    >
                        <MediaToolbar
                            actions={assetActions}
                            callbacks={[
                                {
                                    action: MediaToolbarActions.DELETE,
                                    callback: () => deleteImage(),
                                },
                            ]}
                        />
                    </Box>
                ) : null}
            </Box>
        </>
    );
}
