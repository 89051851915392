import clsx from "clsx";
import css from "./SearchField.module.css";
import { SearchIcon, CloseIcon } from "@bespeak/ui";
import { useTranslation } from "react-i18next";
import { useInstantSearch } from "react-instantsearch";
import { useNavigate } from "react-router-dom";
import { AlgoliaSearchContext } from "@/provider/AlgoliaSearch";
import type { AlgoliaSearchContextType } from "@/provider/AlgoliaSearch/AlgoliaSearch.types";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SearchResults from "../SearchResults/SearchResults.component";
import {
    Index,
    SearchBox,
    Configure,
    type ConfigureProps,
} from "react-instantsearch";
import { ShortCodeHits } from "@/components/atoms/ShortcodeHits/ShortcodeHits.component";

export const SearchField = () => {
    const { t } = useTranslation("common", {
        keyPrefix: "search",
    });
    const [error, setError] = useState<string | null>(null);

    const {
        searchActive,
        handleDisableSearchActive,
        searchInstantiated,
        setGlobalSearchResults,
        globalSearchResults,
        shortcode,
        shortcodeUrl,
    } = useContext<AlgoliaSearchContextType>(AlgoliaSearchContext);
    const navigate = useNavigate();
    const { results } = useInstantSearch();
    const location = useLocation();
    const previousLocation = useRef(location);

    const { didacticToolId } = useParams<{
        didacticToolId: string;
    }>();

    const searchConfigShortcode: ConfigureProps = {
        advancedSyntax: true,
        disableTypoToleranceOnAttributes: ["shortcode"],
        queryType: "prefixNone",
        restrictSearchableAttributes: ["shortcode", "available_to"],
    };

    useEffect(() => {
        if (previousLocation.current !== location) {
            handleDisableSearchActive();
            previousLocation.current = location;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        if (!searchActive) {
            setError(null);
        }
    }, [searchActive]);

    const handleNavigateShortcode = () => {
        if (!shortcodeUrl || (results.query !== shortcode && !shortcodeUrl)) {
            setError(
                t(
                    "errors.noResults",
                    "Geen resultaten, gebruik een ander trefwoord of snelcode",
                ),
            );
        }

        if (shortcodeUrl && results.query === shortcode) {
            setError(null);
            navigate(shortcodeUrl);
            setTimeout(() => {
                handleDisableSearchActive();
            }, 200);
        }
    };

    const handleGlobalSearch = () => {
        if (
            results.query.length >= 2 &&
            results.nbHits === 0 &&
            !shortcodeUrl
        ) {
            setGlobalSearchResults([]);
            return null;
        } else if (results.query.length < 2) {
            setGlobalSearchResults([]);
            setError(null);

            return null;
        }

        if (
            !results ||
            results.index !== import.meta.env.VITE_ALGOLIA_SEARCH_GLOBAL_INDEX
        )
            return false;

        setGlobalSearchResults(results.hits);
        setError(null);
    };

    if (!searchActive || didacticToolId) {
        return null;
    }

    return (
        <div
            className={clsx(css.searchWrapper, {
                [css.searchWrapperActive]: searchActive,
            })}
        >
            <div className={css.searchFieldInputWrapper}>
                <div className={css.searchFieldInput}>
                    {searchInstantiated ? (
                        <SearchBox
                            autoFocus={true}
                            resetIconComponent={() => <CloseIcon />}
                            submitIconComponent={() => <SearchIcon />}
                            className={css.searchFieldInputField}
                            placeholder={t(
                                "search",
                                "Zoek op snelcode of trefwoord",
                            )}
                            onSubmit={handleNavigateShortcode}
                            onChangeCapture={handleGlobalSearch}
                            classNames={{
                                root: css.searchFieldInputRoot,
                                form: css.searchFieldInputForm,
                                reset: css.searchFieldInputReset,
                                submit: css.searchFieldInputSubmit,
                                input: css.searchFieldInputInput,
                                loadingIndicator:
                                    css.searchFieldInputLoadingIndicator,
                            }}
                        />
                    ) : null}
                </div>
            </div>
            {error ? (
                <div className={css.searchFieldResults}>
                    <div className={css.searchFieldError}>{error}</div>
                </div>
            ) : null}
            {results.query !== "" ? (
                <>
                    <div className={css.searchFieldResults}>
                        <SearchResults
                            searchString={results.query}
                            results={globalSearchResults}
                        />
                    </div>
                    <Index
                        indexName={
                            import.meta.env.VITE_ALGOLIA_SEARCH_SHORTCODE_INDEX
                        }
                    >
                        <Configure {...searchConfigShortcode} />
                        <ShortCodeHits />
                    </Index>
                </>
            ) : null}
        </div>
    );
};
