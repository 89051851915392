import { cva } from "cva";
import css from "./MediaCardRoot.module.css";

export const mediaCardRootVariants = cva({
    base: css.MediaCardRoot,
    variants: {
        size: {
            small: css.small,
            medium: css.medium,
        },
        onlyTitle: {
            true: css.onlyTitle,
        },
        hasImage: {
            true: css.hasImage,
        },
    },
    defaultVariants: {
        size: "medium",
    },
});
