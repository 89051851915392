import { useGetGroupsForAuthenticatedUser } from "@/services";
import { useSetCurrentGroupId, useGroupStore } from "@/store/preferences";
import { Dropdown } from "./Dropdown";

const GroupsDropdown = () => {
    const { groups } = useGetGroupsForAuthenticatedUser();
    const { value, setValue } = useGroupStore();

    const setCurrentGroupId = useSetCurrentGroupId();

    const handleSelectionChange = (groupId: string) => {
        setCurrentGroupId(groupId);
        setValue(groupId);
    };

    return (
        <Dropdown
            groups={groups}
            defaultSelectedKey={value}
            onChange={handleSelectionChange}
        />
    );
};

export default GroupsDropdown;
