import type { ValidationError } from "yup";

interface ValidationErrorResolverArgs {
    errors?: ValidationError[];
}

/**
 * Variant to provide a path and optionally a type to match against, which is
 * the default behavior of this function.
 */
interface PathAndTypeResolverArgs extends ValidationErrorResolverArgs {
    path: string;
    type?: string;
}

/**
 * Variant to provide a custom resolver
 */
interface ProvidedResolverArgs extends ValidationErrorResolverArgs {
    resolver: (error: ValidationError) => boolean;
}

/**
 * Resolve a validation error either by path and type or by a custom resolver.
 * @param args Arguments to provide the list of errors and the info to resolve
 * @return the resolved error, or `undefined` if no matching error was found
 */
export function resolveError(
    args: PathAndTypeResolverArgs | ProvidedResolverArgs,
): ValidationError | undefined {
    function isResolverProvided(
        args: PathAndTypeResolverArgs | ProvidedResolverArgs,
    ): args is ProvidedResolverArgs {
        return "resolver" in args;
    }

    const resolver = isResolverProvided(args)
        ? args.resolver
        : (error: ValidationError) =>
              error.path === args.path &&
              (!args.type || error.type === args.type);
    return args?.errors?.find(resolver) || undefined;
}

export default resolveError;
