import type { ReactNode, HTMLAttributes } from "react";
import clsx from "clsx";
import css from "./ReferenceCardMedia.module.css";

export interface ReferenceCardMediaProps
    extends Pick<HTMLAttributes<HTMLDivElement>, "className"> {
    children?: ReactNode;
}

export default function ReferenceCardMedia(props: ReferenceCardMediaProps) {
    return (
        <div className={clsx(css.ReferenceCardMedia, props.className)}>
            {props.children ? props.children : null}
        </div>
    );
}
