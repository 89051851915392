export namespace flattenGroups {
    export interface Group {
        id: string;
        name: string;
        subgroups?: Group[];
        indent?: number;
    }
}

export function flattenGroups({
    id,
    name,
    subgroups = [],
    indent = 0,
}: flattenGroups.Group): flattenGroups.Group[] {
    return [
        { id, name, indent },
        ...subgroups.flatMap((subgroup) =>
            flattenGroups({ ...subgroup, indent: indent + 1 }),
        ),
    ];
}
