import type { ComponentProps, CSSProperties, ReactNode } from "react";
import { useMediaMatch } from "rooks";
import clsx from "clsx";
import css from "./Grid.module.css";

//
// Grid Base component
// ---------------------------------------------------------------------------------------------------------------------

interface GridBaseProps extends ComponentProps<"div"> {
    templateColumns?: string;
    templateRows?: string;
    rowGap?: string;
    colGap?: string;
    verticalPadding?: string | number;
    horizontalPadding?: string | number;
}

function GridBase(props: GridBaseProps) {
    const {
        templateColumns,
        templateRows,
        rowGap,
        colGap,
        className,
        verticalPadding,
        horizontalPadding,
        style,
        ...rest
    } = props;

    const gridStyles: CSSProperties = {
        ...(typeof templateColumns !== "undefined" && {
            ["--template-columns"]: templateColumns,
        }),
        ...(typeof templateRows !== "undefined" && {
            ["--template-rows"]: templateRows,
        }),
        ...(typeof rowGap !== "undefined" && {
            ["--row-gap"]: rowGap,
        }),
        ...(typeof colGap !== "undefined" && {
            ["--col-gap"]: colGap,
        }),
        ...(typeof verticalPadding !== "undefined" && {
            ["--vertical-padding"]: `var(--spacing-${verticalPadding})`,
        }),
        ...(typeof horizontalPadding !== "undefined" && {
            ["--horizontal-padding"]: `var(--spacing-${horizontalPadding})`,
        }),
        ...style,
    };

    const classNames = clsx(className, css.Grid);

    const gridBaseProps = {
        ...rest,
        className: classNames,
        style: gridStyles,
    };

    return <div data-testid="grid-base" {...gridBaseProps} />;
}

//
// Row component
// ---------------------------------------------------------------------------------------------------------------------

interface RowProps extends ComponentProps<"div"> {}

function Row(props: RowProps) {
    const { className, ...rest } = props;

    const classNames = clsx(className, css.Row);

    const rowProps: RowProps = {
        className: classNames,
        ...rest,
    };

    return <div data-testid="grid-row" {...rowProps} />;
}

//
// Col component
// ---------------------------------------------------------------------------------------------------------------------

type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl";

interface ColProps extends Omit<ComponentProps<"div">, "children"> {
    children: ReactNode | ((column: string) => ReactNode);
    column?: string | Record<Breakpoint | string, string>;
    order?: string | number | Record<Breakpoint | string, string | number>;
    verticalPadding?: string | number;
    horizontalPadding?: string | number;
}

function Col(props: ColProps) {
    const {
        className,
        style,
        column,
        children,
        verticalPadding,
        horizontalPadding,
        order,
        ...rest
    } = props;

    const classNames = clsx(className, css.Col);

    const isXs = true;
    const isSm = useMediaMatch("(min-width: 640px)");
    const isMd = useMediaMatch("(min-width: 768px)");
    const isLg = useMediaMatch("(min-width: 1024px)");
    const isXl = useMediaMatch("(min-width: 1280px)");

    const gridColumn =
        column != null && typeof column === "string"
            ? column
            : typeof column === "object"
              ? Object.entries(column)
                    .map(([key, value]): string => {
                        switch (key) {
                            case "xs":
                                return isXs ? value : "";
                            case "sm":
                                return isSm ? value : "";
                            case "md":
                                return isMd ? value : "";
                            case "lg":
                                return isLg ? value : "";
                            case "xl":
                                return isXl ? value : "";
                            default:
                                return "";
                        }
                    })
                    .filter((value) => Boolean(value))
                    .at(-1) || ""
              : "";

    const gridColumnOrder =
        order != null && typeof order === "string"
            ? order
            : typeof order === "object"
              ? Object.entries(order)
                    .map(([key, value]): string | number => {
                        switch (key) {
                            case "xs":
                                return isXs ? value : "";
                            case "sm":
                                return isSm ? value : "";
                            case "md":
                                return isMd ? value : "";
                            case "lg":
                                return isLg ? value : "";
                            case "xl":
                                return isXl ? value : "";
                            default:
                                return "";
                        }
                    })
                    .filter((value) => value !== "")
                    .at(-1) || ""
              : "";

    const colStyles: CSSProperties = {
        ["--column"]: gridColumn,
        ["--order"]: gridColumnOrder,
        ...(verticalPadding && {
            ["--col-vertical-padding"]: `var(--spacing-${verticalPadding})`,
        }),
        ...(horizontalPadding && {
            ["--col-horizontal-padding"]: `var(--spacing-${horizontalPadding})`,
        }),
        ...style,
    };

    const colProps: Omit<ColProps, "children"> = {
        className: classNames,
        style: colStyles,
        ...rest,
    };

    return (
        <div data-testid="grid-column" {...colProps}>
            {typeof children === "function" ? children(gridColumn) : children}
        </div>
    );
}

//
// Grid component
// ---------------------------------------------------------------------------------------------------------------------

export const Grid = Object.assign(GridBase, { Row, Col });

export default Grid;
