import type { ComponentProps } from "react";
import css from "./CarouselControls.module.css";

export interface CarouselControlsProps
    extends Pick<ComponentProps<"div">, "children"> {}

export default function CarouselControls(props: CarouselControlsProps) {
    const { children } = props;

    return <div className={css.Controls}>{children}</div>;
}
