import type { ReactNode, HTMLAttributes } from "react";
import clsx from "clsx";
import css from "./ReferenceCardScroll.module.css";

export interface ReferenceCardScrollProps
    extends Pick<HTMLAttributes<HTMLDivElement>, "className" | "style"> {
    children?: ReactNode;
}

export default function ReferenceCardScroll(props: ReferenceCardScrollProps) {
    return (
        <div
            data-testid="reference-card-scroll"
            className={clsx(css.Scroll, props.className)}
        >
            {props.children}
        </div>
    );
}
