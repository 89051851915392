import { type AuthProviderProps } from "react-oidc-context";
import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import { resolveOIDCSettings } from "@/whitelabels";

const OIDCSettings = resolveOIDCSettings();

const authority =
    OIDCSettings?.url && OIDCSettings?.realm
        ? `${OIDCSettings.url}/realms/${OIDCSettings.realm}`
        : undefined;

export const userManager = new UserManager({
    authority: authority ?? "",
    client_id: OIDCSettings?.clientId ?? "",
    redirect_uri: `${location.origin}${location.pathname ?? "/"}`,
    post_logout_redirect_uri: `${location.origin}${location.pathname ?? "/"}`,

    // Automatic sign-in (per session)
    // -------------------------------------------------------------------------------------------------------------
    // In theory this should be possible with localStorage, but it
    // causes an issue with automatically signing in with an expired session.
    //
    // @link https://tribeagency.atlassian.net/browse/BW-1907
    //
    // Note that sessionStorage is actually already the default, but this is
    // explicitly set here for clarity, and in case the default ever changes.
    //
    userStore: new WebStorageStateStore({
        store: window.sessionStorage,
    }),

    automaticSilentRenew: true,
});

export const authProviderProps = {
    userManager,
    onSigninCallback: () => {
        window.history.replaceState(
            {},
            document.title,
            window.location.pathname,
        );
    },
} satisfies AuthProviderProps;

export default authProviderProps;
