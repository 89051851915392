import CircleOutlined from "@mui/icons-material/CircleOutlined";
import Circle from "@mui/icons-material/Circle";
import Box from "@mui/material/Box";

export interface FauxRadioProps {
    checked?: boolean;
    onChange?: (checked: boolean) => void;
}

export default function FauxRadio(props: FauxRadioProps) {
    return (
        <Box
            sx={{ position: "relative", cursor: "pointer" }}
            tabIndex={-1}
            onClick={(e) => {
                e.preventDefault();
                props.onChange?.(!props.checked);
            }}
        >
            {props.checked ? (
                <Circle
                    color="success"
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                    }}
                />
            ) : null}
            <CircleOutlined sx={{ position: "relative" }} />
        </Box>
    );
}
