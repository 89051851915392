import { getAccessToken } from "@/lib/Auth";

namespace fetchWithCredentials {
    export type Url = RequestInfo | URL;
    export type Init = RequestInit;
}

export async function fetchWithCredentials(
    url: fetchWithCredentials.Url,
    init: fetchWithCredentials.Init = {},
): Promise<Response> {
    const options: RequestInit = { ...init },
        accessToken = await getAccessToken();

    if (accessToken != null)
        options.headers = {
            ...options.headers,
            Authorization: `Bearer ${accessToken}`,
        };

    return fetch(url, options);
}
