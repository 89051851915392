import css from "./Breadcrumbs.module.css";
import { Link } from "react-router-dom";
import type {
    AppModuleDto,
    DidacticToolDto,
    EducationalPathDto,
    EducationalProgramDto,
} from "@bespeak/apollo";
import { useEffect, useMemo, useState } from "react";

export interface BreadCrumbProps {
    program: EducationalProgramDto;
    module?: AppModuleDto;
    learningPath?: EducationalPathDto;
    didacticTool?: DidacticToolDto;
}

export const Breadcrumbs = ({
    program,
    learningPath,
    module,
    didacticTool,
}: BreadCrumbProps) => {
    const [breadcrumbsLinks, setBreadcrumbsLinks] = useState<string[]>([]);

    const contentTypes = useMemo(
        () => [
            {
                ...learningPath,
                type: "learning-path",
                name: learningPath?.name,
            },
            {
                ...module,
                type: "module",
                name: module?.title, //remap because module uses .title, all other content types use .name
            },
            {
                ...didacticTool,
                type: "didactic-tool",
                name: didacticTool?.name,
            },
        ],
        [learningPath, module, didacticTool],
    );

    useEffect(() => {
        const updatedBreadcrumbsLinks: string[] = [];

        let learningPathURL = "";
        let moduleURL = "";

        contentTypes.forEach((content) => {
            if (content.type === "learning-path") {
                learningPathURL = `/learning-path/${content.id}`;
                updatedBreadcrumbsLinks.push(learningPathURL);
            }
            if (content.type === "module") {
                moduleURL = `${learningPathURL}/module/${content.id}`;
                updatedBreadcrumbsLinks.push(moduleURL);
            }
            if (content.type === "didactic-tool") {
                updatedBreadcrumbsLinks.push(
                    `${moduleURL}/didactic-tool/${content.id}` || "",
                );
            }
        });

        setBreadcrumbsLinks(updatedBreadcrumbsLinks);
    }, [contentTypes]);

    return (
        <div className={css.Breadcrumbs}>
            {contentTypes
                .filter((content) => content.name !== undefined)
                .map((content, index) => (
                    <div key={index} className={css.Breadcrumb}>
                        <Link
                            to={`/program/${program.id}${breadcrumbsLinks[index]}`}
                        >
                            <span className={css.BreadcrumbTitle}>
                                {content?.name || ""}
                            </span>
                        </Link>
                        <span className={css.BreadcrumbSeparator}>,</span>
                    </div>
                ))}
        </div>
    );
};

export default Breadcrumbs;
