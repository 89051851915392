import type { ComponentProps, ReactNode } from "react";
import clsx from "clsx";
import css from "./Container.module.css";
import { useMediaQuery } from "usehooks-ts";
export interface ContainerProps {
    children: ReactNode;
    background?: "DEFAULT" | "PRIMARY" | "SECONDARY";
    element?: {
        container?: ContainerElementProps;
        wrapper?: WrapperElementProps;
    };
    paddingVertical?: number | string;
    paddingHorizontal?: number | string;
}

export interface ContainerElementProps
    extends Omit<ComponentProps<"div">, "children"> {
    ["data-testid"]?: string;
}

export interface WrapperElementProps
    extends Omit<ComponentProps<"div">, "children"> {}

export function Container(props: ContainerProps) {
    const mediaQueryPadding = useMediaQuery("(max-width: 768px)");
    const horizontalPadding =
        mediaQueryPadding || props.paddingHorizontal === "default"
            ? "default-mobile"
            : "default-desktop";

    const containerProps: ContainerElementProps = {
            ...props.element?.container,
            className: clsx(props.element?.container?.className, css.Container),
        },
        wrapperProps: WrapperElementProps = {
            ...props.element?.wrapper,
            className: clsx(props.element?.wrapper?.className, css.Wrap, {
                [css.backgroundDefault]: props.background === "DEFAULT",
                [css.backgroundPrimary]: props.background === "PRIMARY",
                [css.backgroundSecondary]: props.background === "SECONDARY",
            }),
            style: {
                ...props.element?.wrapper?.style,
                ["--container-padding-vertical"]: `var(--spacing-${props.paddingVertical ?? "none"})`,
                ["--container-padding-horizontal"]: `var(--spacing-${props.paddingHorizontal ?? horizontalPadding})`,
            },
        };

    return (
        <div {...containerProps}>
            <div {...wrapperProps}>{props.children}</div>
        </div>
    );
}

export default Container;
