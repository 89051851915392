import { useState, useEffect } from "react";

function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState("top");
    const [lastScrollY, setLastScrollY] = useState(window.scrollY);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            const newScrollDirection =
                currentScrollY > lastScrollY ? "down" : "up";

            if (currentScrollY === 0) {
                setScrollDirection("top");
                return;
            }

            if (newScrollDirection !== scrollDirection) {
                setScrollDirection(newScrollDirection);
            }

            setLastScrollY(currentScrollY);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollY, scrollDirection]);

    return scrollDirection;
}

export default useScrollDirection;
