import { useQuery } from "@apollo/client";
import { GetModuleForAppDocument } from "@bespeak/apollo";

export function useGetModuleForApp(moduleId: any) {
    const { data, ...results } = useQuery(GetModuleForAppDocument, {
        variables: { moduleId: moduleId },
        skip: !moduleId,
    });

    return {
        module: data?.getModuleForApp,
        ...results,
    };
}

export default useGetModuleForApp;
