import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import type { SortQuestionAnswers } from "@/components/organisms/BuildingBlockMapper";
import Input from "@/components/atoms/Input";
import { useTranslation } from "react-i18next";
import type { ValidationError } from "yup";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import { IconButton } from "@mui/material";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
export interface SortQuestionTextAnswerProps {
    onInputChange: (value: string) => void;
    onAddItem: () => void;
    onRemoveItem: () => void;
    answer: SortQuestionAnswers;
    showAddButton: boolean;
    showRemoveButton: boolean;
    errors?: ValidationError[];
}

export function SortQuestionTextAnswer({
    answer,
    onInputChange,
    errors,
    onAddItem,
    onRemoveItem,
    showAddButton,
    showRemoveButton,
}: SortQuestionTextAnswerProps) {
    const { t } = useTranslation("common", { keyPrefix: "mpc-answers" });

    const labelError = resolveError({
        errors,
        resolver: (error) => error?.path?.endsWith("label") ?? false,
    });

    return (
        <>
            {labelError && (
                <ValidationErrorLabel
                    keyPrefix={"mpc-answers.label"}
                    error={labelError}
                />
            )}
            <Stack
                key={answer.id}
                direction="row"
                alignItems={"center"}
                gap={1}
                component={Paper}
                sx={{ p: 2 }}
            >
                <Input
                    value={answer.label}
                    onChange={onInputChange}
                    placeholder={t("answer")}
                />
                {showRemoveButton && (
                    <IconButton onClick={onRemoveItem} title={t("delete-item")}>
                        <Delete />
                    </IconButton>
                )}
                {showAddButton && (
                    <IconButton onClick={onAddItem} title={t("add-item")}>
                        <Add />
                    </IconButton>
                )}
            </Stack>
        </>
    );
}

export default SortQuestionTextAnswer;
