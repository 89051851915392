import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material";
import Block from "@/components/atoms/Block";
import { useTranslation } from "react-i18next";
import css from "./QuoteBuildingBlock.module.scss";
import { useState } from "react";
import clsx from "clsx";
import SelectBgColor from "./common/SelectBgColor/SelectBgColor.component";
import Input from "../../../atoms/Input";
import Media from "@/components/atoms/Media";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import LayoutSelector from "@/components/molecules/LayoutSelector";
import { QuoteBlockLayout } from "@bespeak/apollo";
import {
    BuildingBlockType,
    type QuoteBuildingBlock,
} from "@/components/organisms/BuildingBlockMapper";

export interface QuoteBuildingBlockComponentProps extends QuoteBuildingBlock {
    onChange?: (buildingBlock: QuoteBuildingBlock) => void;
}

export function QuoteBuildingBlockComponent(
    props: QuoteBuildingBlockComponentProps,
) {
    const { t } = useTranslation("common");
    const [bgColor, setBgColor] = useState(props.background || "DEFAULT");
    type Fields = Pick<
        QuoteBuildingBlock,
        "quote" | "background" | "name" | "assetId"
    >;

    const handleChange =
        (key: keyof Fields) => (value: Fields[keyof Fields]) => {
            props.onChange?.({
                ...props,
                [key]: value,
            });
        };

    const registerControl = (key: keyof Fields) => ({
        value: props[key],
        onChange: handleChange(key),
    });

    const quoteError = resolveError({
        errors: props.errors,
        path: "quote",
    });

    const nameError = resolveError({
        errors: props.errors,
        path: "name",
    });

    const quote = (
        <Input
            {...registerControl("quote")}
            label={t("quote-label", "Quote")}
            placeholder={t("quote-placeholder", "Quote")}
            variant="h5"
            error={quoteError}
        />
    );

    const name = (
        <Input
            {...registerControl("name")}
            label={t("name-label", "Naam")}
            placeholder={t("name-placeholder", "Naam")}
            error={nameError}
        />
    );

    const imageBlock = (
        <div className={clsx("QuoteImage", css.QuoteImage)}>
            <Media
                image={{ id: props.assetId }}
                onChange={(image) => {
                    props.onChange?.({
                        ...props,
                        ...(image != null && {
                            assetId: image,
                        }),
                        ...(image === null && {
                            assetId: undefined,
                        }),
                    });
                }}
                error={resolveError({
                    errors: props.errors,
                    path: "assetId",
                })}
            />
        </div>
    );

    const blockLayout = props.quoteLayout || QuoteBlockLayout.ImageLeft;

    return (
        <Block>
            <Block.Header>
                <Stack gap={1} direction="row" alignItems={"center"}>
                    <LayoutSelector
                        type={BuildingBlockType.QUOTE}
                        value={props.quoteLayout || QuoteBlockLayout.ImageLeft}
                        layoutOptions={[
                            { type: QuoteBlockLayout.ImageLeft },
                            { type: QuoteBlockLayout.ImageRight },
                        ]}
                        onChange={(e) => {
                            props.onChange?.({
                                ...props,
                                quoteLayout: e as QuoteBlockLayout,
                            });
                        }}
                    />
                    <SelectBgColor
                        {...registerControl("background")}
                        onChange={(event) => {
                            setBgColor(event);
                            props.onChange?.({
                                ...props,
                                background: event,
                            });
                        }}
                    />
                </Stack>
            </Block.Header>
            <Block.Main
                className={clsx({ [css.primary]: bgColor === "PRIMARY" })}
            >
                <Stack gap={4} direction="row">
                    <Stack gap={4} flexBasis="100%">
                        <Grid
                            direction={
                                (blockLayout === QuoteBlockLayout.ImageLeft &&
                                    "row") ||
                                "row-reverse"
                            }
                            container
                            spacing={2}
                        >
                            <Grid item xs={5} xl={4}>
                                {imageBlock}
                            </Grid>
                            <Grid item xs={7} xl={8}>
                                {quote}
                                {name}
                            </Grid>
                        </Grid>
                    </Stack>
                </Stack>
            </Block.Main>
        </Block>
    );
}

export default QuoteBuildingBlockComponent;
