import type { ComponentProps } from "react";
import { memo } from "react";
import West from "@mui/icons-material/West";
import East from "@mui/icons-material/East";

export interface CarouselNavigationButtonIconProps
    extends ComponentProps<typeof West | typeof East> {
    direction: "left" | "right";
}

function CarouselNavigationButtonIcon(
    props: CarouselNavigationButtonIconProps,
) {
    const { direction, ...rest } = props;

    switch (direction) {
        case "left":
            return <West {...rest} />;
        case "right":
            return <East {...rest} />;
        default:
            throw new Error(`Invalid direction: ${direction}`);
    }
}

export default memo(CarouselNavigationButtonIcon);
