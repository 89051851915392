import type { ReactNode } from "react";
import DialogStackContext from "./DialogStack.context";
import DialogStackProvider from "./DialogStack.provider";
import DialogStackConsumer from "./DialogStack.consumer";
import DialogStackBackdrop from "./DialogStackBackdrop.component";

export namespace DialogStack {
    export interface DialogDefinition {
        component: ReactNode;
    }

    export namespace Context {
        export interface Value {
            stack: DialogDefinition[];
            push: (dialog: DialogDefinition) => void;
            pop: () => void;
            backdrop?: boolean;
        }
    }

    export namespace Provider {
        export interface Props {
            children: ReactNode;
            backdrop?: boolean;
        }
    }

    export namespace Consumer {
        export interface Props {
            children: (value: NonNullable<Context.Value>) => ReactNode;
        }
    }

    export namespace Backdrop {
        export interface Props {
            active?: boolean;
        }
    }
}

export const DialogStack = Object.assign(() => null, {
    Context: DialogStackContext,
    Backdrop: DialogStackBackdrop,
    Provider: DialogStackProvider,
    Consumer: DialogStackConsumer,
});

export default DialogStack;
