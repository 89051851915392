import { useTranslation } from "react-i18next";
import css from "./NetworkErrorNotice.module.css";

export default function NetworkErrorNotice() {
    const { t } = useTranslation();

    return (
        <div className={css.fadeIn}>{t("error.network", "Netwerkfout")}</div>
    );
}
