import { SearchField } from "@/components/molecules/SearchField/SearchField.component";
import { AlgoliaSearchContext } from "@/provider/AlgoliaSearch";
import type { AlgoliaSearchContextType } from "@/provider/AlgoliaSearch/AlgoliaSearch.types";
import { useContext } from "react";

export const SearchLayout = () => {
    const { tenantHasSearchKey, searchInstantiated } =
        useContext<AlgoliaSearchContextType>(AlgoliaSearchContext);
    return (
        <>{tenantHasSearchKey && searchInstantiated ? <SearchField /> : null}</>
    );
};

export default SearchLayout;
