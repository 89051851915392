import css from "./WysiwygEditor.module.scss";
import { Typography } from "@mui/material";
import type { ValidationError } from "yup";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import Editor, { isQuillEmpty } from "@/components/atoms/WysiwygEditor/Editor";

interface WysiwygEditorProps {
    value?: string | undefined;
    modules?: {
        toolbar: (
            | string[]
            | {
                  list: string;
              }[]
            | {
                  header: (number | boolean)[];
              }[]
        )[];
    };
    onChange?: (jsonReply: string) => void;
    placeholder?: string;
    label?: string;
    error?: ValidationError;
}

const WysiwygEditor = (props: WysiwygEditorProps) => {
    return (
        <div className={css.Quill}>
            {props.label && (
                <Typography>
                    <strong>{props.label}</strong>:
                </Typography>
            )}
            <ValidationErrorLabel
                error={props.error}
                keyPrefix={"wysiwyg-editor"}
            />
            <Editor
                defaultValue={props.value ? JSON.parse(props.value) : null}
                readOnly={false}
                onTextChange={(current, data, source, editor) => {
                    props.onChange?.(
                        isQuillEmpty(editor)
                            ? ""
                            : JSON.stringify(editor.getContents()),
                    );
                }}
            />
        </div>
    );
};

export default WysiwygEditor;
