export type QueryType = {
    educational_program?: string;
    educational_path?: string;
    module?: string;
    didactic_tool?: string;
};

export function NavigateToSearchResult(query: QueryType) {
    if (!query) {
        return null;
    }

    let url = "";
    const educationalProgram = query.educational_program;
    const educationalPath = query?.educational_path;
    const module = query?.module;
    const didacticTool = query?.didactic_tool;

    if (educationalProgram) {
        url += `/program/${educationalProgram}`;
    }

    if (educationalPath) {
        url += `/learning-path/${educationalPath}`;
    }

    if (module) {
        url += `/module/${module}`;
    }

    if (didacticTool) {
        url += `/didactic-tool/${didacticTool}`;
    }

    if (url === "") {
        return null;
    }

    return url;
}
