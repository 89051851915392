import Stack from "@mui/material/Stack";
import Block from "../../../atoms/Block";

import {
    type BuildingBlocksLabel,
    type InteractiveQuestionBuildingBlock,
} from "@/components/organisms/BuildingBlockMapper";
import SceneBuildingBlockComponent from "@/components/organisms/BuildingBlockMapper/blocks/SceneBuildingBlock.component";
import InteractiveQuestionEditor from "../../InteractiveQuestionEditor";
import type { ValidationError } from "yup";

export interface InteractiveQuestionBuildingBlockComponentProps
    extends InteractiveQuestionBuildingBlock {
    availableLabels?: BuildingBlocksLabel[];
    onChange?: (buildingBlock: InteractiveQuestionBuildingBlock) => void;
    errors?: ValidationError[];
}

export function InteractiveQuestionBuildingBlockComponent(
    props: InteractiveQuestionBuildingBlockComponentProps,
) {
    const questionErrors = props.errors?.filter((error) =>
        error?.path?.startsWith(`interactiveQuestion.${props.id}.question`),
    );

    const sceneErrors = props.errors?.filter((error) =>
        error?.path?.startsWith(
            `interactiveQuestion.${props.id}.sceneWhenWrong`,
        ),
    );

    function removePrefix<T extends ValidationError | undefined>(
        error: T,
        prefix: string,
    ): T {
        if (!error) return error;
        return {
            ...error,
            path: error.path?.replace(prefix, ""),
        };
    }

    return (
        <Block>
            <Block.Main>
                <Stack gap={4} direction="column">
                    <Stack gap={4}>
                        <InteractiveQuestionEditor
                            question={props.question}
                            onChange={(questionBlock) => {
                                props.onChange?.({
                                    ...props,
                                    question: questionBlock as any,
                                });
                            }}
                            errors={
                                questionErrors
                                    ? questionErrors.map((p) =>
                                          removePrefix(
                                              p,
                                              `interactiveQuestion.${props.id}.question.`,
                                          ),
                                      )
                                    : undefined
                            }
                        />
                    </Stack>
                    <SceneBuildingBlockComponent
                        {...props.sceneWhenWrong}
                        errors={
                            sceneErrors
                                ? sceneErrors.map((p) =>
                                      removePrefix(
                                          p,
                                          `interactiveQuestion.${props.id}.sceneWhenWrong.`,
                                      ),
                                  )
                                : undefined
                        }
                        onChange={(sceneWhenWrong) => {
                            props.onChange?.({
                                ...props,
                                sceneWhenWrong,
                            });
                        }}
                    />
                </Stack>
            </Block.Main>
        </Block>
    );
}

export default InteractiveQuestionBuildingBlockComponent;
