import type { ChangeEvent } from "react";
import type { ValidationError } from "yup";
import clsx from "clsx";
import Typography, { type TypographyProps } from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import noop from "@/lib/noop";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import css from "./Input.module.scss";

export interface InputProps extends Pick<TypographyProps, "variant"> {
    value?: string;
    onChange?: (value: string) => void;
    placeholder?: string;
    textAlign?: "left" | "center" | "right";
    className?: string;
    label?: string;
    error?: ValidationError;
}

export function Input(props: InputProps) {
    const { value = "", onChange = noop, variant, placeholder } = props;

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        onChange(event.target.value);
    };

    return (
        <div className={clsx(css.Input, props.className)}>
            {props.label && (
                <Typography>
                    <strong>{props.label}:</strong>
                </Typography>
            )}
            {props.error && (
                <ValidationErrorLabel
                    keyPrefix={"input"}
                    error={props.error}
                    className={css.Error}
                />
            )}
            <Typography
                variant={variant}
                component={TextareaAutosize}
                width="100%"
                value={value}
                onChange={handleChange}
                className={css.Control}
                placeholder={placeholder}
                textAlign={props.textAlign}
            />
            {value ? (
                <div className={css.Placeholder}>{placeholder}</div>
            ) : null}
        </div>
    );
}

export default Input;
