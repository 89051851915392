import React, { useEffect, useLayoutEffect, useRef } from "react";
import "quill/dist/quill.snow.css";
import isEqual from "lodash/isEqual";

import Quill from "quill";

interface EditorProps {
    readOnly?: boolean;
    modules?: Record<string, unknown>;
    defaultValue?: any;
    onTextChange?: (...args: any[]) => void;
    onSelectionChange?: (...args: any[]) => void;
    className?: string;
}

// The editor is inspired from the example of https://quilljs.com/playground/react
const Editor = ({
    readOnly,
    modules = {},
    className,
    onTextChange,
    onSelectionChange,
    defaultValue,
}: EditorProps) => {
    const modulesRef = useRef(modules);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);
    const quillRef = useRef<Quill | null>(null);

    useLayoutEffect(() => {
        onTextChangeRef.current = onTextChange;
        onSelectionChangeRef.current = onSelectionChange;
    });

    useEffect(() => {
        quillRef.current?.enable(!readOnly);
    }, [readOnly]);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            // Check if container is defined
            const editorContainer = container.appendChild(
                container.ownerDocument.createElement("div"),
            );
            const quill = new Quill(editorContainer, {
                theme: "snow",
                modules: modulesRef.current,
            });

            quillRef.current = quill;

            quill.on(Quill.events.TEXT_CHANGE, (...args) => {
                /// Parse quill to get the current content on the editor
                onTextChangeRef.current?.(...args, quill);
            });

            quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
                onSelectionChangeRef.current?.(...args);
            });

            return () => {
                quillRef.current = null;
                container.innerHTML = ""; // container is defined here
            };
        }
    }, []);

    useEffect(() => {
        if (
            quillRef.current &&
            !isEqualValue(quillRef.current.getContents(), defaultValue)
        ) {
            quillRef.current.setContents(defaultValue);
        }
    }, [defaultValue]);

    return <div className={className} ref={containerRef}></div>;
};

function isEqualValue(value: any, nextValue: any): boolean {
    if (isDelta(value) && isDelta(nextValue)) {
        return isEqual(value.ops, nextValue.ops);
    } else {
        return isEqual(value, nextValue);
    }
}

/**
 * True if the value is a Delta instance or a Delta look-alike.
 *
 * @param value
 */
function isDelta(value: any): boolean {
    return value && value.ops;
}

export function isQuillEmpty(quill: Quill) {
    // @ts-ignore
    if ((quill.getContents()["ops"] || []).length !== 1) {
        return false;
    }
    return quill.getText().trim().length === 0;
}

export default Editor;
