import { type ReactNode } from "react";
import type { VariantProps } from "cva";
import { mediaCardRootVariants } from "./MediaCardRoot.variants";

export interface MediaCardRootProps
    extends VariantProps<typeof mediaCardRootVariants> {
    children: ReactNode;
}

function MediaCardRoot(props: MediaCardRootProps) {
    return (
        <div
            data-testid="media-card-root"
            className={mediaCardRootVariants({
                size: props.size,
                onlyTitle: props.onlyTitle,
                hasImage: props.hasImage,
            })}
        >
            {props.children}
        </div>
    );
}

export default MediaCardRoot;
