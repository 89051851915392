import { TextField as MuiTextfield } from "@mui/material";
import { useFormContext, useFormState } from "react-hook-form";

export namespace Textfield {
    export interface Props {
        name: string;
        label: string;
        error?: boolean;
        helperText?: string;
    }
}

export function Textfield(props: Textfield.Props) {
    const { register, control } = useFormContext();

    const { errors, isSubmitted } = useFormState({ control }),
        error = errors[props.name],
        errorMessage =
            typeof error?.message === "string" ? error.message : undefined,
        hasError = Boolean(errorMessage);

    return (
        <MuiTextfield
            label={props.label}
            error={(isSubmitted && hasError) || props.error}
            helperText={(isSubmitted && errorMessage) || props.helperText}
            {...register(props.name)}
            fullWidth
        />
    );
}
