import { RetryLink } from "@apollo/client/link/retry";

export function useRetryLink() {
    return new RetryLink({
        delay: {
            initial: 1000,
            max: 10000,
            jitter: true,
        },
        attempts: {
            max: 5,
        },
    });
}

export default useRetryLink;
