import type { ValidationError } from "yup";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import css from "./ValidationErrorLabel.module.scss";

export interface ValidationErrorLabelProps {
    /**
     * The error, or undefined if there is no error, in which case the label
     * will hide itself.
     */
    error?: ValidationError;

    /**
     * Prefix under which the translation keys for this instance of the
     * component are stored. This allows for custom translations on a per-
     * use-case basis.
     */
    keyPrefix: string;

    /**
     * You can provide additional mappings of error types to translation keys
     * through this callback. This can be used to either have more specific
     * translations for certain error types, or to override the default
     * translations for a more common error type.
     *
     * @param type tyoe to be validated
     * @return the translation key, or undefined if the default should be used.
     */
    additionalTypeToKeyMapping?: (type?: string) => string | undefined;

    className?: string;
}

export const ValidationErrorTypeToKeyMapping = (type?: string) => {
    switch (type) {
        case "required":
        case "nullable":
        case "optionality":
            return "required";
        case "min":
            return "min";
        default:
            return "invalid";
    }
};

export const ValidationErrorLabel = (props: ValidationErrorLabelProps) => {
    const { t } = useTranslation("common", { keyPrefix: props.keyPrefix });

    const getErrorMessage = () => {
        if (props.additionalTypeToKeyMapping) {
            const key =
                props.additionalTypeToKeyMapping(props.error?.type) ??
                ValidationErrorTypeToKeyMapping(props.error?.type);
            return t(key);
        }

        const key = ValidationErrorTypeToKeyMapping(props.error?.type);
        return t(key);
    };

    return props.error ? (
        <div className={props.className}>
            <Typography>
                <small className={css.Label}>{getErrorMessage()}</small>
            </Typography>
        </div>
    ) : null;
};

export default ValidationErrorLabel;
