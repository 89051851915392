import { useTranslation } from "react-i18next";
import { Select, SelectItem } from "@bespeak/ui";
import type { GroupDto } from "@bespeak/apollo";
import css from "./GroupsDropdown.module.css";
import { flattenGroups } from "@/lib/flattenGroups";
import { z } from "zod";
import { useMemo } from "react";

const INDENT_CHAR = "↳";
const NON_BREAKING_SPACE = "\u00A0";

type DropdownProps = {
    groups: GroupDto[] | undefined;
    defaultSelectedKey?: string;
    onChange?: (key: string) => void;
    onBlur?: () => void;
};

const baseGroupSchema = z.object({
    id: z.string(),
    name: z.string(),
    showGroupDuringRegistration: z.boolean(),
});

type Group = z.infer<typeof baseGroupSchema> & {
    subgroups: Group[];
};

const groupSchema: z.ZodType<Group> = baseGroupSchema.extend({
    subgroups: z.lazy(() => z.array(groupSchema)),
});

const groupsSchema = z.array(groupSchema);

export function Dropdown({
    groups = [],
    onChange,
    onBlur,
    defaultSelectedKey,
}: DropdownProps) {
    const { t } = useTranslation();

    const parsedGroups = useMemo(() => {
        try {
            return groupsSchema.parse(groups);
        } catch (error) {
            console.error("Error parsing groups", error);
            return [];
        }
    }, [groups]);

    if (!parsedGroups) return;

    const options = parsedGroups.flatMap(flattenGroups);

    return (
        <Select
            items={options}
            onSelectionChange={onChange}
            defaultSelectedKey={defaultSelectedKey}
            onBlur={onBlur}
            className={css.select}
            style={{ width: "100%" }}
            placeholder={t("select-group", "Selecteer Groep", {
                count: options.length,
            })}
            label={t("select-group", "Selecteer Groep")}
        >
            {(item) => (
                <SelectItem id={item.id}>
                    <Indent indent={item.indent} />
                    <span className={css.SelectItem}>{item.name}</span>
                </SelectItem>
            )}
        </Select>
    );
}

function Indent({ indent = 0 }: { indent?: number }) {
    return (
        <i className={css.SelectIndent}>
            {NON_BREAKING_SPACE.repeat((indent ?? 0) * 2)}
            {indent ? `${INDENT_CHAR} ` : ""}
        </i>
    );
}
