import type { HTMLAttributes, ReactNode } from "react";
import clsx from "clsx";
import css from "./ReferenceCardLead.module.css";

export interface ReferenceCardLeadProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
    className?: string;
}

export default function ReferenceCardLead(props: ReferenceCardLeadProps) {
    return (
        <div
            data-testid="reference-card-lead"
            className={clsx(css.ReferenceCardLead, props.className)}
        >
            {props.children}
        </div>
    );
}
