import type { ReactNode } from "react";
import { Typography } from "@/Typography";
import type { VariantProps } from "cva";
import headerVariants, { css } from "./MediaCardHeader.variants";

export interface MediaCardHeaderProps
    extends VariantProps<typeof headerVariants> {
    fileType: ReactNode;
    title: ReactNode;
    subtitle: ReactNode;
    onlyTitle?: boolean;
}

export function MediaCardHeader(props: MediaCardHeaderProps) {
    return (
        <div
            data-id="media-card-header"
            className={headerVariants({
                gutter: props.gutter,
                onlyTitle: props.onlyTitle,
            })}
        >
            {props.fileType ? (
                <Typography component="p" className={css.lead}>
                    {props.fileType}
                </Typography>
            ) : null}

            {props.title ? (
                <Typography component="h3" className={css.title}>
                    {props.title}
                </Typography>
            ) : null}

            {props.subtitle ? (
                <Typography component="h4" className={css.subtitle}>
                    {props.subtitle}
                </Typography>
            ) : null}
        </div>
    );
}

export default MediaCardHeader;
