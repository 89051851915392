import Stack from "@mui/material/Stack";
import type {
    ConversationMessageType,
    ConversationPersonType,
} from "@/components/organisms/BuildingBlockMapper";
import { DndContext, type DragEndEvent } from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "@/components/molecules/SortableItem/SortableItem";
import ConversationMessage from "../ConversationMessage/ConversationMessage";
import css from "./ConversationMessages.module.scss";
import type { ValidationError } from "yup";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import clsx from "clsx";

export interface ConversationMessagesProps {
    messages: ConversationMessageType[];
    persons?: ConversationPersonType[];
    errors?: ValidationError[];
    onChange: (messages: ConversationMessageType[]) => void;
}

export function ConversationPersons(props: ConversationMessagesProps) {
    const { messages, persons, errors } = props;

    const minError = resolveError({ errors, path: "messages", type: "min" });

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const oldIndex = messages.findIndex(
                (message) => message.id === active.id,
            );
            const newIndex = messages.findIndex(
                (message) => message.id === over?.id,
            );

            props.onChange(arrayMove(messages, oldIndex, newIndex));
        }
    }

    function findErrorsForMessage(index: number) {
        return (
            errors?.filter((error) =>
                error?.path?.startsWith(`messages[${index}].`),
            ) ?? []
        );
    }

    return (
        <Stack gap={0} flex={1}>
            <Stack
                gap={2}
                direction={"column"}
                useFlexGap
                flexWrap="wrap"
                flexBasis="100%"
            >
                <ValidationErrorLabel
                    keyPrefix={"conversation-messages"}
                    error={minError}
                />
                <DndContext onDragEnd={handleDragEnd}>
                    <SortableContext
                        items={messages}
                        strategy={verticalListSortingStrategy}
                    >
                        {messages.map((message, index) => {
                            const personProps = {
                                persons,
                                errors: findErrorsForMessage(index),
                                message,
                                onInputChange: (value: string) => {
                                    props.onChange(
                                        messages.map((p) =>
                                            p.id === message.id
                                                ? { ...p, message: value }
                                                : p,
                                        ),
                                    );
                                },
                                onPersonChange: (value: string) => {
                                    props.onChange(
                                        messages.map((p) =>
                                            p.id === message.id
                                                ? { ...p, personIndex: value }
                                                : p,
                                        ),
                                    );
                                },
                                onRemoveMessage:
                                    messages.length > 1
                                        ? () => {
                                              props.onChange(
                                                  messages.filter(
                                                      (p) =>
                                                          p.id !== message.id,
                                                  ),
                                              );
                                          }
                                        : undefined,
                                onAddMessage: () => {
                                    props.onChange([
                                        ...messages,
                                        {
                                            id: Date.now(),
                                            message: "",
                                            order: messages.length,
                                            personIndex: "0",
                                        },
                                    ]);
                                },
                            };

                            return (
                                <SortableItem key={message.id} id={message.id}>
                                    <div
                                        className={clsx(css.message, {
                                            [css.left]:
                                                message.personIndex === "0",
                                        })}
                                    >
                                        <ConversationMessage {...personProps} />
                                    </div>
                                </SortableItem>
                            );
                        })}
                    </SortableContext>
                </DndContext>
            </Stack>
        </Stack>
    );
}

export default ConversationPersons;
