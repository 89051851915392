import type { ReactNode } from "react";
import type { VariantProps } from "cva";
import actionsVariants from "./MediaCardActions.variants";

export interface MediaCardActionsProps
    extends VariantProps<typeof actionsVariants> {
    children: ReactNode;
}

export function MediaCardActions(props: MediaCardActionsProps) {
    const { children, ...variantProps } = props;

    return (
        <div
            data-id="media-card-actions"
            className={actionsVariants({ ...variantProps })}
        >
            {children}
        </div>
    );
}

export default MediaCardActions;
