import Stack from "@mui/material/Stack";
import {
    BuildingBlockType,
    type MpcAnswerType,
    type MpcQuestionBuildingBlock,
} from "@/components/organisms/BuildingBlockMapper";
import { MpcBlockLayout } from "@bespeak/apollo";
import MpcTextAnswer from "../MpcTextAnswer/MpcTextAnswer";
import MpcImageAnswer from "../MpcImageAnswer/MpcImageAnswer";
import css from "./MpcAnswers.module.scss";
import clsx from "clsx";
import { DndContext, type DragEndEvent } from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "@/components/molecules/SortableItem/SortableItem";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";

export interface MpcAnswersProps extends MpcQuestionBuildingBlock {
    onChange?: (buildingBlock: MpcQuestionBuildingBlock) => void;
}

export function MpcAnswers(props: MpcAnswersProps) {
    const { answers, layout = MpcBlockLayout.TwoColumns, errors } = props;

    const validationErrorKeyPrefix = "mpc-answers";

    const minError = resolveError({ errors, path: "answers", type: "min" });
    const minOneCorrectError = resolveError({
        errors,
        path: "answers",
        type: "min-one-correct-answer",
    });

    const answerImageMissingError = resolveError({
        errors,
        resolver: (error) =>
            (error?.path?.includes("answers") &&
                error?.path?.endsWith("image.id")) ||
            false,
    });

    const errorLabels =
        minError || minOneCorrectError || answerImageMissingError ? (
            <div className={css.error}>
                {minError && (
                    <ValidationErrorLabel
                        keyPrefix={validationErrorKeyPrefix}
                        error={minError}
                    />
                )}
                {minOneCorrectError && (
                    <ValidationErrorLabel
                        keyPrefix={validationErrorKeyPrefix}
                        error={minOneCorrectError}
                        additionalTypeToKeyMapping={(type?: string) =>
                            type === "min-one-correct-answer" ? type : undefined
                        }
                    />
                )}
                {answerImageMissingError && (
                    <ValidationErrorLabel
                        keyPrefix={validationErrorKeyPrefix}
                        error={answerImageMissingError}
                        additionalTypeToKeyMapping={(type?: string) => {
                            switch (type) {
                                case "required":
                                case "nullable":
                                case "optionality":
                                    return "image-missing";
                                default:
                                    return undefined;
                            }
                        }}
                    />
                )}
            </div>
        ) : null;

    const canHaveImages = props.type === BuildingBlockType.MPC_WITH_IMAGES;

    const handleOnAddQuestion = () => {
        props.onChange?.({
            ...props,
            answers: [...(props.answers ?? []), { id: Date.now() }],
        });
    };

    const handleOnRemoveQuestion = (index: number) => () => {
        props.onChange?.({
            ...props,
            answers: props.answers?.filter((_, i) => i !== index),
        });
    };

    const answersAsImageBlock = answers?.some(
        (answer) => answer.image?.id || answer.image?.src,
    );

    type SortableMpcAnswerType = {
        [id in keyof MpcAnswerType]-?: MpcAnswerType[id];
    };

    const sortableAnswers =
        answers?.filter(
            (answer): answer is SortableMpcAnswerType => answer.id != undefined,
        ) ?? [];

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const oldIndex = sortableAnswers.findIndex((item) => {
                return item.id === active.id;
            });

            const newIndex = sortableAnswers.findIndex(
                (item) => item.id === over?.id,
            );

            props.onChange?.({
                ...props,
                answers: arrayMove(sortableAnswers, oldIndex, newIndex),
            });
        }
    }

    function findErrorsForAnswer(index: number) {
        return (
            errors?.filter((error) =>
                error?.path?.startsWith(`answers[${index}].`),
            ) ?? []
        );
    }

    function mapAnswerToProps(answer: MpcAnswerType, index: number) {
        return {
            canHaveImages,
            answer,
            canHaveContent: layout === MpcBlockLayout.TextCentered,
            onMediaChange: (image: string | null) => {
                props.onChange?.({
                    ...props,
                    answers: sortableAnswers.map((a) =>
                        a.id === answer.id
                            ? {
                                  ...a,
                                  image: {
                                      id: image || undefined,
                                  },
                              }
                            : a,
                    ),
                });
            },
            onRadioChange: (checked: boolean) => {
                props.onChange?.({
                    ...props,
                    answers: sortableAnswers.map((a) =>
                        a.id === answer.id ? { ...a, isCorrect: checked } : a,
                    ),
                });
            },
            onInputChange: (value: string) => {
                props.onChange?.({
                    ...props,
                    answers: sortableAnswers.map((a) =>
                        a.id === answer.id ? { ...a, label: value } : a,
                    ),
                });
            },
            onContentChange: (value: string) => {
                props.onChange?.({
                    ...props,
                    answers: sortableAnswers.map((a) =>
                        a.id === answer.id ? { ...a, content: value } : a,
                    ),
                });
            },
            onRemoveQuestion:
                sortableAnswers.length > 1
                    ? handleOnRemoveQuestion(index)
                    : undefined,
            onAddQuestion: handleOnAddQuestion,
            errors: findErrorsForAnswer(index),
        };
    }

    function wrapAnswersInNonSortableContainer() {
        return (
            <>
                {sortableAnswers?.map((answer, index) => (
                    <div
                        key={answer.id}
                        className={clsx({
                            [css.image]: answersAsImageBlock,
                            [css.centered]:
                                layout === MpcBlockLayout.TextCentered,
                        })}
                    >
                        {answersAsImageBlock ? (
                            <MpcImageAnswer
                                {...mapAnswerToProps(answer, index)}
                                showAddButton={
                                    index === sortableAnswers.length - 1
                                }
                            />
                        ) : (
                            <MpcTextAnswer
                                {...mapAnswerToProps(answer, index)}
                                showAddButton={
                                    index === sortableAnswers.length - 1
                                }
                            />
                        )}
                    </div>
                ))}
            </>
        );
    }

    function wrapAnswersInSortableContainer() {
        return (
            <DndContext onDragEnd={handleDragEnd}>
                <SortableContext
                    items={sortableAnswers}
                    strategy={verticalListSortingStrategy}
                >
                    {sortableAnswers?.map((answer, index) => (
                        <SortableItem id={answer.id} key={answer.id}>
                            <div
                                className={clsx({
                                    [css.image]: answersAsImageBlock,
                                    [css.centered]:
                                        layout === MpcBlockLayout.TextCentered,
                                })}
                            >
                                {answersAsImageBlock ? (
                                    <MpcImageAnswer
                                        {...mapAnswerToProps(answer, index)}
                                        showAddButton={
                                            index === sortableAnswers.length - 1
                                        }
                                    />
                                ) : (
                                    <MpcTextAnswer
                                        {...mapAnswerToProps(answer, index)}
                                        showAddButton={
                                            index === sortableAnswers.length - 1
                                        }
                                    />
                                )}
                            </div>
                        </SortableItem>
                    ))}
                </SortableContext>
            </DndContext>
        );
    }

    function wrapAnswersInContainer() {
        return canHaveImages
            ? wrapAnswersInNonSortableContainer()
            : wrapAnswersInSortableContainer();
    }

    return (
        <Stack gap={1} flex={1}>
            <Stack
                gap={"1rem"}
                className={clsx({
                    [css.Images]: answersAsImageBlock,
                })}
                useFlexGap
                flexWrap="wrap"
                direction={answersAsImageBlock ? "row" : "column"}
            >
                {errorLabels}
                {wrapAnswersInContainer()}
            </Stack>
        </Stack>
    );
}

export default MpcAnswers;
