import { useGetAlgoliaKeyQuery } from "@bespeak/apollo";
import { useState } from "react";
import { useKeys } from "rooks";

export function useAlgolia() {
    const { data } = useGetAlgoliaKeyQuery();
    const searchKey = data?.getAlgoliaSearchKeyForAuthenticatedUser;
    const [searchActive, setSearchActive] = useState(false);
    const [globalSearchResults, setGlobalSearchResults] = useState([]);
    const [shortcode, setShortcode] = useState<string | null>(null);
    const [shortcodeUrl, setShortcodeUrl] = useState<string | null>(null);
    const [searchInstantiated, setSearchInstantiated] = useState(false);

    const handleToggleSearchActive = () => {
        setSearchActive((prev) => !prev);
        if (!searchInstantiated) {
            setSearchInstantiated(true);
        }
    };

    const handleDisableSearchActive = () => {
        setSearchActive(false);
    };

    useKeys(["Escape"], () => {
        handleDisableSearchActive();
    });

    return {
        searchKey,
        tenantHasSearchKey: !!searchKey,
        searchActive,
        searchInstantiated,
        handleToggleSearchActive,
        setSearchInstantiated,
        handleDisableSearchActive,
        globalSearchResults,
        setGlobalSearchResults,
        shortcode,
        setShortcode,
        shortcodeUrl,
        setShortcodeUrl,
    };
}

export default useAlgolia;
