import React from "react";
import { LineWave } from "react-loader-spinner";

export const Loader = () => {
    return (
        <LineWave
            ariaLabel="line-wave"
            wrapperStyle={{
                justifyContent: "center",
                padding: "var(--spacing-xxl)",
            }}
            wrapperClass=""
            visible={true}
            firstLineColor=""
            middleLineColor=""
            lastLineColor=""
        />
    );
};

export default Loader;
