import { useRef } from "react";
import type { PressEvent } from "react-aria";
import type { AriaToastProps } from "@react-aria/toast";
import { useToast } from "@react-aria/toast";
import type { ToastState } from "@react-stately/toast";
import css from "./Toast.module.scss";

interface ToastProps<T> extends AriaToastProps<T> {
    state: ToastState<T>;
}

function Toast<T extends React.ReactNode>({ state, ...props }: ToastProps<T>) {
    const ref = useRef(null);

    const { toastProps, titleProps, closeButtonProps } = useToast(
        props,
        state,
        ref,
    );

    const { onPress, ...otherCloseButtonProps } = closeButtonProps;

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (onPress) onPress(event as unknown as PressEvent);
    }

    return (
        <div {...toastProps} ref={ref} className={css.Toast}>
            <div {...titleProps}>{props.toast.content}</div>
            <button onClick={handleClick} {...otherCloseButtonProps}>
                x
            </button>
        </div>
    );
}

export default Toast;
