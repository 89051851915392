import { type ReactNode } from "react";
import Assignment from "./svgs/assignment.svg?react";
import Blank from "./svgs/blank.svg?react";
import Book from "./svgs/book.svg?react";
import Case from "./svgs/case.svg?react";
import Exam from "./svgs/exam.svg?react";
import Quiz from "./svgs/quiz.svg?react";
import Wiki from "./svgs/wiki.svg?react";
import Test from "./svgs/test.svg?react";
import Schedule from "./svgs/schedule.svg?react";
import Cross from "./svgs/cross.svg?react";
import Arrow from "./svgs/arrow.svg?react";
import Play from "./svgs/play-icon.svg?react";
import Done from "./svgs/done.svg?react";
import Again from "./svgs/again.svg?react";
import CheckCircle from "./svgs/check_circle.svg?react";
import Delete from "./svgs/delete.svg?react";
import Edit from "./svgs/edit.svg?react";
import Phone from "./svgs/phone.svg?react";
import Email from "./svgs/email.svg?react";
import LineWave from "./svgs/line-wave.svg?react";
import Search from "./svgs/search.svg?react";
import Upload from "./svgs/upload.svg?react";
import Carousel from "./svgs/carousel.svg?react";
import Check from "./svgs/check.svg?react";

export interface IconProps {
    className?: string;
    width?: number;
    height?: number;
}

interface IconWrapperProps extends IconProps {
    children: ReactNode;
}

export function Icon(props: IconWrapperProps) {
    const { children } = props;
    return <span className={`flex items-center ${props.className}`}>{children}</span>;
}

export const AssignmentIcon = (props: IconProps) => (
    <Icon {...props}>
        <Assignment />
    </Icon>
);

export const BlankIcon = (props: IconProps) => (
    <Icon {...props}>
        <Blank />
    </Icon>
);

export const BookIcon = (props: IconProps) => (
    <Icon {...props}>
        <Book />
    </Icon>
);

export const CaseIcon = (props: IconProps) => (
    <Icon {...props}>
        <Case />
    </Icon>
);

export const ExamIcon = (props: IconProps) => (
    <Icon {...props}>
        <Exam />
    </Icon>
);

export const QuizIcon = (props: IconProps) => (
    <Icon {...props}>
        <Quiz />
    </Icon>
);

export const WikiIcon = (props: IconProps) => (
    <Icon {...props}>
        <Wiki />
    </Icon>
);

export const TestIcon = (props: IconProps) => (
    <Icon {...props}>
        <Test />
    </Icon>
);

export const ClockIcon = (props: IconProps) => (
    <Icon {...props}>
        <Schedule />
    </Icon>
);

export const ArrowIcon = (props: IconProps) => (
    <Icon {...props}>
        <Arrow />
    </Icon>
);

export const CloseIcon = (props: IconProps) => (
    <Icon {...props}>
        <Cross />
    </Icon>
);

export const DoneIcon = (props: IconProps) => (
    <Icon {...props}>
        <Done />
    </Icon>
);

export const PlayIcon = (props: IconProps) => (
    <Icon {...props}>
        <Play />
    </Icon>
);

export const AgainIcon = (props: IconProps) => (
    <Icon {...props}>
        <Again />
    </Icon>
);

export const FullCheckIcon = (props: IconProps) => (
    <Icon {...props}>
        <CheckCircle />
    </Icon>
);

export const DeleteIcon = (props: IconProps) => (
    <Icon {...props}>
        <Delete />
    </Icon>
);

export const EditIcon = (props: IconProps) => (
    <Icon {...props}>
        <Edit />
    </Icon>
);

export const PhoneIcon = (props: IconProps) => (
    <Icon {...props}>
        <Phone />
    </Icon>
);

export const EmailIcon = (props: IconProps) => (
    <Icon {...props}>
        <Email />
    </Icon>
);

export const LoaderIcon = (props: IconProps) => (
    <Icon {...props}>
        <LineWave />
    </Icon>
);

export const SearchIcon = (props: IconProps) => (
    <Icon {...props}>
        <Search />
    </Icon>
);

export const UploadIcon = (props: IconProps) => (
    <Icon {...props}>
        <Upload />
    </Icon>
);

export const CarouselIcon = (props: IconProps) => (
    <Icon {...props}>
        <Carousel />
    </Icon>
);

export const CheckMarkIcon = (props: IconProps) => (
    <Icon {...props}>
        <Check />
    </Icon>
)
