import css from "./HelpBubble.module.css";
import { PhoneIcon, EmailIcon } from "../Icon/Icon.module";
import clsx from "clsx";

export type HelpBubbleCardProps = {
    email?: string;
    phone?: string;
    title: string;
    isVisible: boolean;
};

export const HelpBubbleCard = ({
    phone,
    email,
    title,
    isVisible,
}: HelpBubbleCardProps) => {
    if (!isVisible) {
        return null;
    }

    return (
        <div
            className={clsx(css.HelpBubbleCard, {
                [css.HelpBubbleCardVisible]: isVisible,
            })}
        >
            <h6 className={css.HelpBubbleCardTitle}>{title}</h6>
            <div className={css.HelpBubbleCardLinks}>
                {phone ? (
                    <a
                        className={css.HelpBubbleCardLink}
                        target="_blank"
                        rel="noreferrer"
                        href={`tel:${phone}`}
                    >
                        <PhoneIcon /> <span>{phone}</span>
                    </a>
                ) : null}

                {email ? (
                    <a
                        className={css.HelpBubbleCardLink}
                        target="_blank"
                        rel="noreferrer"
                        href={`mailto:${email}`}
                    >
                        <EmailIcon /> <span>{email}</span>
                    </a>
                ) : null}
            </div>
        </div>
    );
};
