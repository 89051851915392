import { useState, useRef, useEffect } from "react";
import type { ComponentProps } from "react";
import clsx from "clsx";
import css from "./ImageWithId.module.scss";
import { useGetSignedAssetUrl } from "@bespeak/ui";

export interface ImgWithIdProps extends ComponentProps<"img"> {
    id: string;
    onLoad?: () => void;
}

export const ImageWithId = ({ id, className, ...props }: ImgWithIdProps) => {
    const ref = useRef<HTMLImageElement>(null);

    const [loaded, setLoaded] = useState(false);

    const { signedAssetUrl } = useGetSignedAssetUrl(id || "");

    useEffect(() => {
        if (!signedAssetUrl) return;

        const imageElement = ref.current;

        function handleLoad() {
            setLoaded(true);
            props.onLoad?.();
        }

        if (imageElement?.complete) {
            setLoaded(true);
        } else {
            imageElement?.addEventListener("load", handleLoad);
        }

        return () => {
            imageElement?.removeEventListener("load", handleLoad);
        };
    }, [props, ref.current?.complete, signedAssetUrl]);

    return signedAssetUrl ? (
        <img
            className={clsx(className, css.Image, { [css.loaded]: loaded })}
            src={signedAssetUrl}
            ref={ref}
            data-testid="image"
            {...props}
        />
    ) : (
        <div className={css.Image} />
    );
};

export default ImageWithId;
