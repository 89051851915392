import type { ComponentProps } from "react";
import CarouselNavigationButtonIcon from "./CarouselNavigationButtonIcon.component";
import clsx from "clsx";
import css from "./CarouselNavigationButton.module.css";

export interface CarouselNavigationButtonProps
    extends ComponentProps<"button"> {
    direction: "left" | "right";
}

export default function CarouselNavigationButton(
    props: CarouselNavigationButtonProps,
) {
    const { direction, className, ...rest } = props;

    const classNames = clsx(css.CarouselNavigationButton, className);

    return (
        <button
            data-testid="carousel-navigation-button"
            type="button"
            className={classNames}
            {...rest}
        >
            <CarouselNavigationButtonIcon direction={direction} />
        </button>
    );
}
