import { useState } from "react";

namespace useAuthoritiesError {
    export type AuthoritiesError = unknown;
}

export function useAuthoritiesError() {
    const [authoritiesError, setAuthoritiesError] =
        useState<useAuthoritiesError.AuthoritiesError>(false);

    return [authoritiesError, setAuthoritiesError] as const;
}

export default useAuthoritiesError;
2;
