import type { ReactNode } from "react";
import clsx from "clsx";
import css from "./Typography.module.scss";

type Props = {
    className?: string;
    style?: React.CSSProperties;
    children: ReactNode;
    component?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "div";
    variant?:
        | "h1"
        | "h2"
        | "h3"
        | "h4"
        | "h5"
        | "h6"
        | "display"
        | "body"
        | "m-body-bold"
        | "body-bold"
        | "body1"
        | "body2"
        | "base-heading-bold"
        | "l-desktop-bold"
        | "base-tablet-body-bold"
        | "base-desktop-heading-bold"
        | "xxs-all-body-regular"
        | "s-all-body-regular"
        | "base-mobile-body-regular"
        | "base-desktop-body-bold"
        | "m-mobile-bold"
        | "m-mobile-regular"
        | "base-mobile-body-bold"
        | "xs-all-body-bold"
        | "xl-desktop-bold"
        | "m-desktop-medium";
    inverted?: boolean;
    noMargin?: boolean;
    uppercase?: boolean;
};

export const Typography = (props: Props) => {
    const {
        children,
        className: classNameProps,
        style,
        component,
        variant = "body",
        inverted = false,
        noMargin = false,
        uppercase = false,
    } = props;

    const className = clsx(
        "Typography",
        variant,
        component,
        css[variant || component],
        { [css.inverted]: inverted },
        { [css.noMargin]: noMargin },
        { [css.uppercase]: uppercase },
        classNameProps,
    );

    /* TODO rewrite with switch */
    const getStandardComponent = (variant: string) => {
        switch (variant) {
            case "display":
                return "h1";
            case "body":
            case "body1":
            case "body2":
            case "xs-all-body-bold":
                return "p";
            case "base-heading-bold":
                return "h6";
            case "l-desktop-bold":
                return "h3";
            default:
                return "p";
        }
    };

    const Component = component || getStandardComponent(variant);

    return (
        <Component style={style} className={className}>
            {children}
        </Component>
    );
};

export default Typography;
