import TextField from "@mui/material/TextField";

interface SearchProps {
    value: string;
    label: string;
    onChange: (value: string) => void;
}

function Search({ label, onChange, value }: SearchProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.currentTarget.value);
    };

    return (
        <TextField
            data-testid="search-field"
            value={value}
            id="outlined-basic"
            label={label}
            variant="outlined"
            size="small"
            onChange={handleChange}
            sx={{
                minWidth: 200,
            }}
        />
    );
}

export default Search;
