import "./lib/sentry/instrument";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { labelConfig } from "./whitelabels";
import WhiteLabelConfigError from "@/components/atoms/WhiteLabelConfigError/WhiteLabelConfigError";
import { injectCss } from "@bespeak/ui";
import { injectScripts } from "@bespeak/ui";
import { injectThemeColor } from "@bespeak/ui";
import * as provider from "./provider";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { NotFound } from "@bespeak/ui";
import { ErrorBoundary } from "react-error-boundary";
import "./styles/main.css";

// i18next config
import "./i18n.config";

// Set yup locale
import { setLocale } from "yup";
import { nl } from "yup-locales";

setLocale(nl);

const root = ReactDOM.createRoot(document.getElementById("root")!);

function $label(label: "makemewitty" | "beautylevel") {
    localStorage.setItem("witty/whitelabel-override", label);
    window.location.reload();
}

$label.MAKEMEWITTY = "makemewitty";
$label.BEAUTYLEVEL = "beautylevel";

declare global {
    interface Window {
        $label: typeof $label;
    }
}

void (async () => {
    try {
        const key = "witty/whitelabel-override";

        const href =
            sessionStorage.getItem(key) ||
            localStorage.getItem(key) ||
            import.meta.env.VITE_APP_WHITELABEL_OVERRIDE ||
            location.href;

        window.$label = $label;

        const whiteLabelConfig = await labelConfig(href);

        await injectCss(whiteLabelConfig.name.toLowerCase(), {
            prefix: "/styles/whitelabels",
        });

        document.title = whiteLabelConfig.title || "Witty";

        injectScripts(whiteLabelConfig);
        injectThemeColor(whiteLabelConfig);

        root.render(
            <React.StrictMode>
                <provider.Theme whiteLabelConfig={whiteLabelConfig}>
                    <ErrorBoundary FallbackComponent={NotFound}>
                        <SentryErrorBoundary fallback={NotFound}>
                            <provider.Auth>
                                <provider.GraphQL>
                                    <provider.Permissions>
                                        <provider.Dialog>
                                            <App />
                                        </provider.Dialog>
                                    </provider.Permissions>
                                </provider.GraphQL>
                            </provider.Auth>
                        </SentryErrorBoundary>
                    </ErrorBoundary>
                </provider.Theme>
            </React.StrictMode>,
        );
    } catch (error) {
        root.render(<WhiteLabelConfigError error={error} />);
    }
})();
