import css from "./MoodboardBuildingBlock.module.scss";
import Block from "@/components/atoms/Block";
import { MoodboardBlockLayout } from "@bespeak/apollo";
import LayoutSelector from "@/components/molecules/LayoutSelector";
import SelectBgColor from "./common/SelectBgColor/SelectBgColor.component";
import Add from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import clsx from "clsx";
import MoodboardImage from "@/components/molecules/MoodboardImage/MoodboardImage";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import {
    BuildingBlockType,
    type MoodboardBuildingBlock,
    type MoodboardImageType,
} from "@/components/organisms/BuildingBlockMapper";

interface MoodboardBuildingBlockEditorComponentProps
    extends MoodboardBuildingBlock {
    onChange?: (buildingBlock: MoodboardBuildingBlock) => void;
}

export function MoodboardBuildingBlockEditorComponent(
    props: MoodboardBuildingBlockEditorComponentProps,
) {
    const MAX_IMAGES = 4;
    const [bgColor, setBgColor] = useState(props.background || "DEFAULT");

    const handleOnAddImage = () => {
        props.onChange?.({
            ...props,
            images: [
                ...(props.images ?? []),
                { sortOrder: props.images.length },
            ],
        });
    };

    const onMoodBoardImageChange = (images: MoodboardImageType[]) => {
        props.onChange?.({
            ...props,
            images,
        });
    };

    const AddNewMoodboardImage = () => {
        return (
            <div>
                <IconButton onClick={handleOnAddImage}>
                    <Add />
                </IconButton>
            </div>
        );
    };

    const getErrorsForImage = (index: number) => {
        return props.errors?.filter((error) =>
            error.path?.startsWith(`images[${index}].`),
        );
    };

    return (
        <Block>
            <Block.Header>
                <Stack gap={1} direction="row" alignItems={"center"}>
                    <LayoutSelector
                        type={BuildingBlockType.MOODBOARD}
                        value={props.moodboardLayout}
                        layoutOptions={[
                            { type: MoodboardBlockLayout.SmallOverlay },
                            { type: MoodboardBlockLayout.FullOverlay },
                        ]}
                        onChange={(e) => {
                            props.onChange?.({
                                ...props,
                                moodboardLayout: e as MoodboardBlockLayout,
                            });
                        }}
                    />
                    <SelectBgColor
                        onChange={(event) => {
                            setBgColor(event);
                            props.onChange?.({
                                ...props,
                                background: event,
                            });
                        }}
                        value={bgColor}
                    />
                </Stack>
            </Block.Header>
            <Block.Main
                className={clsx({ [css.primary]: bgColor === "PRIMARY" })}
            >
                <ValidationErrorLabel
                    keyPrefix={"moodboard-building-block"}
                    error={resolveError({
                        errors: props.errors,
                        path: "images",
                        type: "min",
                    })}
                    className={css.Error}
                />
                <Grid
                    container
                    columns={2}
                    direction="row"
                    spacing={2}
                    display="grid"
                    gridTemplateColumns="repeat(2, 1fr)"
                >
                    {props.images.map((image, index) => (
                        <Grid
                            item
                            key={index}
                            flexBasis="50%"
                            className={clsx({
                                [css.long]:
                                    props.images.length === 3 && index === 1,
                            })}
                        >
                            <MoodboardImage
                                index={index}
                                moodboardImage={image}
                                layout={props.moodboardLayout}
                                images={props.images}
                                onChange={onMoodBoardImageChange}
                                errors={getErrorsForImage(index)}
                            />
                        </Grid>
                    ))}
                </Grid>
                {props.images.length < MAX_IMAGES && <AddNewMoodboardImage />}
            </Block.Main>
        </Block>
    );
}

export default MoodboardBuildingBlockEditorComponent;
