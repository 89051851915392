import { useCallback, useContext, useEffect, useState } from "react";
import type {
    AnswerSheetContextType,
    AnswerCorrectnessTypes,
    AnswerInputTypes,
} from "@/contexts/AnswerSheet/AnswerSheet.types";
import { AnswerSheetContext } from "@/contexts/AnswerSheet/AnswerSheet.provider";
import {
    useGetBuildingBlockAnswersForDidacticToolLazyQuery,
    useCheckBuildingBlockAnswersLazyQuery,
} from "@bespeak/apollo";

/**
 * Extension of the `AnswerInputTypes` type, which we can use to store
 * additional internal meta-data about the input.
 *
 * An example: the MPC reconstructs an input from its feedback if a didactic
 * tool is reloaded at a later point in time. Some components may want to know
 * whether the input has been reconstructed or actually been entered by the
 * user. This is for instance the case in the Interactive Question: it
 * determines whether the user has previously answered the question and has
 * custom functionality in those cases. To do so, it passes the
 * `skipReconstructed`-flag upon retrieving the answer, so it will not receive
 * reconstructed answers.
 */
type InputMeta = {
    /**
     * Whether the input was reconstructed from feedback. This is the case when
     * a didactic tool is reloaded and the input is reconstructed from the
     * feedback received. Some building blocks depend upon the input being
     * present for correct rendering (for instance the MPC).
     */
    reconstructed?: boolean;
};

type AnswerInputs = Record<string, AnswerInputTypes & InputMeta>;

type CheckedAnswers = Record<string, AnswerCorrectnessTypes>;

export type InputAndFeedback<
    Input extends AnswerInputTypes,
    Correctness extends AnswerCorrectnessTypes,
> = {
    input?: Input;
    feedback?: Correctness;
};

type AnswerSheetHookType = {
    /**
     * Whether the answer sheet context is loading. This is the case initially
     * as the context will fetch the given answers (if any) from the server,
     * allowing the user to continue where they left off. Will be flipped to
     * `false` after this action is completed, after which the context can be
     * considered ready for use.
     */
    loading: boolean;

    /**
     * Check whether the input for the given blockId is currently loading.
     * @param blockId Block ID to check the loading state for.
     * @returns Whether the input is currently loading.
     */
    isAnswerInputLoading: (blockId?: string) => boolean;

    /**
     * Check if all answers are satisfied to check if DM is completed
     */
    allAnswersSatisfied?: (blockIds: string[]) => boolean;

    /**
     * ID of the didactic tool the answers are for.
     */
    didacticToolId?: string;

    /**
     * Retrieve an answer from the answer sheet.
     *
     * On a non-submitted block, only the input will be returned (as soon as
     * you've stored an input using `storeAnswer`).
     * Once you submit a block, feedback will also be returned.
     *
     * On a previously submitted block (after a page refresh), only the feedback
     * will be returned (as we have no actual way to reconstruct the original
     * input in those cases). You are responsible for reconstructing the input
     * if a retry is applicable. You can use {@see storeAnswer} for that.
     *
     * @param blockId Block ID to obtain the input and feedback for.
     * @param skipReconstructed Whether to skip reconstructed inputs (default: false)
     */
    getAnswer: <
        InputType extends AnswerInputTypes,
        FeedbackType extends AnswerCorrectnessTypes,
    >(
        blockId: string,
        skipReconstructed?: boolean,
    ) => InputAndFeedback<InputType, FeedbackType> | undefined;

    /**
     * Store given input as part of the answer sheet. After storage, you can
     * then request feedback using {@see checkStoredInputs}.
     *
     * @param input Input to be stored
     * @param meta Optional meta to store alongside the answer
     */
    storeInput: (input: AnswerInputTypes, meta?: InputMeta) => void;

    /**
     * Clear the answer sheet (removing ALL state). Can be useful when you want
     * the user to retake an entire answer sheet.
     */
    clear: () => void;

    /**
     * Clear a specific answer, removing both input and feedback for that
     * answer.
     * @param blockId
     */
    clearAnswer: (blockId: string) => void;

    /**
     * Check one or more stored inputs for the given block IDs.
     * @param blockIds IDs of the blocks to check.
     * @param andThen Optional callback to be executed after the check is done.
     * @throws Error when one or more block-IDs have no stored answer
     */
    checkStoredInputs: (blockIds: string[], andThen?: () => void) => void;

    /**
     * Check whether the given blockId is correct
     * @param blockId
     */
    isCorrect: (blockId: string) => boolean;

    /**
     * Count the amount of correct answers for the given list of blockIds.
     * @param blockIds
     */
    countCorrectAnswers: (blockIds: string[]) => number;

    /**
     * Count the total amount of answers for a given list of blockIds. Useful
     * to determine whether all blocks have been answered.
     * @param blockIds
     */
    countAmountOfAnswers: (blockIds: string[]) => number;

    /**
     * Count the amount of checked answers. Can be used to detect whether an
     * answer sheet has already been (partially) checked.
     * @param blockIds
     */
    countAmountOfCheckedAnswers: (blockIds: string[]) => number;
};

type AnswerSheetHookProps = {
    didacticToolId?: string;
};

export const useAnswerSheetHook = (
    props: AnswerSheetHookProps,
): AnswerSheetHookType => {
    const { didacticToolId } = props;
    const [initialAnswersLoaded, setInitialAnswersLoaded] = useState<string>();
    const [loading, setLoading] = useState(true);

    const [answerInputs, setAnswerInputs] = useState<AnswerInputs>({});
    const [checkedAnswers, setCheckedAnswers] = useState<CheckedAnswers>({});
    const [answerInputsLoading, setAnswerInputsLoading] = useState<string[]>(
        [],
    );

    const [checkAnswersQuery, { client }] =
        useCheckBuildingBlockAnswersLazyQuery();

    const [getAnswersQuery, { loading: queryLoading }] =
        useGetBuildingBlockAnswersForDidacticToolLazyQuery();

    /**
     * Map the given array of answers to an answer-state compatible form (a map
     * of block IDs to answers) and merge it with the current answer state.
     *
     * @param answers Answers to be merged into the current answer state
     */
    const _mapAndMergeCheckedAnswers = useCallback(
        (answers?: Array<AnswerCorrectnessTypes>) => {
            const mappedResults = answers?.reduce((acc, answer) => {
                acc[answer.buildingBlockId] = answer;
                return acc;
            }, {} as CheckedAnswers);

            setCheckedAnswers((prev) => {
                return {
                    ...prev,
                    ...mappedResults,
                };
            });
        },
        [],
    );

    const _doLoadInitialAnswers = useCallback(
        (didacticToolId: string) => {
            getAnswersQuery({
                variables: {
                    id: didacticToolId,
                },
            })
                .then((result) => {
                    _mapAndMergeCheckedAnswers(
                        result.data?.getBuildingBlockAnswersForDidacticTool,
                    );
                })
                .finally(() => {
                    setLoading(false);
                    setInitialAnswersLoaded(didacticToolId);
                });
        },
        [getAnswersQuery, _mapAndMergeCheckedAnswers],
    );

    // If the ID of the didactic tool changes, reset the answer state and
    // reload the initial answers (using the effect below).
    useEffect(() => {
        if (initialAnswersLoaded && didacticToolId !== initialAnswersLoaded) {
            setInitialAnswersLoaded(undefined);
            setCheckedAnswers({});
        }
    }, [didacticToolId, initialAnswersLoaded]);

    // Load the answers for the didactic tool and mark the hook as non-loading
    // afterward.
    useEffect(() => {
        if (didacticToolId && !queryLoading && !initialAnswersLoaded) {
            setLoading(true);
            _doLoadInitialAnswers(didacticToolId);
        }
    }, [
        didacticToolId,
        queryLoading,
        initialAnswersLoaded,
        _doLoadInitialAnswers,
    ]);

    function getAnswer<
        InputType extends AnswerInputTypes,
        FeedbackType extends AnswerCorrectnessTypes,
    >(
        blockId: string,
        skipReconstructed: boolean | undefined = false,
    ): InputAndFeedback<InputType, FeedbackType> | undefined {
        const input = answerInputs[blockId];
        const feedback = checkedAnswers[blockId];

        if (!input && !feedback) return undefined;

        return {
            input:
                skipReconstructed && input?.reconstructed ? undefined : input,
            feedback,
        } as InputAndFeedback<InputType, FeedbackType>;
    }

    function storeInput(
        input: AnswerInputTypes,
        inputMeta: InputMeta | undefined = undefined,
    ) {
        setAnswerInputs((prev) => {
            return {
                ...prev,
                [input.buildingBlockId]: { ...input, ...inputMeta },
            };
        });
    }

    function checkStoredInputs(blockIds: string[], andThen?: () => void) {
        if (blockIds.length <= 0) return;

        const answersToCheck = blockIds.map((blockId) => answerInputs[blockId]);
        if (answersToCheck.length !== blockIds.length) {
            throw Error(
                `Cannot check answers, one or more blocks not found in sheet (to check ${blockIds.length}, found ${answersToCheck.length})`,
            );
        }
        _checkAnswers(answersToCheck, andThen);
    }

    const _checkAnswers = useCallback(
        (input: AnswerInputTypes[], andThen?: () => void) => {
            if (!didacticToolId) return;
            setAnswerInputsLoading((prev) => [
                ...prev,
                ...input.map((i) => i.buildingBlockId),
            ]);
            checkAnswersQuery({
                variables: {
                    input: {
                        didacticToolId: didacticToolId,
                        answers: input,
                    },
                },
                // Skip the cache as the query has side effects. This means that
                // if we re-fire the same query multiple times, a different
                // outcome may be the result.
                fetchPolicy: "no-cache",
                nextFetchPolicy: "no-cache",
                initialFetchPolicy: "no-cache",
            })
                .then((result) => {
                    _mapAndMergeCheckedAnswers(
                        result.data?.checkBuildingBlockAnswers,
                    );
                })
                .then(() => {
                    andThen?.();
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    // Invalidate queries that use the didacticToolId as
                    // state will have changed
                    client.cache.evict({
                        id: "ROOT_QUERY",
                        args: { id: didacticToolId },
                        broadcast: false,
                    });
                    client.cache.gc();

                    setAnswerInputsLoading((prev) =>
                        prev.filter(
                            (id) =>
                                !input
                                    .map((i) => i.buildingBlockId)
                                    .includes(id),
                        ),
                    );
                });
        },
        [didacticToolId, checkAnswersQuery, _mapAndMergeCheckedAnswers, client],
    );

    function isAnswerInputLoading(blockId?: string): boolean {
        return !!blockId && answerInputsLoading.includes(blockId);
    }

    function isCorrect(blockId: string): boolean {
        return checkedAnswers[blockId]?.correct === true;
    }

    function clearAnswer(blockId: string) {
        setAnswerInputs((prev) => {
            const newInputs = { ...prev };
            delete newInputs[blockId];
            return newInputs;
        });

        setCheckedAnswers((prev) => {
            const newAnswers = { ...prev };
            delete newAnswers[blockId];
            return newAnswers;
        });
    }

    function clear() {
        setAnswerInputs({});
        setCheckedAnswers({});
    }

    function countCorrectAnswers(blockIds: string[]): number {
        return blockIds
            .map((blockId) => checkedAnswers[blockId])
            .reduce((acc, answer) => {
                if (answer && answer.correct) {
                    return acc + 1;
                }
                return acc;
            }, 0);
    }

    function countAmountOfAnswers(blockIds: string[]): number {
        return blockIds
            .map((blockId) => checkedAnswers[blockId] || answerInputs[blockId])
            .reduce((acc, answer) => {
                if (answer) {
                    return acc + 1;
                }
                return acc;
            }, 0);
    }

    function countAmountOfCheckedAnswers(blockIds: string[]): number {
        return blockIds
            .map((blockId) => checkedAnswers[blockId])
            .reduce((acc, answer) => {
                if (answer) {
                    return acc + 1;
                }
                return acc;
            }, 0);
    }

    function allAnswersSatisfied(blockIds: string[]): boolean {
        return countAmountOfAnswers(blockIds) === blockIds.length;
    }

    return {
        loading,
        didacticToolId: props.didacticToolId,

        // Related to manipulation of the answer sheet (retrieving, storing and
        // checking the sheet data)
        getAnswer,
        storeInput,
        checkStoredInputs,
        clear,
        clearAnswer,
        isCorrect,
        isAnswerInputLoading,

        // Related to derivatives of the answer sheet, such as the total given
        // answer count, the correct answer count and so on.
        countCorrectAnswers,
        countAmountOfAnswers,
        countAmountOfCheckedAnswers,
        allAnswersSatisfied,
    };
};

/**
 * An answer sheet is a context that holds the answers of the user for a
 * specific didactic tool.
 */
export const useAnswerSheet = (): AnswerSheetContextType => {
    const context = useContext(AnswerSheetContext);

    if (context === undefined) {
        throw new Error(
            "useAnswerSheet must be used within a AnswerSheetContextProvider",
        );
    }

    return context;
};

export default useAnswerSheet;
