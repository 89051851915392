import type { SearchResult } from "./SearchResult.component";
import css from "./SearchResult.module.css";
import { NavigateToSearchResult } from "@/lib/navigateToSearchResult/navigateToSearchResult";
import { useNavigate } from "react-router-dom";
import { useProgramStore } from "@/store/preferences";

export const SearchResultMain = ({
    linkToIds,
    children,
}: SearchResult.Main) => {
    const navigate = useNavigate();
    const { value: currentProgram } = useProgramStore();

    const handleNavigate = () => {
        const mappedSearch = {
            educational_program: currentProgram.toString(),
            didactic_tool: linkToIds.didactic_tool_id,
            module: linkToIds.module_id,
            educational_path: linkToIds.educational_path_id,
        };

        const linkToId = NavigateToSearchResult(mappedSearch);

        if (linkToId) {
            return navigate(linkToId);
        }
    };

    return (
        <button onClick={handleNavigate} className={css.searchResult}>
            {children}
        </button>
    );
};
