/**
 * @fileOverview ProgressBar component.
 */
import { ProgressBarRoot } from "./ProgressBarRoot.component";
import { Bar } from "./Bar.component";
import { Dot } from "./Dot.component";
import { State as ProgressBarState } from "./ProgressBar.State";

export namespace ProgressBar {
    export type Props = ProgressBarRoot.Props;
    export type State = ProgressBarState;
}

/**
 * ProgressBar component.
 *
 * Renders a component that displays a progress bar. This bar is used in the Quiz component to show the progress of the
 * user. The progress is shown as a series of dots, where each dot represents a question. The state of the dot can
 * be either answered correctly, answered incorrectly, unanswered, or current.
 */
export const ProgressBar = Object.assign(ProgressBarRoot, {
    State: ProgressBarState,
    Bar,
    Dot,
});
