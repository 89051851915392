import React, { type CSSProperties } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Stack from "@mui/material/Stack";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import IconButton from "@mui/material/IconButton";
import type { UniqueIdentifier } from "@dnd-kit/core";
import { IconButtonWrapper } from "@/components/organisms/SortableList/IconButtonWrapper.component";
import { Box } from "@mui/material";

export function SortableItem(props: {
    id: UniqueIdentifier;
    children: React.ReactNode;
}) {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({
            id: props.id,
            transition: {
                duration: 600, // milliseconds
                easing: "cubic-bezier(0.25, 1, 0.5, 1)",
            },
        });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    } satisfies CSSProperties;

    return (
        <Stack
            ref={setNodeRef}
            style={style}
            {...attributes}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
        >
            <IconButtonWrapper>
                <IconButton
                    {...listeners}
                    disableTouchRipple
                    sx={{ cursor: "grab" }}
                >
                    <DragIndicatorIcon />
                </IconButton>
            </IconButtonWrapper>
            <Box flex={1}>{props.children}</Box>
        </Stack>
    );
}
