import ConvertedHTML from "@/ConvertedHTML";
import Typography from "@/Typography";
import clsx from "clsx";
import css from "./Popup.module.css";
import { DEFAULT_SPOT_SIZE } from "@/Spot";

export interface PopupProps {
    active?: boolean | null | undefined;
    title?: string | null | undefined;
    content?: string | null | undefined;
    children?: ({ active }: { active: boolean }) => JSX.Element;
    position?: "left" | "right";
    slots?: {
        Title?: (props?: PopupProps) => JSX.Element;
        Content?: (props?: PopupProps) => JSX.Element;
    };
}

export default function Popup(props: PopupProps) {
    const ariaExpanded = props.active ? "true" : undefined;

    const popupStyle: React.CSSProperties = {
        position: "absolute",
        width: "100vw",
        ...(props.position === "left" && {
            translate: "00% -50%",
            paddingLeft: DEFAULT_SPOT_SIZE / 2 + 10,
        }),
        ...(props.position === "right" && {
            translate: "-100% -50%",
            paddingRight: DEFAULT_SPOT_SIZE / 2 + 10,
        }),
    };

    return (
        <>
            <div
                data-testid="hotspot-popup"
                data-direction={props.position}
                className={clsx(css.Popup)}
                aria-expanded={ariaExpanded}
                style={popupStyle}
            >
                {typeof props.slots?.Title === "function" ? (
                    props.slots.Title(props)
                ) : (
                    <Typography
                        className={clsx(css.Title)}
                        variant="body1"
                        component="h1"
                    >
                        {props.title}
                    </Typography>
                )}
                {typeof props.slots?.Content === "function" ? (
                    props.slots.Content(props)
                ) : (
                    <Typography
                        className={clsx(css.Content)}
                        variant="body1"
                        component="div"
                    >
                        <ConvertedHTML value={props.content} />
                    </Typography>
                )}
            </div>
            {props.children?.({
                active: Boolean(props.active),
            })}
        </>
    );
}
