import { cva } from "cva";
import css from "./MediaCardMedia.module.css";

export const mediaVariants = cva({
    base: css.MediaCardMedia,
    variants: {
        fit: {
            contain: css.contain,
            cover: css.cover,
        },
        size: {
            small: css.small,
            medium: css.medium,
        },
    },
    defaultVariants: {
        fit: "cover",
        size: "medium",
    },
});
