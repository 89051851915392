import { useState, Fragment, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DataGrid, type GridRowSelectionModel } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import type { OverviewProps } from "./OverviewTable.types";
import Search from "./filters/Search";
import Select from "./filters/Select";
import MultiSelect from "@/components/organisms/MultiSelect/MultiSelect";
import BulkMenu from "./BulkMenu";
import Clear from "./filters/Clear";

export const Overview = ({
    bulkActions,
    rowActions,
    tableOptions,
    data,
    columns,
    buttons,
    loading,
    onRowClick,
    filters,
    pagination,
    onPaginationChanged,
    customRows,
}: OverviewProps) => {
    const { t } = useTranslation();

    const [selection, setSelection] = useState<GridRowSelectionModel>();

    const hasSelectedItems = selection && selection.length > 0;

    const actionColumn =
        rowActions && rowActions[0].length > 0
            ? [
                  {
                      field: "actions",
                      type: "actions",
                      width: 100,
                      cellClassName: "actions",
                      getActions: (row: any) => {
                          return [
                              <BulkMenu
                                  key={0}
                                  bulkActions={rowActions}
                                  selection={[row.id]}
                              />,
                          ];
                      },
                  },
              ]
            : [];

    const totalCountRef = useRef(data?.pagination.totalCount ?? 0);

    const totalCount = useMemo(() => {
        if (data?.pagination.totalCount !== undefined) {
            totalCountRef.current = data?.pagination.totalCount;
        }
        return totalCountRef.current;
    }, [data?.pagination.totalCount]);

    return (
        <Paper>
            <Toolbar
                data-testid="toolbar"
                sx={{
                    backgroundColor: hasSelectedItems ? "primary.light" : "",
                    color: hasSelectedItems ? "white" : "",
                    py: 2,
                }}
            >
                <Grid
                    container
                    sx={{
                        display: "flex",
                        alignItems: {
                            xs: "flex-start",
                            xl: "center",
                        },
                        gap: 1,
                        flex: 1,
                    }}
                    flexWrap={"wrap"}
                    alignItems="center"
                >
                    <Grid item xs="auto">
                        {hasSelectedItems ? (
                            <strong>
                                {t("overview-table.selected-items", {
                                    count: selection.length,
                                })}
                            </strong>
                        ) : (
                            <Stack
                                sx={{
                                    flexWrap: "wrap",
                                    flex: 1,
                                    width: "100%",
                                    flexDirection: {
                                        xs: "column",
                                        lg: "row",
                                    },
                                }}
                                gap={1}
                            >
                                {filters?.map((filter, index) => (
                                    <div style={{ flex: 1 }} key={index}>
                                        {filter.type === "search" && (
                                            <Search {...filter} />
                                        )}
                                        {filter.type === "select" && (
                                            <Select {...filter} />
                                        )}
                                        {filter.type === "multi-select" && (
                                            <MultiSelect {...filter} />
                                        )}
                                        {filter.type === "clear" && (
                                            <Clear {...filter} />
                                        )}
                                    </div>
                                ))}
                            </Stack>
                        )}
                    </Grid>
                    <Grid
                        item
                        sx={{
                            ml: {
                                xl: "auto",
                                xs: 0,
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                            }}
                        >
                            {buttons?.map((group, groupIndex) => (
                                <Fragment key={`${groupIndex}`}>
                                    {groupIndex > 0 && (
                                        <Divider
                                            sx={{ mx: 2 }}
                                            orientation="vertical"
                                            variant="middle"
                                            flexItem
                                        />
                                    )}
                                    {group.map((button, buttonIndex) => (
                                        <Button
                                            key={`${groupIndex}_${buttonIndex}`}
                                            {...button}
                                        />
                                    ))}
                                </Fragment>
                            ))}

                            {bulkActions &&
                                selection &&
                                selection.length > 0 && (
                                    <BulkMenu
                                        bulkActions={bulkActions}
                                        selection={selection}
                                    />
                                )}
                        </Box>
                    </Grid>
                </Grid>
            </Toolbar>
            <Box>
                <DataGrid
                    autoHeight
                    {...tableOptions}
                    hideFooterSelectedRowCount={true}
                    checkboxSelection={bulkActions && bulkActions.length > 0}
                    loading={loading}
                    sx={{ border: "none" }}
                    rows={customRows || data?.rows || []}
                    columns={[...columns, ...actionColumn]}
                    onRowSelectionModelChange={(items) => setSelection(items)}
                    paginationMode="server"
                    disableRowSelectionOnClick={true}
                    rowCount={totalCount}
                    onRowClick={onRowClick}
                    pageSizeOptions={[5, 10, 50, 100]}
                    paginationModel={pagination}
                    onPaginationModelChange={(model) => {
                        onPaginationChanged(model);
                    }}
                />
            </Box>
        </Paper>
    );
};

export default Overview;
