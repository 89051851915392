import type { ReactNode } from "react";
import type { ProgressBar } from "./ProgressBar.component";
import { Bar } from "./Bar.component";
import { Dot } from "./Dot.component";

export namespace ProgressBarRoot {
    export interface Props {
        /**
         * The progress of the user. Each element in the array represents a question. The state of the question can be
         * either answered correctly, answered incorrectly, unanswered, or current.
         *
         * @default null
         * @example [{state:State.CORRECT}, {state:State.INCORRECT}, {state:State.CURRENT}, {state:State.UNANSWERED}]
         */
        progress?: { state: ProgressBar.State; onClick?: () => void }[];

        /**
         * The children of the component. If this prop is provided, the progress prop will be ignored.
         *
         * @default null
         */
        children?: ReactNode;
    }
}

export function ProgressBarRoot(props: ProgressBarRoot.Props) {
    return (
        <div data-testid="progress-bar">
            {props.progress == null && props.children ? props.children : null}

            {props.progress != null && props.children == null ? (
                <Bar>
                    {props.progress.map((progress, index) => (
                        <Dot key={index} {...progress} />
                    ))}
                </Bar>
            ) : null}
        </div>
    );
}
