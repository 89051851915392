import type { ReactNode, HTMLAttributes } from "react";
import clsx from "clsx";
import css from "./ReferenceCardHeader.module.css";

export interface ReferenceCardHeaderProps
    extends Pick<HTMLAttributes<HTMLDivElement>, "className"> {
    children?: ReactNode;
}

export default function ReferenceCardHeader(props: ReferenceCardHeaderProps) {
    return (
        <div
            data-testid="reference-card-header"
            className={clsx(css.ReferenceCardHeader, props.className)}
        >
            {props.children ? props.children : null}
        </div>
    );
}
