import { cva } from "cva";
import css from "./MediaCardActions.module.css";

export const actionsVariants = cva({
    base: css.MediaCardActions,
    variants: {
        absolute: {
            true: css.absolute,
        },
        gutter: {
            true: css.gutter,
        },
        onlyTitle: {
            true: css.onlyTitle,
        },
    },
    defaultVariants: {
        absolute: false,
        onlyTitle: false,
        gutter: true,
    },
});

export default actionsVariants;

export { css };
