import type { ExternalVideoAssetDto } from "../../../packages/apollo";

export function isExternalVideoAsset(
    asset?: any,
): asset is ExternalVideoAssetDto {
    return (
        asset &&
        asset.__typename &&
        asset.__typename === "ExternalVideoAssetDto"
    );
}
