import { DidacticToolCardIcon } from "@/components/molecules/DidacticToolCard/DidacticToolCardIcon";
import { DidacticToolType } from "@bespeak/apollo";
import { CarouselIcon, BlankIcon } from "@bespeak/ui";
import type { SearchResult } from "./SearchResult.component";
import css from "./SearchResult.module.css";
import clsx from "clsx";
import { useDetailedAsset } from "@bespeak/ui/src/hooks";

export const SearchResultAsset = ({ assetId, type }: SearchResult.Asset) => {
    const dmTypes = Object.values(DidacticToolType);

    const typeIcon = () => {
        if (dmTypes.includes(type as DidacticToolType)) {
            return <DidacticToolCardIcon type={type} />;
        } else if (type === "reference-card") {
            return <CarouselIcon />;
        }

        return <BlankIcon />;
    };

    const asset = useDetailedAsset(assetId);
    return (
        <div className={css.searchResultAsset}>
            {asset?.__typename === "FileAssetDto" && asset ? (
                <div className={css.searchResultAssetImage}>
                    <img src={asset.signedUrl || ""} />
                </div>
            ) : (
                <div
                    className={clsx(css.searchResultAssetIcon, {
                        [css.searchResultAssetIconDm]: dmTypes.includes(
                            type as DidacticToolType,
                        ),
                        [css.searchResultAssetIconReferenceCard]:
                            type === "reference-card",
                        [css.searchResultAssetIconModule]: type === "module",
                    })}
                >
                    {typeIcon()}
                </div>
            )}
        </div>
    );
};
