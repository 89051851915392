import {
    NavigateToSearchResult,
    type QueryType,
} from "@/lib/navigateToSearchResult/navigateToSearchResult";
import { AlgoliaSearchContext } from "@/provider/AlgoliaSearch";
import type { AlgoliaSearchContextType } from "@/provider/AlgoliaSearch/AlgoliaSearch.types";
import { isArray } from "@apollo/client/utilities";
import { useContext, useEffect } from "react";
import { type HitsProps, useHits } from "react-instantsearch";

export const ShortCodeHits = (props: HitsProps<any>) => {
    const { items: hits } = useHits(props);
    const { setShortcode, setShortcodeUrl } =
        useContext<AlgoliaSearchContextType>(AlgoliaSearchContext);

    const shortcodeResult =
        isArray(hits) && hits.at(0)
            ? NavigateToSearchResult(hits!.at(0) as QueryType)
            : null;

    useEffect(() => {
        if (isArray(hits) && hits.at(0)) {
            setShortcode(hits!.at(0)!.shortcode);
        }

        if (shortcodeResult) {
            setShortcodeUrl(shortcodeResult);
        }

        if (!shortcodeResult) {
            setShortcodeUrl(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shortcodeResult, hits]);

    return null;
};
