import FormControl from "@mui/material/FormControl";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
interface ClearProps {
    onClick: () => void;
}

function Clear({ onClick }: ClearProps) {
    const { t } = useTranslation("common");

    return (
        <FormControl>
            <Button
                sx={{
                    minWidth: "160px",
                    paddingLeft: 1,
                    paddingRight: 1,
                }}
                onClick={onClick}
                variant="text"
            >
                <span>{t("filters.clear")}</span>
            </Button>
        </FormControl>
    );
}

export default Clear;
