import type { ReactNode, HTMLAttributes, CSSProperties } from "react";
import { useMemo } from "react";
import clsx from "clsx";
import css from "./ReferenceCardMain.module.css";

export interface ReferenceCardMainProps
    extends Pick<HTMLAttributes<HTMLDivElement>, "className" | "style"> {
    children?: ReactNode;
    flex?: string | boolean;
    lineClamp?: number;
}

export default function ReferenceCardMain(props: ReferenceCardMainProps) {
    const containerStyles: CSSProperties = useMemo(() => {
        const flexStyles: CSSProperties = {
            ...(props.flex && {
                ["--flex"]: props.flex === true ? "1 1 auto" : props.flex,
            }),
        };

        return {
            ...props.style,
            ...flexStyles,
        };
    }, [props.flex, props.style]);

    const contentStyles: CSSProperties = useMemo(() => {
        const lineClampStyles: CSSProperties = {
            ...(props.lineClamp && {
                ["--line-clamp"]: props.lineClamp,
            }),
        };

        return {
            ...lineClampStyles,
        };
    }, [props.lineClamp]);

    return (
        <div
            data-testid="reference-card-main"
            style={containerStyles}
            className={clsx(css.ReferenceCardMain, props.className)}
        >
            <div
                data-testid="reference-card-main-content"
                style={contentStyles}
                className={clsx(css.Content, {
                    [css.truncate]: props.lineClamp !== undefined,
                })}
            >
                {props.children}
            </div>
        </div>
    );
}
