import { cva } from "cva";
import { State } from "./ProgressBar.State";
import css from "./Dot.module.css";

export const dot = cva({
    base: css.Dot,
    variants: {
        state: {
            [State.CORRECT]: css.correct,
            [State.INCORRECT]: css.incorrect,
            [State.UNANSWERED]: css.unanswered,
            [State.CURRENT]: css.current,
        },
    },
    defaultVariants: {
        state: State.UNANSWERED,
    },
});
