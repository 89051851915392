import { type ReactNode, type HTMLAttributes } from "react";
import css from "./Center.module.scss";
import clsx from "clsx";

interface Props extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
}

export const Center = (props: Props) => {
    const { children, className: classNameProps } = props;

    const className = clsx("Center", css.Center, classNameProps);

    return <div className={className}>{children}</div>;
};

export default Center;
