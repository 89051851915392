import { cva } from "cva";
import css from "./MediaCardHeader.module.css";

export const headerVariants = cva({
    base: css.MediaCardHeader,
    variants: {
        gutter: {
            true: css.gutter,
        },
        onlyTitle: {
            true: css.onlyTitle,
        },
    },
    defaultVariants: {
        gutter: false,
    },
});

export default headerVariants;

export { css };
