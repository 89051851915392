import { useQuery } from "@apollo/client";
import { GetSignedAssetUrlDocument } from "@bespeak/apollo";

/**
 * @deprecated Use `useDetailedAsset` instead, each type will present its own
 *  details including a signed URL (if applicable).
 */
export function useGetSignedAssetUrl(id?: string) {
    const { data, loading } = useQuery(GetSignedAssetUrlDocument, {
        variables: { id },
        skip: !id,
        errorPolicy: "all",
    });

    return {
        loading,
        signedAssetUrl: data?.getSignedAssetUrl || null,
    };
}
