import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import type { SelectChangeEvent } from "@mui/material/Select";
import Select from "@mui/material/Select";
import css from "./ListLayoutSelector.module.scss";
import { ContentBlockListLayout } from "../../../../../packages/apollo";

export type ListLayoutSelectorProps = {
    value?: ContentBlockListLayout;
    onChange?: (value: ContentBlockListLayout) => void;
    options: {
        type: ContentBlockListLayout;
    }[];
    labelKey?: string;
    keyPrefix?: string;
};

export default function ListLayoutSelector(props: ListLayoutSelectorProps) {
    const {
        options,
        value,
        onChange,
        labelKey = "select-layout",
        keyPrefix = undefined,
    } = props;
    const { t } = useTranslation("common", { keyPrefix: keyPrefix });
    const handleChange = (event: SelectChangeEvent<string>) => {
        if (onChange) onChange(event.target.value as ContentBlockListLayout);
    };

    const inputLabel = t(labelKey, "Lay-out");

    const optionLabel = (type: string) => {
        switch (type) {
            case ContentBlockListLayout.OneColumnNumbered:
                return t(`layout-type.${type}`);
            case ContentBlockListLayout.TwoColumns:
                return t(`layout-type.${type}`, "Twee kolommen");
            case ContentBlockListLayout.TwoColumnsWithImage:
                return t(`layout-type.${type}`);
            case ContentBlockListLayout.TwoColumnsWithNumbers:
                return t(`layout-type.${type}`);
            case ContentBlockListLayout.ThreeColumns:
                return t(`layout-type.${type}`);
            case ContentBlockListLayout.ThreeColumnsWithNumbers:
                return t(`layout-type.${type}`);
            default:
                return type;
        }
    };

    return (
        <FormControl sx={{ minWidth: 180 }}>
            <InputLabel>{inputLabel}</InputLabel>
            <Select
                value={value || options[0].type}
                label={inputLabel}
                onChange={handleChange}
            >
                {options.map((option) => (
                    <MenuItem key={option.type} value={option.type}>
                        <div className={css.SelectItem}>
                            {optionLabel(option.type)}
                        </div>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
