import { useContext } from "react";
import { DialogStackContext } from "./DialogStack.context";

export function useDialogStack() {
    const context = useContext(DialogStackContext);

    if (!context) {
        throw new Error(
            "useDialogStack must be used within a DialogStackProvider",
        );
    }

    return context;
}

export default useDialogStack;
