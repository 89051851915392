import type { StateCreator } from "zustand";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { devtools } from "zustand/middleware";

const GROUP_PROGRESS = "app:groupprogress:read" as const;
const GROUPS_CREATE = "app:groups:create" as const;
const GROUPS_READ = "app:groups:read" as const;
const GROUPS_UPDATE = "app:groups:update" as const;
const GROUPS_DELETE = "app:groups:delete" as const;
const REFERENCE_CARDS_READ = "app:referencecards:read" as const;

export const Scope = {
    GROUP_PROGRESS,
    GROUPS_CREATE,
    GROUPS_READ,
    GROUPS_UPDATE,
    GROUPS_DELETE,
    REFERENCE_CARDS_READ,
} as const;

export type Scope = (typeof Scope)[keyof typeof Scope];

interface PermissionsStoreProps {
    ready: boolean;
    scopes: Scope[];
}

interface PermissionsStoreState extends PermissionsStoreProps {
    setReady: (ready: boolean) => void;
    hasPermission: (scope: Scope | Scope[]) => boolean;
}

const createPermissionsStore: StateCreator<PermissionsStoreState> = (
    set,
    get,
) => ({
    ready: false,
    setReady: (ready) => set({ ready }),
    scopes: [],
    hasPermission: (scope) => {
        const { scopes } = get();
        if (Array.isArray(scope)) {
            return scope.every((s) => scopes.includes(s));
        }
        return scopes.includes(scope);
    },
});

export const usePermissionsStore = create<PermissionsStoreState>()(
    devtools(
        persist(createPermissionsStore, {
            name: "witty/permissions",
            storage: createJSONStorage(() => sessionStorage),
            partialize: (state) => ({
                scopes: state.scopes,
            }),
        }),
    ),
);
