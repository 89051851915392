import { createContext } from "react";

export interface ThemeContextType {
    name: string;
    algoliaApplicationID: string;
}

export const ThemeContext = createContext<ThemeContextType | undefined>(
    undefined,
);
