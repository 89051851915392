import clsx from "clsx";
import css from "./CarouselIndicator.module.css";

export interface CarouselIndicatorProps {
    active?: boolean;
}

function CarouselIndicator(props: CarouselIndicatorProps) {
    return (
        <div
            data-testid="carousel-indicator"
            className={clsx(css.CarouselIndicator, {
                [css.active]: props.active,
            })}
        />
    );
}

export default CarouselIndicator;
