import type { ReactNode } from "react";
import css from "./DialogFooter.module.css";

export type DialogFooterProps = {
    /** @deprecated Use children instead */
    onClick?: () => void;
    /** @deprecated Use children instead */
    closeDialog?: () => void;
    /** @deprecated Use children instead */
    buttonLabel?: string;
    children?: ReactNode;
};

export const DialogFooter = ({ children }: DialogFooterProps) => {
    return <div className={css.DialogFooter}>{children}</div>;
};

export default DialogFooter;
