import { type ChangeEvent, useState, useEffect } from "react";
import { useAuthenticatedFetchLazy } from "@/lib/useFetch";
import type {
    ExternalVideoAssetDto,
    AssetDto,
} from "../../../../../packages/apollo";
import ReactPlayer from "react-player";
import {
    Button,
    Input,
    InputLabel,
    Stack,
    Tooltip,
    Switch,
} from "@mui/material";
import css from "@/components/organisms/AssetUpload/ExternalVideoAssetUpload.module.css";
import { Check, Edit } from "@mui/icons-material";
import { isExternalVideoAsset } from "@bespeak/ui";
import { useTranslation } from "react-i18next";

export type ExternalVideoAssetUploadProps = {
    asset?: ExternalVideoAssetDto | undefined;
    label?: string;
    loop?: boolean;
    autoplay?: boolean;
    showExternalVideoSettings: boolean;
    onChange: (asset: AssetDto | null) => void;
};

export function ExternalVideoAssetUpload(props: ExternalVideoAssetUploadProps) {
    const { asset, onChange, showExternalVideoSettings = false } = props;

    const [editing, setEditing] = useState(false);

    const { t } = useTranslation("common", {
        keyPrefix: "asset-upload",
    });

    const [url, setUrl] = useState("");
    const [loop, setLoop] = useState<boolean | undefined>(false);
    const [autoplay, setAutoplay] = useState<boolean | undefined>(false);

    useEffect(() => {
        setUrl(asset?.videoUrl ?? "");
        setLoop(asset?.loop);
        setAutoplay(asset?.autoplay);
    }, [asset]);

    const { uploadAsset } = useAuthenticatedFetchLazy();

    const updateUrl = async (event: ChangeEvent<HTMLInputElement>) => {
        setUrl(event.target.value.trim());
    };

    const updateLoop = async (event: ChangeEvent<HTMLInputElement>) => {
        setLoop(event.target.checked);
    };

    const updateAutoplay = async (event: ChangeEvent<HTMLInputElement>) => {
        setAutoplay(event.target.checked);
    };

    const matchAsset = () => {
        return (
            isExternalVideoAsset(asset) &&
            asset.videoUrl === url &&
            asset.loop === loop &&
            asset.autoplay === autoplay
        );
    };

    async function switchEditingMode() {
        if (!editing) {
            // Switch to edit-mode
            setEditing(true);
        } else if (matchAsset()) {
            // No change, switch back to read-only mode
            setEditing(false);
        } else if (ReactPlayer.canPlay(url)) {
            // URL changed, update asset and switch back to read-only mode
            await uploadAsset(undefined, {
                type: "EXTERNAL_VIDEO",
                id: asset?.id ?? null,
                videoUrl: url,
                loop: loop !== undefined ? loop : false,
                autoplay: autoplay !== undefined ? autoplay : false,
            })
                .then((data) => {
                    onChange(data);
                })
                .then(() => setEditing(false));
        } else if (url.length === 0) {
            // URL cleared, remove asset and switch back to read-only mode
            onChange(null);
            setEditing(false);
        }
    }

    return (
        <div className={css.ExternalVideoAssetUpload}>
            <div className={css.Input}>
                <div className={css.InputWrap}>
                    <Input
                        type={"text"}
                        placeholder={props.label || "URL"}
                        value={url}
                        onChange={updateUrl}
                        disabled={!editing}
                        className={css.InputField}
                    />
                    <Button onClick={switchEditingMode}>
                        {!editing && <Edit />}
                        {editing && <Check />}
                    </Button>
                </div>
                {showExternalVideoSettings ? (
                    <Stack direction={"row"} gap={1}>
                        <InputLabel>
                            <Switch
                                checked={loop}
                                disabled={!editing}
                                onChange={updateLoop}
                            />
                            <span>{t("enable-loop")}</span>
                        </InputLabel>
                        <InputLabel>
                            <Switch
                                checked={autoplay}
                                disabled={!editing}
                                onChange={updateAutoplay}
                            />
                            <Tooltip title={t("autoplay-tooltip")}>
                                <span>{t("enable-autoplay")}</span>
                            </Tooltip>
                        </InputLabel>
                    </Stack>
                ) : null}

                {/*
                The button is used to have a clear distinction as to when the
                user is done editing the URL. Only at that point will the URL
                be validated and the asset updated.
                */}
            </div>

            {isExternalVideoAsset(asset) &&
                ReactPlayer.canPlay(asset.videoUrl) && (
                    <ReactPlayer
                        className={css.Player}
                        url={asset.videoUrl}
                        light={true}
                        width={"100%"}
                        height={"400px"}
                    />
                )}
        </div>
    );
}
