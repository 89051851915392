import { createContext, useMemo, type ReactNode } from "react";
import { useAlgolia } from "./AlgoliaSearch.hook";
import algoliasearch from "algoliasearch/lite";
import {
    InstantSearch,
    Configure,
    type ConfigureProps,
} from "react-instantsearch";
namespace AlgoliaSearchProvider {
    export interface Props {
        children: ReactNode;
        applicationID?: string;
    }
}

export const AlgoliaSearchContext = createContext<
    ReturnType<typeof useAlgolia> | undefined
>(undefined);

export const AlgoliaSearchProvider = (props: AlgoliaSearchProvider.Props) => {
    const { children, applicationID } = props;
    const algolia = useAlgolia();

    const searchClient = useMemo(() => {
        return algoliasearch(applicationID || "", algolia.searchKey || "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [algolia.searchInstantiated]);

    const globalIndex = import.meta.env.VITE_ALGOLIA_SEARCH_GLOBAL_INDEX;

    const globalSearchConfig: ConfigureProps = {
        advancedSyntax: false,
        queryType: "prefixLast",
        facetFilters: [
            "available_to",
            "name",
            "description",
            "building_blocks_content",
            "header_intro_text",
            "educational_path_name",
            "educational_program_name",
            "module_name",
            "description",
        ],
        restrictSearchableAttributes: [
            "name",
            "description",
            "building_blocks_content",
            "header_intro_text",
            "educational_path_name",
            "educational_program_name",
            "module_name",
            "description",
        ],
    };

    return (
        <>
            <AlgoliaSearchContext.Provider value={algolia}>
                {searchClient && algolia.searchInstantiated ? (
                    <InstantSearch
                        searchClient={searchClient}
                        indexName={globalIndex}
                    >
                        <Configure {...globalSearchConfig} />
                        {children}
                    </InstantSearch>
                ) : (
                    <>{children}</>
                )}
            </AlgoliaSearchContext.Provider>
        </>
    );
};
