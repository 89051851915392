import { createPortal } from "react-dom";
import { useToastQueue } from "@react-stately/toast";
import ToastRegion from "./ToastRegion";
import toastQueue from "./queue";

/**
 * Create a global toast queue
 */
function GlobalToastRegion() {
    const state = useToastQueue<unknown>(toastQueue);

    return state.visibleToasts.length > 0
        ? createPortal(<ToastRegion state={state as any} />, document.body)
        : null;
}

export default GlobalToastRegion;
