import { useQuery } from "@apollo/client";
import { GetEducationalProgramsForAppDocument } from "@bespeak/apollo";

export function useGetEducationalProgramsForApp() {
    const { data, ...results } = useQuery(
        GetEducationalProgramsForAppDocument,
        {},
    );

    return {
        educationalPrograms: data?.getEducationalProgramsForApp,
        ...results,
    };
}

export default useGetEducationalProgramsForApp;
