import type { SearchResult } from "./SearchResult.component";
import css from "./SearchResult.module.css";
import clsx from "clsx";
import { useGetLabel } from "@/lib/useGetLabelForDm/useGetLabel";
import { useTranslation } from "react-i18next";
import highlighString from "@/lib/HighlightString/HighlightString";
import { useMediaQuery } from "usehooks-ts";

export const SearchResultContent = ({
    type,
    title,
    description,
    searchString,
}: SearchResult.Content) => {
    const { t } = useTranslation("common", {
        keyPrefix: "search",
    });
    const { label } = useGetLabel(type);
    const mediaQuery = useMediaQuery("(min-width: 768px)");
    const mappedLabel =
        type === "reference-card" ? t(type, "Fact card") : label;

    return (
        <div className={css.searchResultContent}>
            <span className={css.searchResultType}>{mappedLabel}</span>
            <p className={css.searchResultTitle}>{title}</p>
            <p
                className={clsx(css.searchResultDescription, {
                    [css.searchResultDescriptionTruncate]: !mediaQuery,
                })}
            >
                {highlighString(description, searchString)}
            </p>
        </div>
    );
};
