import type { VariantProps } from "cva";
import { mediaVariants } from "./MediaCardMedia.variants";

export interface MediaCardMediaProps
    extends VariantProps<typeof mediaVariants> {
    src: string;
    alt: string;
}

export function MediaCardMedia({ src, alt, fit, size }: MediaCardMediaProps) {
    if (!src) {
        return null;
    }

    return (
        <figure
            data-id="media-card-media"
            className={mediaVariants({ fit, size })}
        >
            <img src={src} alt={alt} />
        </figure>
    );
}

export default MediaCardMedia;
