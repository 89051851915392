import { useMemo } from "react";
import * as z from "zod";
import {
    type GroupDto,
    useGetGroupsForAuthenticatedUserQuery,
} from "@bespeak/apollo";

const baseGroupSchema = z.object({
    id: z.string(),
    name: z.string(),
    showGroupDuringRegistration: z.boolean(),
});

type Group = z.infer<typeof baseGroupSchema> & {
    subgroups: Group[];
};

const groupSchema: z.ZodType<Group> = baseGroupSchema.extend({
    subgroups: z.lazy(() => z.array(groupSchema)),
});

const groupsSchema = z.array(groupSchema);

export function useGetGroupsForAuthenticatedUser() {
    const { data, loading } = useGetGroupsForAuthenticatedUserQuery();

    const getGroupsForAuthenticatedUser = groupsSchema.safeParse(
        data?.getGroupsForAuthenticatedUser,
    );

    if (!loading && !getGroupsForAuthenticatedUser.success)
        console.error(getGroupsForAuthenticatedUser.error);

    const sortedGroups = useMemo(() => {
        if (!getGroupsForAuthenticatedUser.data) return [];

        const sortGroup = (group: GroupDto): GroupDto => ({
            ...group,
            subgroups: [...group.subgroups]
                .toSorted((a, b) => a.name.localeCompare(b.name))
                .map(sortGroup),
        });

        return getGroupsForAuthenticatedUser.data
            .toSorted((a, b) => a.name.localeCompare(b.name))
            .map(sortGroup);
    }, [getGroupsForAuthenticatedUser]);

    return {
        groups: sortedGroups,
        loading,
    };
}

export default useGetGroupsForAuthenticatedUser;
