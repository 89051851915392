import Stack from "@mui/material/Stack";
import {
    type QuestionConnectBuildingBlock,
    type QuestionConnectAnswerType,
} from "@/components/organisms/BuildingBlockMapper";
import QuestionConnectAnswer from "../QuestionConnectAnswer/QuestionConnectAnswer";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import css from "./QuestionConnectAnswers.module.scss";
import type { ValidationError } from "yup";

export interface QuestionConnectAnswersProps
    extends QuestionConnectBuildingBlock {
    onChange?: (buildingBlock: QuestionConnectBuildingBlock) => void;
    errors?: ValidationError[];
}

export function QuestionConnectAnswers(props: QuestionConnectAnswersProps) {
    const { answers, errors } = props;

    const minError = resolveError({ errors, path: "answers", type: "min" });
    const labelOrImageError = resolveError({
        errors,
        path: "answers",
        type: "has-label-or-image",
    });

    const handleOnAddQuestion = () => {
        props.onChange?.({
            ...props,
            answers: [...(props.answers ?? []), { id: Date.now() }],
        });
    };

    const handleOnRemoveQuestion = (index: number) => () => {
        props.onChange?.({
            ...props,
            answers: props.answers?.filter((_, i) => i !== index),
        });
    };

    function findErrorsForAnswer(index: number) {
        return (
            errors?.filter((error) =>
                error?.path?.startsWith(`answers[${index}].`),
            ) ?? []
        );
    }

    function mapAnswerToProps(
        answer: QuestionConnectAnswerType,
        index: number,
    ) {
        return {
            answer,
            onMediaChange: (image: string | null) => {
                props.onChange?.({
                    ...props,
                    answers: answers.map((a) =>
                        a.id === answer.id
                            ? {
                                  ...a,
                                  image: {
                                      id: image || undefined,
                                  },
                              }
                            : a,
                    ),
                });
            },
            onInputChange: (value: string) => {
                props.onChange?.({
                    ...props,
                    answers: answers.map((a) =>
                        a.id === answer.id ? { ...a, label: value } : a,
                    ),
                });
            },
            onCorrectAnswerChange: (value: string) => {
                props.onChange?.({
                    ...props,
                    answers: answers.map((a) =>
                        a.id === answer.id
                            ? {
                                  ...a,
                                  correctAnswer: {
                                      content: value,
                                      id: answer.correctAnswer?.id,
                                  },
                              }
                            : a,
                    ),
                });
            },
            onRemoveQuestion:
                answers.length > 1 ? handleOnRemoveQuestion(index) : undefined,
            onAddQuestion: answers.length < 3 ? handleOnAddQuestion : undefined,
        };
    }

    return (
        <div className={css.QuestionConnectAnswers}>
            <Stack gap={1} useFlexGap direction={"row"}>
                <div className={css.errorMessage}>
                    {
                        <>
                            <ValidationErrorLabel
                                keyPrefix={"question-connect"}
                                error={minError}
                            />
                            <ValidationErrorLabel
                                keyPrefix={"question-connect.label-or-image"}
                                error={labelOrImageError}
                            />
                        </>
                    }
                </div>
                {answers.map((answer, index) => {
                    return (
                        <QuestionConnectAnswer
                            key={answer.id}
                            {...mapAnswerToProps(answer, index)}
                            errors={findErrorsForAnswer(index)}
                        />
                    );
                })}
            </Stack>
        </div>
    );
}

export default QuestionConnectAnswers;
