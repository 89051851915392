import {
    AssignmentIcon,
    BlankIcon,
    BookIcon,
    CaseIcon,
    ExamIcon,
    QuizIcon,
    TestIcon,
    WikiIcon,
    PlayIcon,
} from "@bespeak/ui";

interface DidacticToolCardIconProps {
    type?: string;
    label?: string;
}

export function DidacticToolCardIcon(props: DidacticToolCardIconProps) {
    const { type, label } = props;

    switch (type) {
        case "EXAM":
            // Suppress the warning for the nested switch statement as having
            // a separate if outside the switch makes this code less readable.
            //
            // eslint-disable-next-line sonarjs/no-nested-switch
            switch (label) {
                case "TEST_YOURSELF":
                    return <TestIcon />;
                case "BOOK_CHECK":
                    return <BookIcon />;
                case "ENTRY_TEST":
                default:
                    return <ExamIcon />;
            }
        case "CASE":
            return <CaseIcon />;
        case "WIKI":
            return <WikiIcon />;
        case "ASSIGNMENT":
            return <AssignmentIcon />;
        case "QUIZ":
            return <QuizIcon />;
        case "BOOK":
            return <BookIcon />;
        case "LINEAR_VIDEO":
        case "VIDEO_INTERACTIVE":
            return <PlayIcon />;
        case "LESSON":
        default:
            return <BlankIcon />;
    }
}
