import Toaster from "./components/organisms/Toast/GlobalToastRegion";
import { Router } from "./provider";

function App() {
    return (
        <>
            <Router />
            <Toaster />
        </>
    );
}

export default App;
