import Stack from "@mui/material/Stack";
import css from "./MoodboardImage.module.scss";
import { useTranslation } from "react-i18next";
import Media from "@/components/atoms/Media";
import Input from "@/components/atoms/Input";
import WysiwygEditor from "@/components/atoms/WysiwygEditor";
import IconButton from "@mui/material/IconButton";
import { Delete } from "@mui/icons-material";
import React from "react";
import { type MoodboardImageType } from "@/components/organisms/BuildingBlockMapper";
import { MoodboardBlockLayout } from "@bespeak/apollo";
import type { ValidationError } from "yup";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";

interface MoodboardImageProps {
    onChange?: (imageBlocks: MoodboardImageType[]) => void;
    index: number;
    layout: MoodboardBlockLayout;
    moodboardImage: MoodboardImageType;
    images: MoodboardImageType[];
    errors?: ValidationError[];
}

export function MoodboardImage(props: MoodboardImageProps) {
    const { t } = useTranslation("common");
    const image = props.moodboardImage;
    const modules = {
        toolbar: [["bold", "italic"]],
    };
    type Fields = Pick<MoodboardImageType, "title" | "text">;

    const handleChange =
        (index: number, key: keyof Fields) => (value: Fields[keyof Fields]) => {
            props.onChange?.(
                props.images.map((i, idx) =>
                    idx === index ? { ...i, [key]: value } : i,
                ),
            );
        };

    const registerControl = (index: number, key: keyof Fields) => ({
        value: props.images[index][key] ?? "",
        onChange: handleChange(index, key),
    });
    const handleOnRemoveImage = (index: number) => {
        props.onChange?.(props.images?.filter((_, i) => i !== index));
    };

    const handleMoodboardImageChange = (
        index: number,
        image: string | undefined,
    ) => {
        props.onChange?.(
            props.images.map((i, idx) => {
                return idx === index ? { ...i, assetId: image } : i;
            }),
        );
    };

    return (
        <Stack spacing={2} className={css.moodboardImageBorder}>
            <div className={css.imageContainer}>
                <Media
                    image={{ id: image.assetId }}
                    onChange={(image) => {
                        handleMoodboardImageChange(
                            props.index,
                            image !== null ? image : undefined,
                        );
                    }}
                    error={resolveError({
                        errors: props.errors,
                        resolver: (error) =>
                            error.path?.endsWith("assetId") ?? false,
                    })}
                />
            </div>
            <Input
                {...registerControl(props.index, "title")}
                placeholder={t("title", "Title")}
                variant="h5"
                error={resolveError({
                    errors: props.errors,
                    resolver: (error) => error.path?.endsWith("title") ?? false,
                })}
            />
            {props.layout === MoodboardBlockLayout.FullOverlay && (
                <div>
                    <WysiwygEditor
                        {...registerControl(props.index, "text")}
                        modules={modules}
                        placeholder={t("text", "Text")}
                        error={resolveError({
                            errors: props.errors,
                            resolver: (error) =>
                                error.path?.endsWith("text") ?? false,
                        })}
                    />
                </div>
            )}
            <div className={css.moodboardImageActions}>
                {props.images.length > 0 && (
                    <IconButton
                        onClick={() => handleOnRemoveImage(props.index)}
                    >
                        <Delete />
                    </IconButton>
                )}
            </div>
        </Stack>
    );
}

export default MoodboardImage;
