const DEFAULT_OPTIONS = {
    extname: ".css",
    prefix: "",
};

export function injectCss(name = "", options: Partial<typeof DEFAULT_OPTIONS>) {
    return new Promise<void>((resolve, reject) => {
        const { extname, prefix } = {
            ...DEFAULT_OPTIONS,
            ...options,
        };

        const link = document.createElement("link");
        link.href = `${prefix}/${name}${extname}`;
        link.type = "text/css";
        link.rel = "stylesheet";
        link.media = "screen,print";

        document.getElementsByTagName("head")[0].appendChild(link);

        link.onload = () => {
            resolve();
        };

        link.onerror = () => {
            reject(new Error(`Error loading ${name}`));
        };
    });
}
