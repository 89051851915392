export const Color = {
    Brand: "brand",
    Primary: "primary",
    Secondary: "secondary",
    Error: "error",
    Warning: "warning",
    Info: "info",
    Success: "success",
    SurfaceOnBackground: "surfaceOnBackground",
} as const;
