import { useRef, useState } from "react";
import { useDebounceCallback } from "usehooks-ts";
import clsx from "clsx";
import css from "./Carousel.module.scss";
import CarouselNavigationButtonGroup from "@/CarouselNavigationButtonGroup";
import CarouselNavigationButton from "@/CarouselNavigationButton";
import CarouselIndicators from "@/CarouselIndicators";
import CarouselControls from "@/CarouselControls/CarouselControls.component";
import { useMediaMatch } from "rooks";

export interface CarouselProps {
    slides: (JSX.Element | null)[];

    // You can add elements to the bottom left part of the controller. This area
    // is for instance used to display a button to manipulate the active slide.
    // Use this in combination with a provided indexCallback, so you know which
    // slide is targeted.
    additionalControllerButtons?: JSX.Element | null;

    // A callback can be provided to be called when the index changes.
    onIndexChanged?: (index: number) => void;

    // Additional classname for the main carousel container.
    carouselClassName?: string;

    // Provide your own indicators to override the default ones.
    overrideIndicators?: JSX.Element[];
}

export const Carousel = ({
    slides,
    additionalControllerButtons,
    onIndexChanged,
    carouselClassName,
    overrideIndicators,
}: CarouselProps) => {
    const carouselRef = useRef<HTMLDivElement>(null);
    const [index, _setIndex] = useState(0);
    const setIndex = useDebounceCallback((index: number) => {
        onIndexChanged?.(index);
        return _setIndex(index);
    }, 50);

    const updateIndexOnScroll = () => {
        if (!carouselRef.current) {
            return;
        }
        const index = Math.round(
            carouselRef.current.scrollLeft / carouselRef.current.clientWidth,
        );
        setIndex(index);
    };

    const scrollToPreviousSlide = () => {
        if (!carouselRef.current) {
            return;
        }
        carouselRef.current.scrollBy({
            left: 0 - carouselRef.current.clientWidth / 2,
            behavior: "smooth",
        });
    };

    const scrollToNextSlide = () => {
        if (!carouselRef.current) {
            return;
        }
        carouselRef.current.scrollBy({
            left: carouselRef.current.clientWidth / 2,
            behavior: "smooth",
        });
    };

    const isMd = useMediaMatch("(min-width: 768px)");

    return (
        <>
            <div
                className={clsx(css.Carousel, carouselClassName)}
                ref={carouselRef}
                onScroll={updateIndexOnScroll}
            >
                {slides.map((slide, slideIndex) =>
                    slide !== null ? (
                        <div key={`slide-${slideIndex}`} className={css.Slide}>
                            {slide}
                        </div>
                    ) : null,
                )}
            </div>
            <CarouselControls>
                {additionalControllerButtons ||
                    (isMd && <div>{additionalControllerButtons}</div>)}
                {overrideIndicators ? (
                    <div className={css.OverrideIndicators}>
                        {overrideIndicators}
                    </div>
                ) : (
                    <CarouselIndicators slides={slides} index={index} />
                )}
                <CarouselNavigationButtonGroup>
                    <CarouselNavigationButton
                        onClick={scrollToPreviousSlide}
                        disabled={index <= 0}
                        direction={"left"}
                    />
                    <CarouselNavigationButton
                        onClick={scrollToNextSlide}
                        disabled={index >= slides.length - 1}
                        direction={"right"}
                    />
                </CarouselNavigationButtonGroup>
            </CarouselControls>
        </>
    );
};

export default Carousel;
