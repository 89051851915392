import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import type { LinkBuildingBlock } from "@/components/organisms/BuildingBlockMapper";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import { AssetUpload } from "@/components/organisms/AssetUpload";
import Input from "@/components/atoms/Input";
import Block from "@/components/atoms/Block";
import { FormGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

interface LinkBuildingBlockEditorComponentProps extends LinkBuildingBlock {
    elevate?: boolean;
    onChange?: (buildingBlock: LinkBuildingBlock) => void;
}

export function LinkBuildingBlockEditorComponent(
    props: LinkBuildingBlockEditorComponentProps,
) {
    const { t } = useTranslation("common");

    type StringTypeFields = Pick<
        LinkBuildingBlock,
        "title" | "instruction" | "url" | "linkLabel"
    >;

    const handleStringTypeChange =
        (key: keyof StringTypeFields) =>
        (value: StringTypeFields[keyof StringTypeFields]) => {
            props.onChange?.({
                ...props,
                [key]: value,
            });
        };

    const registerControlForStringType = (key: keyof StringTypeFields) => ({
        value: props[key],
        onChange: handleStringTypeChange(key),
    });

    const titelError = resolveError({
        errors: props.errors,
        path: "title",
    });

    const instructionError = resolveError({
        errors: props.errors,
        path: "instruction",
    });

    const urlError = resolveError({
        errors: props.errors,
        path: "url",
    });

    const linkLabelError = resolveError({
        errors: props.errors,
        path: "linkLabel",
    });

    const assetIdError = resolveError({
        errors: props.errors,
        path: "asset.id",
    });

    return (
        <Block elevate={props.elevate}>
            <Block.Main>
                <Stack gap={1} direction="column">
                    <Input
                        {...registerControlForStringType("title")}
                        label={t("link", "Titel")}
                        placeholder={t("title-placeholder", "Titel")}
                        variant="h5"
                        error={titelError}
                    />
                    <Input
                        {...registerControlForStringType("instruction")}
                        label={t("link", "Instructie")}
                        placeholder={t("instruction-placeholder", "Instructie")}
                        variant="body1"
                        error={instructionError}
                    />
                    <Input
                        {...registerControlForStringType("url")}
                        label={t("link", "URL")}
                        placeholder={t("url-placeholder", "URL")}
                        variant="body1"
                        error={urlError}
                    />
                    <Input
                        {...registerControlForStringType("linkLabel")}
                        label={t("link", "Label")}
                        placeholder={t("linkLabel-placeholder", "Label")}
                        variant="body1"
                        error={linkLabelError}
                    />
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.openInNewWindow || false}
                                    onChange={(e) =>
                                        props.onChange?.({
                                            ...props,
                                            openInNewWindow: e.target.checked,
                                        })
                                    }
                                />
                            }
                            label={t(
                                "openInNewWindow",
                                "Openen in nieuw venster",
                            )}
                        />
                    </FormGroup>
                    <AssetUpload
                        availableModes={["file"]}
                        asset={props.asset}
                        onChange={(assetId) => {
                            props.onChange?.({
                                ...props,

                                ...(assetId != null && {
                                    asset: assetId,
                                }),

                                ...(assetId === null && {
                                    asset: undefined,
                                }),
                            });
                        }}
                        error={assetIdError}
                        label={t("select-image", "Upload afbeelding")}
                    />
                </Stack>
            </Block.Main>
        </Block>
    );
}

export default LinkBuildingBlockEditorComponent;
