import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import type { ConversationPersonType } from "@/components/organisms/BuildingBlockMapper";
import Input from "@/components/atoms/Input";
import Media from "@/components/atoms//Media";
import css from "./ConversationPerson.module.scss";
import { useTranslation } from "react-i18next";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import type { ValidationError } from "yup";

export interface ConversationPersonProps {
    onMediaChange: (image: string | null) => void;
    onInputChange: (value: string) => void;
    onAddPerson: () => void;
    onRemovePerson?: () => void;
    person: ConversationPersonType;
    errors?: ValidationError[];
}

export function ConversationPerson({
    person,
    onMediaChange,
    onInputChange,
    onRemovePerson,
    onAddPerson,
    errors,
}: ConversationPersonProps) {
    const { t } = useTranslation("common");

    const nameError = resolveError({
        errors,
        resolver: (error) => error?.path?.endsWith("name") ?? false,
    });

    return (
        <Stack
            key={person.id}
            direction="row"
            gap={1}
            component={Paper}
            sx={{ p: 2 }}
            flexBasis="100%"
            alignItems={"center"}
            justifyContent={"space-between"}
        >
            <Stack direction="row" flexBasis="100%" alignItems="center" gap={1}>
                <div className={css.thumb}>
                    <Media
                        image={{ id: person.assetId }}
                        label=" "
                        onChange={onMediaChange}
                    />
                </div>
                <Stack flexBasis="100%">
                    {nameError && (
                        <ValidationErrorLabel
                            keyPrefix={"conversation-persons.name"}
                            error={nameError}
                        />
                    )}
                    <Input
                        value={person.name}
                        onChange={onInputChange}
                        placeholder={t("name", "Naam")}
                    />
                </Stack>
            </Stack>
            <Stack direction="row">
                {onRemovePerson && (
                    <IconButton
                        onClick={onRemovePerson}
                        title={t("conversation-persons.remove-person")}
                    >
                        <Delete />
                    </IconButton>
                )}
                <IconButton
                    onClick={onAddPerson}
                    title={t("conversation-persons.add-person")}
                >
                    <Add />
                </IconButton>
            </Stack>
        </Stack>
    );
}

export default ConversationPerson;
