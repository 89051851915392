import type { ReactNode } from "react";
import clsx from "clsx";
import { Color } from "../common/colors";
import { Size } from "../common/sizes";
import css from "./Button.module.scss";
import { ButtonLoader } from "@bespeak/ui";

export { css as ButtonStyles };

export const ButtonVariant = {
    /** @deprecated Use "ghost" instead. */
    Outlined: "outlined",
    /** @deprecated Use "filled" instead. */
    Contained: "contained",
    Filled: "filled",
    Ghost: "ghost",
    Text: "text",
} as const;

export const ButtonColor = {
    ...Color,
} as const;

export const ButtonSize = {
    ...Size,
} as const;

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: (typeof ButtonVariant)[keyof typeof ButtonVariant];
    color?: (typeof ButtonColor)[keyof typeof ButtonColor];
    size?: (typeof ButtonSize)[keyof typeof ButtonSize];
    state?: "" | "hover" | "focus" | "active" | "disabled"; //Only used for storybook
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
    label?: ReactNode;
    fullWidth?: boolean;
    dense?: boolean;
    loading?: boolean;
}

export function Button({
    type = "button",
    children,
    className,
    state = "",
    size = ButtonSize.Regular,
    variant = ButtonVariant.Filled,
    color = ButtonColor.Primary,
    startAdornment,
    endAdornment,
    label,
    fullWidth,
    dense,
    loading = false,
    ...props
}: ButtonProps) {
    return (
        <button
            className={clsx(css.Button, css[state ?? ""], className, {
                [css.fullWidth]: fullWidth,
                [css.hasAdornment]: startAdornment || endAdornment,
                [css.variantGhost]: variant === ButtonVariant.Ghost,
                [css.variantText]: variant === ButtonVariant.Text,
                [css.variantFilled]: variant === ButtonVariant.Filled,
                [css.colorPrimary]: color === ButtonColor.Primary,
                [css.colorBrand]: color === ButtonColor.Brand,
                [css.surfaceOnBackground]:
                    color === ButtonColor.SurfaceOnBackground,
                [css.sizeTiny]: size === ButtonSize.Tiny,
                [css.sizeSmall]: size === ButtonSize.Small,
                [css.sizeRegular]: size === ButtonSize.Regular,
                [css.dense]: dense,
            })}
            type={type}
            {...props}
        >
            {!loading ? (
                <>
                    {startAdornment}
                    {label || children}
                    {endAdornment}
                </>
            ) : (
                <ButtonLoader />
            )}

            <div className={clsx(css.shadow)} />
        </button>
    );
}

export default Button;
