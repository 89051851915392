import { useTranslation } from "react-i18next";
import { FormControl, Switch, FormControlLabel } from "@mui/material";
import type { ChangeEvent } from "react";
import { Sorting } from "@bespeak/apollo";

interface ToggleRandomize {
    onChange: (value: Sorting) => void;
    value?: Sorting;
}

const ToggleRandomize = ({
    onChange,
    value = Sorting.Randomized,
}: ToggleRandomize) => {
    const { t } = useTranslation("common");

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked
            ? Sorting.AsPresented
            : Sorting.Randomized;
        onChange(value);
    };

    return (
        <FormControl sx={{ minWidth: 150 }}>
            <FormControlLabel
                control={
                    <Switch
                        id="toggle-randomized"
                        checked={value === Sorting.AsPresented ? true : false}
                        onChange={handleChange}
                    />
                }
                label={t("randomize", "Als ingevuld weergeven")}
            />
        </FormControl>
    );
};

export default ToggleRandomize;
