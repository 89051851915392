import { useContext } from "react";
import RefreshingFetchContext from "@/contexts/RefreshingFetch/RefreshingFetchContext";

/**
 * Obtain a custom fetch function that will automatically refresh the relevant
 * tokens when they are about to expire.
 */
function useRefreshingFetch() {
    const refreshingFetch = useContext(RefreshingFetchContext);

    if (!refreshingFetch) {
        throw new Error(
            "useRefreshingFetch must be used within a RefreshingFetchProvider",
        );
    }

    return refreshingFetch;
}

export default useRefreshingFetch;
