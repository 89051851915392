import Box from "@mui/material/Box";
import type { IconButtonWrapperProps } from "./IconButtonWrapperProps";

export const IconButtonWrapper = ({ children }: IconButtonWrapperProps) => {
    return (
        <Box flexGrow={"0"} flexBasis={"40px"}>
            {children}
        </Box>
    );
};
