import type { CSSProperties, Ref, ReactNode, RefObject } from "react";
import { forwardRef, useState } from "react";
import css from "./FigureWithId.module.scss";
import clsx from "clsx";
import { ImageWithId } from "@bespeak/ui";

type FileUploadProps = {
    id: string;
    alt?: string;
    cover?: "cover" | "contain";
    styles?: {
        figure?: CSSProperties;
        image?: CSSProperties;
    };
    ref?: Ref<HTMLElement>;
    slot?: ReactNode | (() => ReactNode);
    slotContainerRef?: RefObject<any>;
    hideCursor?: boolean;
};

export function FigureWithId({
    id,
    alt,
    cover,
    styles,
    ref,
    slot,
    slotContainerRef,
    hideCursor,
}: FileUploadProps) {
    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <figure
            className={clsx(css.Figure, { [css.cover]: cover === "cover" })}
            style={styles?.figure || {}}
            ref={ref}
        >
            {id && (
                <ImageWithId
                    className={css.Image}
                    alt={alt}
                    id={id}
                    style={styles?.image}
                    onLoad={() => setImageLoaded(true)}
                />
            )}
            <div
                style={{
                    position: "absolute",
                    inset: 0,
                    ...(hideCursor && { cursor: "none" }),
                }}
                ref={slotContainerRef}
            >
                {imageLoaded
                    ? typeof slot === "function"
                        ? slot()
                        : slot
                    : null}
            </div>
        </figure>
    );
}

export const ForwardedFigureWithId = forwardRef<HTMLElement, FileUploadProps>(
    (props, ref) => {
        return <FigureWithId {...props} ref={ref} />;
    },
);

ForwardedFigureWithId.displayName = "ForwardedFigureWithId";
