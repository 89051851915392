import { useTranslation } from "react-i18next";
import WysiwygEditor from "@/components/atoms/WysiwygEditor";
import Input from "@/components/atoms/Input";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import type { ValidationError } from "yup";

export interface QuestionFieldsProps {
    onChange: (
        value: string,
        key: "introduction" | "question" | "instruction",
    ) => void;
    questionValue?: string;
    introductionValue?: string;
    instructionValue?: string;
    error?: ValidationError;
}

export function QuestionFields(props: QuestionFieldsProps) {
    const { t } = useTranslation("common");
    const modules = {
        toolbar: [["bold", "italic"]],
    };

    const question = (
        <>
            <div>
                <WysiwygEditor
                    onChange={(value) => props.onChange(value, "introduction")}
                    value={props.introductionValue}
                    modules={modules}
                    label={t("introduction-label", "Introductie")}
                    placeholder={t("introduction-placeholder", "Introductie")}
                />
            </div>
            <div>
                {props.error && (
                    <ValidationErrorLabel
                        keyPrefix={"input"}
                        error={props.error}
                    />
                )}
                <Input
                    value={props.questionValue}
                    onChange={(value) => props.onChange(value, "question")}
                    label={t("question-label", "Vraag")}
                    placeholder={t("question", "Vraag")}
                    variant="h5"
                />
            </div>
            <div>
                <Input
                    value={props.instructionValue}
                    onChange={(value) => props.onChange(value, "instruction")}
                    label={t("instruction-label", "Instructie")}
                    placeholder={t("instruction", "Instructie")}
                />
            </div>
        </>
    );
    return <>{question}</>;
}

export default QuestionFields;
