export const Size = {
    Tiny: "tiny",
    Small: "small",
    Regular: "regular",
    /** @deprecated Use "regular" instead. */
    Medium: "md",
    /** @deprecated Use small, tiny or regular instead. */
    Large: "lg",
    /** @deprecated Use small, tiny or regular instead. */
    ExtraLarge: "xl",
} as const;
