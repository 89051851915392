import type { SVGProps } from "react";

export namespace icons {
    export interface Props extends SVGProps<SVGSVGElement> {}
}

const Checkmark = (props: icons.Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={"0 0 20 20"}
        width={20}
        height={20}
        fill="none"
        {...props}
    >
        <mask
            id="a"
            width={20}
            height={20}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
                maskType: "alpha",
            }}
        >
            <path fill="#D9D9D9" d="M0 0h20v20H0z" />
        </mask>
        <g mask="url(#a)">
            <path
                fill="#F7F9FF"
                d="m7.958 15-4.75-4.75 1.188-1.187 3.562 3.562 7.646-7.646 1.187 1.188L7.958 15Z"
            />
        </g>
    </svg>
);

const Dot = (props: icons.Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={"0 0 20 20"}
        width={20}
        height={20}
        fill="none"
        {...props}
    >
        <mask
            id="a"
            width={20}
            height={20}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
                maskType: "alpha",
            }}
        >
            <path fill="#D9D9D9" d="M0 0h20v20H0z" />
        </mask>
        <g mask="url(#a)">
            <path
                fill="#F7F9FF"
                d="M10 12.5c-.7 0-1.292-.242-1.775-.725C7.742 11.292 7.5 10.7 7.5 10s.242-1.292.725-1.775C8.708 7.742 9.3 7.5 10 7.5s1.292.242 1.775.725c.483.483.725 1.075.725 1.775s-.242 1.292-.725 1.775c-.483.483-1.075.725-1.775.725Z"
            />
        </g>
    </svg>
);

const Cross = (props: icons.Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={"0 0 20 20"}
        width={20}
        height={20}
        fill="none"
        {...props}
    >
        <mask
            id="a"
            width={20}
            height={20}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
                maskType: "alpha",
            }}
        >
            <path fill="#D9D9D9" d="M0 0h20v20H0z" />
        </mask>
        <g mask="url(#a)">
            <path
                fill="#F7F9FF"
                d="m5.334 15.833-1.167-1.166L8.834 10 4.167 5.333l1.167-1.166L10 8.833l4.667-4.666 1.167 1.166L11.167 10l4.667 4.667-1.167 1.166L10 11.167l-4.666 4.666Z"
            />
        </g>
    </svg>
);

export const icons = {
    Checkmark,
    Dot,
    Cross,
};
