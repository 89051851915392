export enum SpacingType {
    S_3 = "s-3",
    S_2 = "s-2",
    S_1 = "s-1",
    S0 = "s0",
    S1 = "s1",
    S2 = "s2",
    S3 = "s3",
    S4 = "s4",
    S5 = "s5",
    SXXL = "spacing-xxl",
    SXL = "spacing-xl",
}

export default SpacingType;
