import DialogBase from "./DialogBase.component";
import DialogBody from "./DialogBody.component";
import DialogFooter from "./DialogFooter.component";
import DialogHeader from "./DialogHeader.component";
import DialogContent from "./DialogContent.component";

export const Dialog = Object.assign(DialogBase, {
    Body: DialogBody,
    Content: DialogContent,
    Footer: DialogFooter,
    Header: DialogHeader,
});

export default Dialog;
