import { useState } from "react";
import type { NetworkError } from "@apollo/client/errors";

function useNetworkError() {
    const [networkError, setNetworkError] = useState<NetworkError | null>(null);
    return [networkError, setNetworkError] as const;
}

export { type NetworkError };

export default useNetworkError;
