import Overview from "@/components/organisms/OverviewTable/OverviewTable";
import { useTranslation } from "react-i18next";
import type {
    ReferenceCardDto,
    ReferenceCardCategoryDto,
    ReferenceCardLeadDto,
    ReferenceCardsDto,
    EducationalProgramDto,
} from "@bespeak/apollo";
import type { GridPaginationModel, GridRowParams } from "@mui/x-data-grid";
import { Checkbox } from "@mui/material";
import { type Filter } from "../OverviewTable";
export interface ReferenceCardOverviewProps {
    buttons?: any[];
    loading: boolean;
    onAddItem?: (item: any) => void;
    onPaginationChanged: (pagination: GridPaginationModel) => void;
    rowActions?: any[];
    bulkActions?: any[];
    pagination: GridPaginationModel;
    onRowClick: (row: GridRowParams<any>) => void;
    data: ReferenceCardsDto;
    addedCards?: ReferenceCardDto[];
    filters?: Filter[];
}

export const ReferenceCardOverview = ({
    buttons,
    onPaginationChanged,
    rowActions,
    bulkActions,
    pagination,
    loading,
    data,
    onRowClick,
    addedCards,
    filters,
}: ReferenceCardOverviewProps) => {
    const { t } = useTranslation("common", {
        keyPrefix: "list-reference-cards",
    });

    return (
        <Overview
            pagination={pagination}
            onPaginationChanged={onPaginationChanged}
            loading={loading}
            onRowClick={(row) => {
                onRowClick(row);
            }}
            filters={filters}
            columns={[
                ...(addedCards
                    ? [
                          {
                              field: "id",
                              headerName: "",
                              minWidth: 30,
                              sortable: false,
                              filterable: false,
                              renderCell: (params: any) => {
                                  const isChecked = addedCards?.some(
                                      (card) => card.id === params.id,
                                  );
                                  return <Checkbox checked={isChecked} />;
                              },
                          },
                      ]
                    : []),
                {
                    field: "title",
                    headerName: t("name"),
                    minWidth: 200,
                    flex: 1,
                    sortable: true,
                    filterable: false,
                },
                {
                    field: "lead",
                    headerName: t("lead"),
                    minWidth: 200,
                    flex: 1,
                    sortable: false,
                    filterable: false,
                    valueFormatter: (params: ReferenceCardLeadDto) => {
                        return params?.label;
                    },
                },
                {
                    field: "categories",
                    headerName: t("category"),
                    minWidth: 200,
                    flex: 1,
                    sortable: false,
                    filterable: false,
                    valueFormatter: (params: ReferenceCardCategoryDto[]) => {
                        if (!params.length) return "";
                        return params
                            .map((category: any) => category.label)
                            .join(", ");
                    },
                },
                {
                    field: "programs",
                    headerName: t("program"),
                    minWidth: 200,
                    flex: 1,
                    sortable: false,
                    filterable: false,
                    valueFormatter: (params: EducationalProgramDto[]) => {
                        if (!params.length) return "";
                        return params
                            .map(
                                (program: EducationalProgramDto) =>
                                    program.name,
                            )
                            .join(", ");
                    },
                },
            ]}
            rowActions={rowActions}
            bulkActions={bulkActions}
            buttons={buttons}
            data={data}
        />
    );
};

export default ReferenceCardOverview;
