import { useEffect, useState } from "react";

const useHistoryPath = (pathname: string) => {
    const [historyPath, setHistoryPath] = useState("");

    useEffect(() => {
        const updatedPath = pathname.substring(
            0,
            pathname.lastIndexOf("/", pathname.lastIndexOf("/") - 1),
        );
        setHistoryPath(updatedPath);
    }, [pathname]);

    return historyPath;
};

export default useHistoryPath;
