import type { AriaToastRegionProps } from "@react-aria/toast";
import type { ToastState } from "@react-stately/toast";
import { useToastRegion } from "@react-aria/toast";
import { useRef } from "react";
import Toast from "./Toast";
import css from "./ToastRegion.module.scss";

interface ToastRegionProps<T> extends AriaToastRegionProps {
    state: ToastState<T>;
}

function ToastRegion<T extends React.ReactNode>({
    state,
    ...props
}: ToastRegionProps<T>) {
    const ref = useRef(null);
    const { regionProps } = useToastRegion(props, state, ref);

    return (
        <div {...regionProps} ref={ref} className={css.ToastRegion}>
            {state.visibleToasts.map((toast) => (
                <Toast key={toast.key} toast={toast} state={state} />
            ))}
        </div>
    );
}

export default ToastRegion;
