import css from "./ReferenceCardBuildingBlock.module.scss";
import Block from "@/components/atoms/Block";
import { Button, Input } from "@mui/material";
import SelectBgColor from "./common/SelectBgColor/SelectBgColor.component";
import Add from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import clsx from "clsx";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import Stack from "@mui/material/Stack";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Box, Typography } from "@mui/material";
import type {
    EducationalProgramDto,
    ReferenceCardLeadDto,
    ReferenceCardsFilterInput,
} from "@bespeak/apollo";
import {
    Operator,
    type ReferenceCardsDto,
    ReferenceCardsFieldSelector,
    useGetReferenceCardCategoriesQuery,
    useGetReferenceCardLeadsQuery,
    useGetReferenceCardsQuery,
} from "@bespeak/apollo";
import { DndContext } from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "@/components/molecules/SortableItem/SortableItem";
import { Delete, Close } from "@mui/icons-material";
import type { DragEndEvent } from "@dnd-kit/core";
import type { GridPaginationModel, GridRowParams } from "@mui/x-data-grid";
import type { ReferenceCardBuildingBlock } from "@/components/organisms/BuildingBlockMapper";
import ReferenceCardOverview from "../../ReferenceCardOverview/ReferenceCardOverview";
import { useDebounce } from "use-debounce";
import type { Filter } from "../../OverviewTable";
import { useGetEducationalPrograms } from "@/hooks/useGetEducationalPrograms";
import { useSnackbar } from "notistack";

export interface ReferenceCardBuildingBlockEditorComponentProps
    extends ReferenceCardBuildingBlock {
    onChange?: (buildingBlock: ReferenceCardBuildingBlock) => void;
}

const MAX_CARDS = 12;

export function ReferenceCardBuildingBlockComponent(
    props: ReferenceCardBuildingBlockEditorComponentProps,
) {
    const { t } = useTranslation("common", {
        keyPrefix: "reference-card-building-block",
    });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    type Fields = Pick<ReferenceCardBuildingBlock, "title">;

    const { data: referenceCardCategories } =
        useGetReferenceCardCategoriesQuery();

    const { educationalPrograms } = useGetEducationalPrograms();
    const { data: referenceCardLeads } = useGetReferenceCardLeadsQuery();

    const [bgColor, setBgColor] = useState(props.background || "DEFAULT");
    const [modalOpen, setModalOpen] = useState(false);

    const [pagination, setPagination] = useState<GridPaginationModel>({
        pageSize: 8,
        page: 0,
    });

    const [searchQuery, setSearchQuery] = useState<string>("");
    const [queryDebounced] = useDebounce<string>(searchQuery, 300);

    const [category, setCategory] = useState<string>("none");
    const [lead, setLead] = useState<string>("none");
    const [educationalProgram, setEducationalProgram] =
        useState<string>("none");

    const usePaginationChange = (pagination: GridPaginationModel) => {
        setPagination(pagination);
    };

    const useClearFilters = () => {
        setSearchQuery("");
        setCategory("none");
        setEducationalProgram("none");
        setLead("none");
    };

    const filterInput = useMemo(() => {
        const arr: ReferenceCardsFilterInput[] = [];

        if (queryDebounced !== "") {
            arr.push({
                selector: ReferenceCardsFieldSelector.Title,
                operator: Operator.LikeIgnoreCase,
                value: queryDebounced,
            });
        }

        if (category !== "" && category !== "none") {
            arr.push({
                selector: ReferenceCardsFieldSelector.Category,
                operator: Operator.Equals,
                value: category !== "none" ? category : "",
            });
        }

        if (educationalProgram !== "" && educationalProgram !== "none") {
            arr.push({
                selector: ReferenceCardsFieldSelector.EducationalProgram,
                operator: Operator.Equals,
                value: educationalProgram !== "none" ? educationalProgram : "",
            });
        }

        if (lead !== "" && lead !== "none") {
            arr.push({
                selector: ReferenceCardsFieldSelector.Lead,
                operator: Operator.Equals,
                value: lead !== "none" ? lead : "",
            });
        }

        return arr;
    }, [queryDebounced, category, educationalProgram, lead]);

    const filters = [
        {
            name: ReferenceCardsFieldSelector.Title,
            type: "search",
            value: searchQuery,
            onChange: setSearchQuery,
            label: t("search"),
        },
        {
            name: ReferenceCardsFieldSelector.Lead,
            type: "select",
            value: lead,
            onChange: setLead,
            label: t("lead"),
            options: [
                { label: t("all-leads"), value: "none" },
                ...(referenceCardLeads?.getReferenceCardLeads?.map(
                    (referenceCardLead: ReferenceCardLeadDto) => ({
                        label: referenceCardLead.label,
                        value: referenceCardLead.id,
                    }),
                ) || []),
            ],
        },
        {
            name: ReferenceCardsFieldSelector.Category,
            type: "select",
            value: category,
            onChange: setCategory,
            label: t("category"),
            options: [
                { label: t("all-categories"), value: "none" },
                ...(referenceCardCategories?.getReferenceCardCategories.map(
                    (category) => ({
                        label: category.label,
                        value: category.id,
                    }),
                ) || []),
            ],
        },
        {
            name: ReferenceCardsFieldSelector.EducationalProgram,
            type: "select",
            value: educationalProgram,
            onChange: setEducationalProgram,
            label: t("program"),
            options: [
                { label: t("all-programs"), value: "none" },
                ...(educationalPrograms?.map(
                    (educationalProgram: EducationalProgramDto) => ({
                        label: educationalProgram.name,
                        value: educationalProgram.id,
                    }),
                ) || []),
            ],
        },
        {
            name: "clear",
            type: "clear",
            onClick: useClearFilters,
        },
    ] satisfies Filter[];

    const { data, loading } = useGetReferenceCardsQuery({
        variables: {
            pagination: pagination,
            filters: filterInput,
        },
    });

    const addedCards = props?.cards;

    const titleError = resolveError({
        errors: props.errors,
        path: "title",
    });

    const registerControl = (key: keyof Fields) => ({
        value: props[key],
        onChange: handleChange(key),
    });

    const handleChange =
        (key: keyof Fields) => (event: React.ChangeEvent<HTMLInputElement>) => {
            props.onChange?.({
                ...props,
                [key]: event?.target?.value,
            });
        };

    const sortableCards = props?.cards;

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const oldIndex = sortableCards.findIndex((item) => {
                return item.id === active.id;
            });

            const newIndex = sortableCards.findIndex(
                (item) => item.id === over?.id,
            );

            props.onChange?.({
                ...props,
                cards: arrayMove(sortableCards, oldIndex, newIndex),
            });
        }
    }

    const handleOnRemoveItem = (index: number) => () => {
        props.onChange?.({
            ...props,
            cards: props.cards?.filter((_, i) => i !== index),
        });
    };

    const handleRowClick = (item: GridRowParams<any>) => {
        const existingCardIndex = addedCards.findIndex(
            (card) => card.id === item.row.id,
        );
        if (existingCardIndex !== -1) {
            props.onChange?.({
                ...props,
                cards: addedCards.filter(
                    (_, index) => index !== existingCardIndex,
                ),
            });

            return;
        }

        props.onChange?.({
            ...props,
            cards: [
                ...(props.cards ?? []),
                {
                    ...item.row,
                    id: item.row.id,
                    title: item.row.title,
                    sortOrder: props.cards.length,
                },
            ],
        });

        if (addedCards.length >= MAX_CARDS - 1) {
            setModalOpen(false);
            enqueueSnackbar(t("max-cards-reached"), {
                variant: "success",
                persist: false,
                autoHideDuration: 1500,
                action: (key) => (
                    <Button
                        sx={{
                            color: "white",
                            padding: 0,
                            margin: 0,
                            width: "auto",
                            height: "auto",
                        }}
                        onClick={() => closeSnackbar(key)}
                        variant="text"
                    >
                        <Close />
                    </Button>
                ),
            });
        }
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 2,
        width: "70vw",
        overflowY: "scroll",
        margin: "0 auto",
    };

    const useAddModal = () => {
        setModalOpen(true);
    };
    const handleClose = () => {
        setModalOpen(false);
        setPagination({ pageSize: 8, page: 0 });
    };

    const addCard = (
        <>
            {props?.cards?.length < MAX_CARDS && (
                <IconButton onClick={useAddModal}>
                    <Add />
                </IconButton>
            )}
        </>
    );

    const ReferenceCardList = () => {
        return (
            <DndContext onDragEnd={handleDragEnd}>
                <SortableContext
                    items={sortableCards}
                    strategy={verticalListSortingStrategy}
                >
                    {sortableCards?.map((referenceCard, index) => {
                        return (
                            <SortableItem
                                key={referenceCard.id}
                                id={referenceCard.id}
                            >
                                <div
                                    className={clsx(
                                        "SortableItem",
                                        css.SortableItem,
                                    )}
                                >
                                    <span>{referenceCard.title}</span>
                                    <div
                                        className={clsx("Buttons", css.Buttons)}
                                    >
                                        <IconButton
                                            onClick={handleOnRemoveItem(index)}
                                            title={t("delete-item")}
                                        >
                                            <Delete />
                                        </IconButton>
                                        {sortableCards.length - 1 === index &&
                                            addCard}
                                    </div>
                                </div>
                            </SortableItem>
                        );
                    })}
                </SortableContext>
            </DndContext>
        );
    };

    return (
        <Block>
            <Block.Header>
                <Stack gap={1} direction="row" alignItems={"center"}>
                    <SelectBgColor
                        onChange={(event) => {
                            setBgColor(event);
                            props.onChange?.({
                                ...props,
                                background: event,
                            });
                        }}
                        value={bgColor}
                    />
                </Stack>
            </Block.Header>
            <Block.Main
                className={clsx({ [css.primary]: bgColor === "PRIMARY" })}
            >
                <ValidationErrorLabel
                    keyPrefix={"reference-card-building-block"}
                    error={resolveError({
                        errors: props.errors,
                        path: "images",
                        type: "min",
                    })}
                    className={css.Error}
                />
                <Input
                    {...registerControl("title")}
                    placeholder={t("title-placeholder", "Titel")}
                    error={!!titleError}
                    style={{ margin: "20px 0px" }}
                />

                <Modal open={modalOpen} onClose={handleClose}>
                    <Box sx={style}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: 2,
                            }}
                        >
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                            >
                                {t("add-card-title")}
                            </Typography>
                            <Button
                                sx={{
                                    p: 0,
                                }}
                                variant="text"
                                onClick={handleClose}
                            >
                                <Close />
                            </Button>
                        </Box>

                        <Box>
                            <ReferenceCardOverview
                                data={
                                    data?.getReferenceCards as ReferenceCardsDto
                                }
                                onPaginationChanged={usePaginationChange}
                                addedCards={addedCards}
                                pagination={pagination}
                                filters={filters}
                                loading={loading}
                                onAddItem={handleRowClick}
                                onRowClick={(row) => handleRowClick(row)}
                                {...props}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginTop: "20px",
                                }}
                            >
                                <Button onClick={handleClose}>
                                    {t("close")}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>

                {props.cards?.length === 0 ? addCard : null}
                <ReferenceCardList />
            </Block.Main>
        </Block>
    );
}

export default ReferenceCardBuildingBlockComponent;
