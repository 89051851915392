import { userManager } from "./Auth.config";
import { jwtDecode } from "jwt-decode";

export async function getAccessToken(): Promise<string | null> {
    return userManager.getUser().then((user) => {
        const token = user?.access_token ?? null;

        // Check whether the currently stored token is still valid. This is
        // generally the case during an active session, but the state store may
        // contain an invalid token if the user comes back after a longer time
        // (e.g. the next day), because the elapsed token will have been stored
        // in local storage in those cases and is restored as state before the
        // new token arrives.
        const decoded = token ? jwtDecode(token) : null;
        if (decoded?.exp && decoded.exp * 1000 > Date.now()) {
            return token;
        }

        return null;
    });
}
