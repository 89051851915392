import { createFilterOptions, FormControl } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import noop from "@/lib/noop";
import { useTranslation } from "react-i18next";

interface SelectLabelProps {
    onChange?: (newValue: LabelType) => void;
    value?: LabelType;
    options: LabelType[];
}

interface LabelType {
    inputValue?: string;
    label: string;
    id?: string | null;
}

const filter = createFilterOptions<LabelType>();

const SelectLabel = (props: SelectLabelProps) => {
    const { onChange = noop, value } = props;

    const { t } = useTranslation("common", {
        keyPrefix: "dynamic-form",
    });

    return (
        <FormControl data-test-id="label-selector" sx={{ minWidth: 150 }}>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                        onChange({
                            label: newValue,
                        });
                    } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        onChange({
                            label: newValue.inputValue,
                        });
                    } else {
                        onChange(newValue);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                        (option) => inputValue === option.label,
                    );
                    if (inputValue !== "" && !isExisting) {
                        filtered.push({
                            inputValue,
                            label: t("add-new-option", {
                                title: inputValue,
                            }),
                        });
                    }

                    return filtered;
                }}
                clearOnBlur
                handleHomeEndKeys
                options={props.options}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                        return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.label;
                }}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                        {option.label}
                    </li>
                )}
                sx={{ width: 300 }}
                freeSolo
                renderInput={(params) => (
                    <TextField {...params} label={"Label"} />
                )}
            />
        </FormControl>
    );
};

export default SelectLabel;
