import { State } from "./ProgressBar.State";
import { icons } from "./ProgressBar.icons";

export namespace Icon {
    export interface Props {
        state: State;
    }
}

export function Icon(props: Icon.Props) {
    switch (props.state) {
        case State.CORRECT:
            return <icons.Checkmark />;
        case State.INCORRECT:
            return <icons.Cross />;
        case State.CURRENT:
            return <icons.Dot />;
        default:
            return null;
    }
}
