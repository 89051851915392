import { FormRoot } from "./FormRoot.component";
import { Textfield } from "./Textfield";
import { Autocomplete } from "./Autocomplete";
import { type FieldValues } from "react-hook-form";

export namespace Form {
    export interface Props<FormType extends FieldValues>
        extends FormRoot.Props<FormType> {}
}

export const Form = Object.assign(FormRoot, {
    Textfield,
    Autocomplete,
});
